import React from 'react';
import { arrayOf, string, shape } from 'prop-types';
import { useHistory } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

function CustomMenuItem({ heading, menuList }) {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (href, isExternalLink = false) => {
    setAnchorEl(null);
    if (!isExternalLink) {
      history.push(href);
    } else {
      window.open(href);
    }
  };

  return (
    <>
      <li
        className="nav-item menu-item"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {heading}
      </li>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuList.map(menu => (
          <MenuItem key={menu.name} onClick={() => handleClose(menu.href, menu.isExternalLink)}>
            {menu.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

CustomMenuItem.propTypes = {
  heading: string,
  menuList: arrayOf(
    shape({
      name: string,
      href: string,
    }),
  ),
};

CustomMenuItem.defaultProps = {
  heading: '',
  menuList: [],
};

export { CustomMenuItem };
