import React, { useEffect, useState } from 'react';
import { element } from 'prop-types';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import * as FullStory from '@fullstory/browser';
import { useHistory } from 'react-router-dom';
import { googleLogout } from '@react-oauth/google';

import { doGet } from 'utils/NetworkUtils';
import {
  setToken,
  getToken,
  clearSession,
  isLoggedInUser as isLoggedInUserFn,
} from 'utils/SessionUtils';

const UserContext = React.createContext();
const { Provider, Consumer } = UserContext;

const UserProvider = props => {
  const { children } = props;
  const history = useHistory();
  const [userInfo, setUserInfo] = useState({});
  const [isLoggedInUser, setIsLoggedInUser] = useState(false);
  const [isGuestUser, setIsGuestUser] = useState(false);
  const dispatch = useDispatch();

  const getUserInfoFromApi = () => {
    doGet({
      url: '/me',
    })
      .then(({ user, preference }) => {
        const isValidUser = user && Object.keys(user).length > 0;
        setUserInfo(user);
        setIsLoggedInUser(isValidUser);
        setIsGuestUser(_.get(user, 'is_guest', true));

        if (preference) {
          dispatch({
            type: 'UPDATE_PREFERENCE',
            payload: {
              has_modular_kitchen: preference.has_modular_kitchen,
              layout_type: preference.layout_type,
              carpet_area: preference.carpet_area,
            },
          });
        }
        if (user.role === 'user') {
          dispatch({ type: 'SHOW_PROFILE_SETUP_POPUP' });
        }
      })
      .catch(e => {
        if (e.message === 'logout') {
          clearSession();
          history.replace('/login');
        }
      });
  };

  useEffect(() => {
    const token = getToken();
    if (token) {
      getUserInfoFromApi();
    }
  }, []);

  useEffect(() => {
    if (userInfo._id && process.env.NODE_ENV === 'production') {
      FullStory.identify(userInfo._id, { email: userInfo.email });
    }
  }, [isLoggedInUser]);

  const handleLogin = ({ token }) => {
    setToken(token);
    getUserInfoFromApi();
  };

  const handleLogout = () => {
    clearSession();
    googleLogout();
    window.location.href = '/';
  };

  const setUserRole = role => {
    setUserInfo({ ...userInfo, role, is_guest: false });
  };

  const {
    user_name: userName,
    email,
    gravatar_url: profileUrl,
    contact_number: contactNumber,
    role,
  } = userInfo || {};

  // once the user has logged in, you can check localStorage
  // or the login request that the user makes
  const isLoginEnabled = !isGuestUser && (isLoggedInUser || isLoggedInUserFn());

  return (
    <Provider
      value={{
        profileUrl,
        contactNumber,
        email,
        userName,
        profileName: userName ? userName[0].toUpperCase() : 'H',
        isLoggedInUser: isLoginEnabled,
        isGuestUser,
        role,
        onLogin: handleLogin,
        onLogout: handleLogout,
        setUserRole,
      }}
    >
      {children}
    </Provider>
  );
};

UserProvider.propTypes = {
  children: element.isRequired,
};

export default UserContext;
export { UserProvider, Consumer as UserConsumer };
