import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { AddPattern } from './AddPattern';
import { doPost } from '../../../utils';

const FinishCategoryDetail = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();

  const [formDetail, setFormDetail] = useState({});
  const { finishes = [] } = useSelector(state => state.vendor);
  const [buttonText, setButtonText] = useState('Save');
  const [finishImage, setFinishImage] = useState(null);

  const { name, patterns = [] } = finishes.find(finish => finish.slug === slug) || {};
  const isEmptyState = patterns.length === 0;

  useEffect(() => {
    setFormDetail({
      name,
      slug,
    });
  }, [name, slug]);

  const formSubmitHandler = e => {
    e.preventDefault();
    setButtonText('Saving...');
    const bodyFormData = new FormData();

    if (finishImage) {
      const [file] = finishImage;
      bodyFormData.set('finish_image', file);
    }

    for (const attribute of Object.keys(formDetail)) {
      bodyFormData.set(attribute, formDetail[attribute]);
    }

    doPost({
      url: `/vendor/category/${slug}/details`,
      body: bodyFormData,
    })
      .then(() => {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'success', message: 'Updated successfully.' },
        });
        setButtonText('Save');
      })
      .catch(() => {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'failure', message: 'Failed to save finish details' },
        });
      });
  };

  const changeHandler = e => {
    const { value } = e.target;
    setFormDetail({
      ...formDetail,
      name: value,
    });
  };

  return (
    <div>
      <AddPattern />
      <div className="section-container">
        <h2 className="vendor-title">Finish Category Details</h2>
        <form onSubmit={formSubmitHandler} className="v2-form">
          <div className="form-group row">
            <label htmlFor="inputPassword3" className="col-sm-2 col-form-label">
              Slug
            </label>
            <div className="col-sm-10">
              <input
                readOnly
                type="text"
                className="form-control"
                id="inputPassword3"
                placeholder="Password"
                value={slug}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">
              Name
            </label>
            <div className="col-sm-10">
              <input
                type="text"
                className="form-control"
                id="inputEmail3"
                placeholder="Email"
                value={formDetail.name}
                onChange={changeHandler}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="finish_image" className="col-sm-2 col-form-label">
              Catalog Image
            </label>
            <div className="col-sm-10">
              <input
                className="form-control"
                type="file"
                name="finish_image"
                id="finish_image"
                onChange={e => setFinishImage(e.target.files)}
              />
            </div>
          </div>

          <div className="form-group row">
            <div className="col-sm-10 offset-sm-2">
              <button type="submit" className="btn btn-primary round-border">
                {buttonText}
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="section-container">
        <h2 className="vendor-title">
          Available Patterns {!isEmptyState && <span>- ({patterns.length})</span>}
        </h2>

        {isEmptyState && (
          <div className="col-sm-12 white-background-color empty-state round-border grey-border">
            <p
              style={{
                fontWeight: '600',
              }}
            >
              Please add Patterns via Excel
            </p>
          </div>
        )}

        <ul className="card-container">
          {patterns.map(pattern => {
            return (
              <li>
                <div
                  className="card-item category-card blur-image white-background-color"
                  style={{
                    'background-image': `url(${pattern.image_url})`,
                    'background-size': 'cover',
                  }}
                >
                  <span className="upper-case-text bold-font">{pattern.name}</span>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export { FinishCategoryDetail };
