import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FullPageModal } from '../../Shared';
import { VendorAction } from '../../../actions';

const RequestSampleModal = () => {
  const {
    requestSample: { data, show },
  } = useSelector(state => state.modal);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (data) {
      setFormData(data);
    }
  }, [data]);

  if (!show) {
    return null;
  }

  const handleRequestSample = e => {
    e.preventDefault();
    if (!formData) {
      return;
    }
    dispatch(VendorAction.requestSample(formData))
      .then(() => {
        return dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'success', message: 'Requested sample successfully.' },
        });
      })
      .then(() => {
        dispatch({
          type: 'HIDE_REQUEST_SAMPLE',
        });
      });
  };

  const handleClose = () => {
    dispatch({
      type: 'HIDE_REQUEST_SAMPLE',
    });
  };

  const onChangeHandler = e => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <FullPageModal
      open={show}
      onClose={handleClose}
      styleClasses={['tw-max-w-4xl', 'tw-mx-auto', 'tw-max-h-4xl']}
    >
      <div className="tw-flex tw-flex-row tw-h-full">
        <div
          className="tw-w-1/2  tw-h-full rounded-lg"
          style={{
            backgroundImage:
              "url('https://images.unsplash.com/photo-1615184697985-c9bde1b07da7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80')",
            backgroundSize: 'cover',
          }}
        />
        <div className="tw-w-1/2 relative tw-h-full tw-flex tw-flex-row tw-relative">
          <form action="" className="tw-p-6 tw-w-full" onSubmit={handleRequestSample}>
            <h3 className="tw-text-2xl tw-pb-3 tw-border-b tw-border-gray-200 tw-mb-8">
              Request Details
            </h3>
            <div className="tw-mb-4">
              <label className="tw-text-gray-700 tw-block" htmlFor="name">
                Name*
              </label>
              <input
                required
                className="tw-border tw-border-gray-200 tw-block tw-h-10 tw-w-full"
                type="text"
                name="name"
                id="name"
                onChange={onChangeHandler}
              />
            </div>
            <div className="tw-mb-4">
              <label className="tw-text-gray-700 tw-block" htmlFor="contact">
                Phone No*
              </label>
              <input
                className="tw-border tw-border-gray-200 tw-block tw-h-10 tw-w-full"
                required
                type="tel"
                name="phone_number"
                id="phone_number"
                onChange={onChangeHandler}
              />
            </div>
            <div className="tw-mb-4">
              <label className="tw-text-gray-700 tw-block" htmlFor="email">
                Email Address*
              </label>
              <input
                className="tw-border tw-border-gray-200 tw-block tw-h-10 tw-w-full"
                required
                type="email"
                name="email"
                id="email"
                onChange={onChangeHandler}
              />
            </div>
            <div className="tw-mb-4">
              <label className="tw-text-gray-700 tw-block" htmlFor="details">
                Sample Details*
              </label>
              <textarea
                id="message"
                name="message"
                required
                rows="4"
                cols="10"
                onChange={onChangeHandler}
                className="tw-border tw-border-gray-200 tw-block tw-h-32 tw-w-full"
              />
            </div>
            <div className="tw-absolute tw-bottom-0 tw-right-0 tw-w-full tw-flex tw-flex-row tw-justify-end">
              <button
                type="button"
                onClick={handleClose}
                className="tw-w-1/3 tw-px-4 tw-py-2 tw-text-center tw-bg-gray-200 tw-mr-5"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="tw-w-1/3 tw-px-4 tw-py-2 tw-text-center tw-bg-cta tw-text-white"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </FullPageModal>
  );
};

export { RequestSampleModal };
