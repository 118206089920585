const getCatalogCode = ({ count, catalog }) => {
  const prefix = catalog?.catalog_prefix;
  if (!count || !prefix) {
    return '';
  }

  return `hc-${count}-${prefix}`;
};

export { getCatalogCode };
