import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { ReactComponent as BugIcon } from 'assets/resource-center/bug.svg';
import { ReactComponent as AssumptionsIcon } from 'assets/resource-center/assumptions.svg';
import { ReactComponent as CalculatorIcon } from 'assets/resource-center/calculator.svg';
import { ReactComponent as FaqIcon } from 'assets/resource-center/faq.svg';
import { ReactComponent as GlossaryIcon } from 'assets/resource-center/glossary.svg';
import { ReactComponent as RequestIcon } from 'assets/resource-center/request.svg';
import { ReactComponent as SupportIcon } from 'assets/resource-center/support.svg';
import { ReactComponent as UpdatesIcon } from 'assets/resource-center/updates.svg';
import { isMobile } from 'react-device-detect';

const urlsToSkip = {
  desktop: ['/boqs/[0-9]+/summary'],
  mobile: ['/boqs/[0-9]+/summary', '/explore'],
  tablet: [],
};

const ResourceCenter = () => {
  const [open, setOpen] = React.useState(false);
  const [shouldDisplay, setShouldDisplay] = useState(false);
  const location = useLocation();

  useEffect(() => {
    let urls;
    if (!isMobile) {
      urls = urlsToSkip.desktop;
    } else {
      urls = urlsToSkip.mobile;
    }
    const result = urls.every(url => !location.pathname.match(new RegExp(url)));
    setShouldDisplay(result);
  }, [location]);

  return (
    <>
      {shouldDisplay && (
        <div
          className="d-none d-sm-block tw-fixed tw-z-50 tw-w-full tw-flex tw-flex-col tw-justify-end tw-items-end"
          style={{ bottom: '20px', right: '20px', zIndex: 9999, maxWidth: '375px', width: 'auto' }}
        >
          {open && (
            <div
              className="tw-flex tw-w-full tw-flex-col tw-bg-white tw-rounded-lg tw-overflow-hidden"
              style={{ minHeight: '400px', maxWidth: '375px' }}
            >
              <div
                className="tw-min-h-[150px] tw-bg-cta tw-w-full tw-p-4 tw-pb-16 tw-relative"
                style={{ top: '-3px' }}
              >
                <img src="/logo-white.png" alt="logo-white.png" className="tw-mb-4" />
                <h2 className="tw-font-bold tw-text-white tw-text-lg">Hi, Welcome!</h2>
                <p className="tw-text-white">Let us help you with what you might need.</p>
              </div>
              <div
                className="tw-shadow-xl tw-relative tw-mx-auto tw-w-10/12 tw-bg-white tw-rounded-lg"
                style={{ top: '-50px' }}
              >
                <h2 className="tw-font-bold tw-text-lg tw-pl-4 tw-pt-4">Resource Center</h2>
                <p className="tw-pl-4">Get started with these tools below</p>
                <ul className="tw-flex tw-flex-row tw-flex-wrap tw-border-t">
                  <li className="tw-w-1/2" style={{ minHeight: '70px' }}>
                    <Link
                      onClick={() => setOpen(false)}
                      to="/calculator"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-py-3"
                    >
                      <CalculatorIcon />
                      Calculator Guide
                    </Link>
                  </li>
                  <li
                    className="tw-w-1/2"
                    style={{ minHeight: '70px', backgroundColor: '#FFFAF8' }}
                  >
                    <Link
                      onClick={() => setOpen(false)}
                      to="/frequently-asked-questions"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-py-3"
                    >
                      <FaqIcon />
                      FAQs
                    </Link>
                  </li>
                  <li
                    className="tw-w-1/2"
                    style={{ minHeight: '70px', backgroundColor: '#FFFAF8' }}
                  >
                    <Link
                      onClick={() => setOpen(false)}
                      to="/assumptions"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-py-3"
                    >
                      <AssumptionsIcon />
                      Assumptions
                    </Link>
                  </li>
                  <li className="tw-w-1/2" style={{ minHeight: '70px' }}>
                    <Link
                      onClick={() => setOpen(false)}
                      to="/support"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-py-3"
                    >
                      <SupportIcon />
                      Contact Support
                    </Link>
                  </li>
                  <li className="tw-w-1/2" style={{ minHeight: '70px' }}>
                    <Link
                      onClick={() => setOpen(false)}
                      to="/updates"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-py-3"
                    >
                      <UpdatesIcon />
                      Product Updates
                    </Link>
                  </li>
                  <li
                    className="tw-w-1/2"
                    style={{ minHeight: '70px', backgroundColor: '#FFFAF8' }}
                  >
                    <Link
                      onClick={() => setOpen(false)}
                      to="/request-a-feature"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-py-3"
                    >
                      <RequestIcon />
                      Request a feature
                    </Link>
                  </li>
                  <li
                    className="tw-w-1/2"
                    style={{ minHeight: '70px', backgroundColor: '#FFFAF8' }}
                  >
                    <Link
                      onClick={() => setOpen(false)}
                      to="/report-bug"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-py-3"
                    >
                      <BugIcon />
                      Submit a bug
                    </Link>
                  </li>
                  <li className="tw-w-1/2" style={{ minHeight: '70px' }}>
                    <Link
                      onClick={() => setOpen(false)}
                      to="/glossary"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-py-3"
                    >
                      <GlossaryIcon />
                      Glossary
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          )}
          <div
            className="tw-bg-white tw-rounded-full tw-h-16 tw-w-16 tw-flex tw-justify-center tw-items-center tw-shadow-xl tw-cursor-pointer"
            onClick={() => setOpen(!open)}
          >
            {!open && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="tw-h-10 tw-w-10 tw-text-cta"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                  clipRule="evenodd"
                />
              </svg>
            )}
            {open && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="tw-h-10 tw-w-10 tw-text-cta"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ResourceCenter;
