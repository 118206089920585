import React from 'react';
import { Link } from 'react-router-dom';
import { string, arrayOf, shape } from 'prop-types';

import './style.scss';

const row = (item, editUrlSegment = '') => {
  const { id, label, description, alias = [] } = item;
  return (
    <tr key={id}>
      <td>
        <Link to={`/${editUrlSegment}/${id}`}>{label}</Link>
      </td>
      <td>
        {description}
        <div>
          {alias.map(term => {
            return (
              <span key={term} className="alias-badge badge badge-secondary">
                {term}
              </span>
            );
          })}
        </div>
      </td>
    </tr>
  );
};

const CatalogTable = ({ items, editUrlSegment }) => {
  return (
    <table className="table table-hover tabs-container">
      <thead>
        <tr>
          <th scope="col">Item name</th>
          <th scope="col">Description</th>
        </tr>
      </thead>
      <tbody>{items.map(item => row(item, editUrlSegment))}</tbody>
    </table>
  );
};

CatalogTable.propTypes = {
  items: arrayOf(
    shape({
      id: string,
      label: string,
      alias: arrayOf(string),
    }),
  ).isRequired,
  editUrlSegment: string.isRequired,
};

export { CatalogTable };
