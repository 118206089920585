import React from 'react';

import { Table } from 'components/Shared';

function Hardware() {
  const hardware = [
    ['Ball Bearing Telescopic Channel', 'Rado, Baba'],
    ['Butt / L / Duck Hinges', 'Hero, Hiren'],
    ['Sliding Door Fittings', 'CNR, Enox'],
    ['Handle & Knob', 'Fab, Jippo, Toons, Klik, Mozo, Modern'],
    ['Bed Hydraulic Fitting', 'Vagad'],
    ['Magnetic Ballcage - M2', 'Goodluck'],
    ['Screws', 'Omni'],
    ['Nails', 'Badshah'],
    ['Tower Bolt', 'Hero, Hiren'],
    ['Table Chain', 'S.S. - Local make'],
    ['Wardrobe lock', 'Vijayan'],
    ['Multi lock', 'Godrej, Vijayan'],
    ['Door Lock & Mortise Handle', 'Hansa, Sahara, Doorset, Hero'],
    ['Dead Lock', 'Vijayan'],
    ['Main door lock', 'Europa, Godrej'],
    ['Hanger Rod - Round / Oval Shape', 'S.S. - Local make'],
    ['Collaspsible Hardware - Indian', 'Enox, CNR, Gravity, Kainya'],
    ['Collaspsible Hardware - Imported', 'Quba, Godrej'],
  ];

  const datas = [{ heading: 'HARDWARE', content: hardware }];
  const tableHeadings = ['Material', 'Brand'];

  return (
    <div>
      {datas.map(data => (
        <>
          <div>
            <Table headers={tableHeadings.map(heading => ({ title: heading }))}>
              {data.content.map(items => {
                return (
                  <>
                    <td>{items[0]}</td>
                    <td>{items[1]}</td>
                  </>
                );
              })}
            </Table>
          </div>
        </>
      ))}
    </div>
  );
}

export { Hardware };
