import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { func } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { SimpleModal } from '../../Shared';
import { useToast } from '../../../hooks';
import { doPost } from '../../../utils';
import { getCatalogCode } from './utils';

const AddVendorCatalog = ({ onComplete }) => {
  const dispatch = useDispatch();
  const { errorToast } = useToast();
  const { slug: vendorSlug } = useParams();
  const { finishes, vendors, brands } = useSelector(state => state.vendor);
  const [form, setForm] = useState({
    slug: vendorSlug,
  });
  const [catalogImage, setCatalogImage] = useState(null);
  const [catalogDetail, setCatalogDetail] = useState(null);
  const vendor = vendors.find(item => item.slug === vendorSlug) || {};

  const [availablePatterns, setAvailablePatterns] = useState([]);
  const [selectedCatalog, setSelectedCatalog] = useState(null);

  const {
    vendorCatalog: { show = false, data: { count } = {} },
  } = useSelector(state => state.modal);

  useEffect(() => {
    let catalog = [];
    if (selectedCatalog) {
      catalog = finishes.find(finish => finish._id === selectedCatalog) ?? [];
    } else {
      // eslint-disable-next-line prefer-destructuring
      catalog = finishes[0];
    }
    const patterns = catalog?.patterns ?? [];
    setAvailablePatterns(patterns);
    setForm({
      ...form,
      catalog_code: getCatalogCode({
        count,
        catalog,
      }),
    });
  }, [selectedCatalog]);

  useEffect(() => {
    const defaultSelected = finishes[0] ?? [];
    const pattern = defaultSelected?.patterns ?? [];
    if (Array.isArray(pattern) && pattern.length > 0) {
      const chosenPattern = pattern[0];
      setAvailablePatterns(pattern);
      setForm({
        ...form,
        pattern_id: chosenPattern._id,
        catalog_code: getCatalogCode({ count, catalog: defaultSelected }),
      });
    }
  }, [finishes]);

  useEffect(() => {
    if (count) {
      let catalog = finishes[0];
      if (selectedCatalog) {
        catalog = finishes.find(finish => finish._id === selectedCatalog) ?? [];
      }
      setForm({
        ...form,
        catalog_code: getCatalogCode({ count, catalog }),
      });
    }
  }, [count]);

  if (!show) {
    return null;
  }

  const onCloseHandler = () => {
    dispatch({ type: 'HIDE_ADD_VENDOR_CATALOG' });
  };

  const onFormSubmitHandler = e => {
    e.preventDefault();
    if (!form.vendor_id) {
      form.vendor_id = vendor._id;
    }
    if (!form.brand_id) {
      form.brand_id = brands[0]?._id;
    }
    if (!catalogImage || !catalogDetail) {
      errorToast('Please select a valid file');
      return;
    }
    const [file] = catalogImage;
    const [file1] = catalogDetail;

    const bodyFormData = new FormData();
    bodyFormData.set('catalog_image', file);
    bodyFormData.set('catalog_detail', file1);
    bodyFormData.set('slug', vendorSlug);
    for (const attribute of Object.keys(form)) {
      bodyFormData.set(attribute, form[attribute]);
    }

    doPost({
      url: `/vendor/vendor/${vendorSlug}/catalog`,
      body: bodyFormData,
    })
      .then(() => {
        dispatch({ type: 'HIDE_ADD_VENDOR_CATALOG' });
        if (onComplete) {
          onComplete();
        }
      })
      .catch(() => {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'failure', message: 'Failed to add Item to vendor catalog' },
        });
      });
  };

  const onCatalogChangeHandler = e => {
    const chosenCatalogId = e.target.value;
    setSelectedCatalog(chosenCatalogId);
    setForm({
      ...form,
      vendor_id: vendor._id,
    });
  };
  const onInputChangeHandler = e => {
    const { value, id } = e.target;

    setForm({
      ...form,
      [id]: value,
    });
  };

  const onPatternChangeHandler = e => {
    const { value } = e.target;
    if (!value) {
      return;
    }

    setForm({
      ...form,
      pattern_id: value,
    });
  };

  const onBrandChangeHandler = e => {
    const { value } = e.target;
    if (!value) {
      return;
    }

    setForm({
      ...form,
      brand_id: value,
    });
  };

  return (
    <SimpleModal open={show} classes="tw-relative" onClose={onCloseHandler} fullWidth maxWidth="sm">
      <h4 className="center-align">Add Catalog</h4>
      <form onSubmit={onFormSubmitHandler} encType="multipart/form-data">
        <div className="form-group">
          <label htmlFor="finishCatalog">Select type of finish Category</label>
          <select
            name="finishCatalog"
            id="finishCatalog"
            className="form-control"
            onChange={onCatalogChangeHandler}
          >
            {finishes.map(item => {
              const { slug } = item;
              return (
                <option key={slug} value={item._id}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="pattern">Select type of Pattern</label>
          <select
            name="pattern"
            id="pattern"
            className="form-control"
            onChange={onPatternChangeHandler}
          >
            {availablePatterns.map(item => {
              const { slug } = item;
              return (
                <option key={slug} value={item._id}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="brand">Brand</label>
          <select name="brand" id="brand" className="form-control" onChange={onBrandChangeHandler}>
            {brands.map(item => {
              const { slug } = item;
              return (
                <option key={slug} value={item._id}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="name">Item Name</label>
          <input
            required
            type="text"
            name="name"
            id="name"
            className="form-control"
            onChange={onInputChangeHandler}
          />
        </div>
        <div className="row">
          <div className="col-sm-6">
            <label htmlFor="base_price">Base Price</label>
            <input
              required
              type="text"
              name="base_price"
              id="base_price"
              className="form-control"
              onChange={onInputChangeHandler}
            />
          </div>
          <div className="col-sm-6">
            <label htmlFor="max_price">Max Price</label>
            <input
              required
              type="text"
              name="max_price"
              id="max_price"
              className="form-control"
              onChange={onInputChangeHandler}
            />
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="catalog_code">Catalog code</label>
          <input
            required
            type="text"
            name="catalog_code"
            id="catalog_code"
            className="form-control"
            value={form.catalog_code}
            onChange={onInputChangeHandler}
          />
        </div>
        <div className="form-group">
          <label htmlFor="catalog_image" className="form-label">
            Catalog Image
          </label>
          <input
            className="form-control"
            type="file"
            name="catalog_image"
            id="catalog_image"
            onChange={e => setCatalogImage(e.target.files)}
          />
        </div>
        <div className="form-group">
          <label htmlFor="catalog_detail" className="form-label">
            Catalog PDF
          </label>
          <input
            className="form-control"
            type="file"
            name="catalog_detail"
            id="catalog_detail"
            onChange={e => setCatalogDetail(e.target.files)}
          />
        </div>
        <button type="submit" className="btn btn-primary btn-block">
          Add
        </button>
      </form>
    </SimpleModal>
  );
};

AddVendorCatalog.propTypes = {
  onComplete: func.isRequired,
};

export { AddVendorCatalog };
