import { doGet, doPost } from '../utils';

/**
 * mapped to /vendors
 */
class VendorAction {
  static fetchDetails() {
    return async dispatch => {
      const body = await doGet({
        url: '/vendor',
      });
      return dispatch({
        type: 'FETCH_VENDOR',
        payload: { ...body },
      });
    };
  }

  static updateCategory({ slug, ...others }) {
    return async dispatch => {
      const body = await doPost({
        url: `/vendor/category/${slug}/details`,
        body: { ...others },
      });
      return dispatch({
        type: 'UPDATE_CATEGORY',
        payload: { ...body },
      });
    };
  }

  static updateVendorDetail({ slug, ...others }) {
    return async dispatch => {
      const body = await doPost({
        url: `/vendor/vendor/${slug}/details`,
        body: { ...others },
      });
      return dispatch({
        type: 'UPDATE_VENDOR_DETAIL',
        payload: { ...body },
      });
    };
  }

  static updateVendorCatalog({ slug, ...others }) {
    return async dispatch => {
      const body = await doPost({
        url: `/vendor/vendor/${slug}/catalog`,
        body: { ...others },
      });
      return dispatch({
        type: 'UPDATE_VENDOR',
        payload: { ...body },
      });
    };
  }

  static requestSample(body) {
    return async () => {
      await doPost({
        url: '/vendor/request-sample',
        body,
      });
    };
  }

  static contactVendor(body) {
    return async () => {
      await doPost({
        url: '/vendor/contact-vendor',
        body,
      });
    };
  }

  static getCatalogCount() {
    return async () => {
      return doGet({
        url: '/vendor/vendor/catalog/count',
      });
    };
  }
}

export { VendorAction };
