import React from 'react';
import { string, func } from 'prop-types';

import './style.scss';

class Form extends React.Component {
  changeListener = e => {
    const { onChange } = this.props;
    onChange(e);
  };

  handleSubmit = e => {
    e.preventDefault();
    const { onSubmit } = this.props;
    onSubmit(e);
  };

  render() {
    const { name, alias, caption } = this.props;
    const disabled = !name || !alias;
    return (
      <div className="form-highlight">
        <form method="POST" action="" onSubmit={this.handleSubmit}>
          <div className="form-group row">
            <label htmlFor="name" className="col-2 col-form-label">
              Name
            </label>
            <div className="col-10">
              <input
                type="text"
                className="form-control"
                name="material"
                id="name"
                required
                value={name}
                onChange={this.changeListener}
              />
            </div>
          </div>

          <div className="form-group row">
            <label htmlFor="alias" className="col-2 col-form-label">
              Alias
            </label>
            <div className="col-10">
              <textarea
                required
                className="form-control"
                name="alias"
                id="alias"
                onChange={this.changeListener}
                value={alias}
              />
            </div>
          </div>

          <div className="form-group">
            <input
              type="submit"
              className="btn btn-primary offset-2"
              disabled={disabled}
              value={caption}
            />
          </div>
        </form>
      </div>
    );
  }
}

Form.defaultProps = {
  name: '',
  alias: '',
};

Form.propTypes = {
  name: string,
  alias: string,
  caption: string.isRequired,
  onChange: func.isRequired,
  onSubmit: func.isRequired,
};

export { Form };
