import React from 'react';
import { node } from 'prop-types';

class ErrorView extends React.Component {
  constructor() {
    super();
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(error) {
    console.error(error);
    return { hasError: true };
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      // @TODO: add error page.
      return <h1>Something went wrong!</h1>;
    }
    return children;
  }
}

ErrorView.propTypes = {
  children: node.isRequired,
};

export { ErrorView };
