const initialState = {
  filterForMobileReducer: { services: { carpentry: {}, civil: {} }, selection: {} },
};
function filterForMobileReducer(state = initialState.filterForMobileReducer, action) {
  switch (action.type) {
    case 'RESET_MOBILE_FILTER':
      return { ...state, ...initialState.filterForMobileReducer };
    case 'UPDATE_MOBILE_FILTER':
      return { ...state, services: { ...state.services, ...action.payload } };
    case 'UPDATE_MOBILE_FILTER_SELECTION':
      return { ...state, selection: { ...state.selection, ...action.payload } };
    default:
      return state;
  }
}

export { filterForMobileReducer };
