import React, { useRef, useState } from 'react';
import { arrayOf, bool, string, shape } from 'prop-types';
import { useHistory } from 'react-router-dom';

import { useDetectOutsideClick } from 'hooks';

function DropDownMenu({ label, menuOptions }) {
  const ref = useRef(null);
  const history = useHistory();
  const [shouldDisplayList, updateShouldDisplayList] = useState(false);
  useDetectOutsideClick(ref, () => updateShouldDisplayList(false));

  const handleOnClick = ({ href, isExternalLink }) => {
    updateShouldDisplayList(false);
    if (isExternalLink) {
      window.open(href);
    } else {
      history.push(href);
    }
  };

  return (
    <div className="select-wrapper tw-relative">
      <li
        className="nav-item menu-item"
        onClick={() => updateShouldDisplayList(!shouldDisplayList)}
      >
        <span className="tw-w-3/4 text-2-color">{label}</span>
      </li>

      {shouldDisplayList && (
        <div
          className="tw-absolute tw-mt-1 tw-w-max tw-rounded-md tw-bg-white tw-shadow-lg tw-z-50"
          ref={ref}
        >
          <ul
            tabIndex="-1"
            className="tw-rounded-md tw-py-1 tw-overflow-auto focus:tw-outline-none sm:tw-text-sm"
          >
            {/* eslint-disable-next-line no-shadow */}
            {menuOptions.map(({ label, href, isExternalLink = false }) => (
              <li
                key={label}
                className="tw-text-gray-900 tw-cursor-default tw-relative tw-py-2 hover:tw-text-cta"
                onClick={() => handleOnClick({ href, isExternalLink })}
              >
                <div className="tw-flex items-center tw-pl-4 tw-pr-4">
                  <span className="tw-block tw-truncate tw-cursor-pointer">{label}</span>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

DropDownMenu.propTypes = {
  label: string.isRequired,
  menuOptions: arrayOf(
    shape({
      label: string.isRequired,
      href: string.isRequired,
      isExternalLink: bool.isRequired,
    }),
  ).isRequired,
};

DropDownMenu.defaultProps = {};

export { DropDownMenu };
