import React from 'react';
import { string, func, shape, arrayOf } from 'prop-types';

function RoomForm({ formData, updateFormData, onSubmit, formLabel }) {
  return (
    <div className="tw-flex">
      <form className="tw-w-full">
        <div className="tw-order-1 tw-mx-3  tw-my-3">
          <label
            htmlFor="roomName"
            className="tw-block tw-uppercase tw-tracking-wide tw-text-gray-700 tw-text-xs tw-font-bold tw-mb-2"
          >
            Room Name
            <input
              className="tw-appearance-none tw-block tw-w-full tw-text-gray-700 tw-border tw-border-gray-200 tw-rounded tw-py-1 tw-px-2 tw-mb-3 tw-leading-tight focus:tw-outline-none focus:tw-bg-white focus:tw-border-gray-500"
              type="text"
              id="roomName"
              value={formData.name}
              onChange={e => updateFormData('name', e.target.value)}
            />
          </label>
        </div>

        <div className="tw-order-2 tw-mx-3 tw-my-3">
          <label
            htmlFor="alias"
            className="tw-block tw-uppercase tw-tracking-wide tw-text-gray-700 tw-text-xs tw-font-bold mb-2"
          >
            Alias
            <textarea
              id="alias"
              className="tw-appearance-none tw-block tw-w-full tw-text-gray-700 tw-border tw-border-gray-200 tw-rounded tw-py-1 tw-px-2 tw-mb-3 tw-leading-tight focus:tw-outline-none focus:tw-bg-white focus:tw-border-gray-500"
              value={formData.alias.join(',')}
              onChange={e => updateFormData('alias', e.target.value)}
            />
          </label>
        </div>

        <div className="tw-flex tw-flex-row-reverse tw-mx-3">
          <button
            type="button"
            className="tw-fill-current tw-bg-orange-500 tw-p-2 tw-rounded-md"
            onClick={onSubmit}
          >
            {formLabel}
          </button>
        </div>
      </form>
    </div>
  );
}

RoomForm.propTypes = {
  formData: shape({
    name: string.isRequired,
    alias: arrayOf(string).isRequired,
  }).isRequired,
  updateFormData: func.isRequired,
  onSubmit: func.isRequired,
  formLabel: string.isRequired,
};

export { RoomForm };
