import { BoqAction } from './BoqAction';
import { doGet, doPatch, doPost } from '../utils';
import { ACTION_CONSTANTS } from './constants';
import { RevisionHistoryAction } from './RevisionHistoryAction';

/**
 * mapped to /soft-furnishing
 */
class SoftFurnishingAction {
  static get(boqId) {
    return async dispatch => {
      try {
        const { data: payload } = await doGet({ url: `/soft-furnishing?boq_id=${boqId}` });
        dispatch({ type: 'FETCH_SOFT_FURNISHING_BOQ', payload });
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static fetchById(boqId, id) {
    return async dispatch => {
      try {
        const { data: payload } = await doGet({ url: `/soft-furnishing/${id}?boq_id=${boqId}` });
        dispatch({ type: 'UPDATE_BOQ_SOFT_FURNISHING_ITEM_FORM', payload });
        dispatch({ type: 'UPDATE_BOQ_SOFT_FURNISHING_ITEM', payload });
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static update(boqId, formData) {
    return async dispatch => {
      try {
        const { id } = formData;
        await doPatch({
          url: `/soft-furnishing/${id}?boq_id=${boqId}`,
          body: { area: Number(formData.area), rate: Number(formData.rate) },
        });
        dispatch(BoqAction.fetchBoqDetails(boqId));
        dispatch(SoftFurnishingAction.get(boqId));
        dispatch({ type: 'HIDE_VIEW_BOQ_SOFT_FURNISHING' });
        dispatch({
          type: 'UPDATE_BOQ_SOFT_FURNISHING_EDIT_MODE',
          payload: { display: false, formData: {} },
        });
        dispatch(RevisionHistoryAction.fetchList(boqId));
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'success', message: ACTION_CONSTANTS.itemUpdated },
        });
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static getEstimate(oldData, newData) {
    return async dispatch => {
      try {
        dispatch({
          type: 'UPDATE_BOQ_SOFT_FURNISHING_ITEM_STATE',
          payload: { displayCostLoader: true },
        });
        const { data: payload } = await doPost({
          url: '/soft-furnishing/estimate',
          body: {
            ...newData,
            finish: newData.finish.label,
          },
        });
        dispatch({ type: 'UPDATE_BOQ_SOFT_FURNISHING_ITEM', payload });
        dispatch({ type: 'UPDATE_BOQ_SOFT_FURNISHING_ITEM_FORM', payload });
      } catch (e) {
        console.error(e);
        dispatch({ type: 'UPDATE_BOQ_SOFT_FURNISHING_ITEM_FORM', payload: oldData });
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      } finally {
        dispatch({
          type: 'UPDATE_BOQ_SOFT_FURNISHING_ITEM_STATE',
          payload: { displayCostLoader: false },
        });
      }
    };
  }
}

export { SoftFurnishingAction };
