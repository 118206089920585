const PERMISSIONS = {
  designer: new Set([
    'edit_labour_rate',
    'edit_material_rate',
    'edit_item',
    'bulk_edit',
    'revision_history',
    'edit_manual_items',
    'add_manual_items',
    'assign_boq',
    'add_boq_item_comment',
    'upload_ref_images',
    'upload_boq',
    'add_boq_item',
    'delete_boq_item',
    'edit_design_fee',
    'view_filter_menu',
    'view_search_menu',
    'download_boq_summary_report',
    'download_boq_detailed_report',
    'view_dashboard',
  ]),
  'home-owner': new Set([
    'edit_item',
    'bulk_edit',
    'revision_history',
    'edit_manual_items',
    'add_manual_items',
    'add_boq_item_comment',
    'upload_ref_images',
    'add_boq_item',
    'delete_boq_item',
    'view_filter_menu',
    'view_search_menu',
    'download_boq_summary_report',
    'download_boq_detailed_report',
    'view_dashboard',
  ]),
  user: new Set([
    'edit_item',
    'bulk_edit',
    'revision_history',
    'edit_manual_items',
    'add_manual_items',
    'add_boq_item_comment',
    'upload_ref_images',
    'add_boq_item',
    'delete_boq_item',
    'view_filter_menu',
    'view_search_menu',
    'download_boq_summary_report',
    'download_boq_detailed_report',
    'view_dashboard',
  ]),
};

export { PERMISSIONS };
