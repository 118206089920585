import React, { useState } from 'react';
import { string, shape } from 'prop-types';

import _ from 'lodash';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import { doPost } from 'utils';

import './reset-password-style.scss';

const ResetPassword = ({ location: { search } = {} }) => {
  const [password, setPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [message, setMessage] = useState({ content: null, type: 'info' });
  const history = useHistory();

  const handleSubmit = e => {
    e.preventDefault();
    if (!password || !confirmPassword) {
      return setMessage({
        content: 'Please fill the details to reset the password',
        type: 'error',
      });
    }
    if (password !== confirmPassword) {
      return setMessage({ content: 'Password and confirm password should match', type: 'error' });
    }

    if (password.length < 8) {
      return setMessage({
        content: 'Passwords should be atleast 8 or more character',
        type: 'error',
      });
    }

    const query = new URLSearchParams(search);
    const code = query.get('code');

    if (!code) {
      return setMessage({
        content:
          'Details required for setting password is missing, Please initiate a fresh password reset request.',
        type: 'error',
      });
    }

    return doPost({
      url: '/users/reset-password',
      body: { password, confirmPassword, code },
    })
      .then(response => {
        setMessage({ content: _.get(response, 'message'), type: 'info' });
        setTimeout(() => {
          history.replace('/login');
        }, 2000);
      })
      .catch(err => {
        setMessage({ content: _.get(err, 'message'), type: 'error' });
      });
  };

  const onChangeListener = e => {
    const { id, value } = e.target;
    if (id === 'password') {
      setPassword(value.trim());
    } else {
      setConfirmPassword(value.trim());
    }
  };

  const { content, type } = message;

  return (
    <main className="align-center">
      <div className="reset-password-container">
        <h1>Reset your password</h1>
        <form action="/reset-password" className="container" onSubmit={handleSubmit}>
          <div className="form-group error-msg">
            {content && (
              <p
                className={cx('alert', {
                  'alert-danger': type === 'error',
                  'alert-info': type === 'info',
                })}
              >
                <FontAwesomeIcon icon={faExclamationCircle} /> {content}
              </p>
            )}
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              className="form-control"
              id="password"
              required
              minimum={6}
              placeholder="Password"
              onChange={onChangeListener}
              value={password}
            />
          </div>
          <div className="form-group">
            <label htmlFor="confirmpassword">Confirm Password</label>
            <input
              type="password"
              className="form-control"
              id="confirmpassword"
              required
              placeholder="Confirm password"
              onChange={onChangeListener}
              value={confirmPassword}
            />
          </div>
          <button type="submit" className="btn btn-primary btn-block b-100-curved">
            Set a new Password
          </button>
        </form>
        <a href="/login">Back to Login</a>
      </div>
    </main>
  );
};

ResetPassword.propTypes = {
  location: shape({
    search: string,
  }).isRequired,
};

export { ResetPassword };
