import React, { useContext } from 'react';
import { arrayOf, shape, string, number } from 'prop-types';
import cx from 'classnames';

import UserContext from '../../../UserContext';
import { ViewMore } from './ViewMore';
import { VendorCard } from './VendorCard';
import { useCollapsable } from './useCollapsable';

import '../vendor-style.scss';

const VendorList = ({ vendors = [] }) => {
  const { role } = useContext(UserContext);
  const isAdmin = role === 'admin';

  const { count, items, onItemClick } = useCollapsable({
    collection: vendors,
    adminRedirectUrl: '/vendors/vendor/:slug/details',
  });

  return (
    <section className="section-container">
      <h5 className="vendor-title header-section">
        Vendors ({count})
        <span
          style={{
            marginRight: '3%',
          }}
        >
          {isAdmin && (
            <a href="/vendors/search" className="a-cta">
              Catalog
            </a>
          )}
        </span>
      </h5>
      <ul className="card-container">
        {items.map(vendor => {
          const { slug, name, rating, type, review_count: reviewCount } = vendor;
          return (
            <li key={slug} onClick={() => onItemClick({ slug, isAdmin })}>
              <div
                className={cx('card-item white-background-color', {
                  'center-content': type === 'viewMore',
                })}
              >
                {type !== 'viewMore' && (
                  <VendorCard name={name} rating={rating} reviewCount={reviewCount} />
                )}
                {type === 'viewMore' && <ViewMore />}
              </div>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

VendorList.propTypes = {
  vendors: arrayOf(
    shape({
      slug: string,
      name: string,
      rating: number,
      type: string,
      review_count: number,
    }),
  ).isRequired,
};

export { VendorList };
