import React from 'react';
import { bool, func, oneOfType, string, node } from 'prop-types';
import classNames from 'classnames';

function Btn({ text, onClick, className, disabled }) {
  const classes = classNames('dkwlk', className);
  return (
    <button type="button" className={classes} onClick={onClick} disabled={disabled}>
      {text}
    </button>
  );
}

Btn.propTypes = {
  text: oneOfType([string, node]),
  onClick: func.isRequired,
  className: string,
  disabled: bool,
};

Btn.defaultProps = {
  className: '',
  disabled: false,
  text: 'Button',
};

export { Btn };
