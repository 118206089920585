import React from 'react';
import { string, func } from 'prop-types';
import { IoIosExit } from 'react-icons/io';
import classNames from 'classnames';

function ExitBtn({ onClick, className = '', text }) {
  const classes = classNames('tw-flex', className);
  return (
    <button type="button" className={classes} onClick={onClick}>
      <IoIosExit className="tw-h-5 tw-w-5 tw-fill-current tw-text-cta" />
      <span className="cta tw-ml-1 d-none d-md-flex">{text}</span>
    </button>
  );
}

ExitBtn.defaultProps = {
  className: '',
  text: 'Cancel',
};

ExitBtn.propTypes = {
  onClick: func.isRequired,
  className: string,
  text: string,
};

export { ExitBtn };
