import React from 'react';
import { string } from 'prop-types';

import './sectionheader.scss';

const SectionHeader = ({ title }) => <h3 className="section-header">{title}</h3>;

SectionHeader.propTypes = {
  title: string.isRequired,
};

export { SectionHeader };
