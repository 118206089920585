import React from 'react';
import { Link } from 'react-router-dom';

const Calculator = () => {
  return (
    <div className="tw-container tw-mx-auto tw-my-8">
      <ul className="tw-flex tw-flex-row">
        <li className="tw-text-text2 tw-mr-2">
          <Link to="/">Home</Link>
        </li>
        <li className="tw-text-text2 tw-mr-2">/</li>
        <li className="tw-text-text2 tw-mr-2 tw-text-opacity-50">Calculator</li>
      </ul>
      <h2
        className="tw-text-text2 tw-font-bold tw-border-cta tw-border-4 tw-pl-2 tw-border-b-0 tw-border-r-0 tw-border-t-0"
        style={{ fontSize: '2rem', margin: '1em 0' }}
      >
        Getting Started
      </h2>
      <div className="tw-flex tw-flex-col lg:tw-flex-row tw-py-16">
        <div className="tw-w-full lg:tw-w-1/2">
          <span className="tw-text-cta tw-p-2 tw-border-cta tw-border-2 tw-rounded-full tw-w-8 tw-h-8 tw-inline-flex tw-justify-center tw-items-center tw-mr-2">
            1
          </span>
          <span className="tw-text-text2 tw-font-bold tw-text-xl">Select Apartment Type</span>
          <p className="tw-text-text2 tw-pl-8 tw-mt-2">
            Choose the option that best matches your apartment. Eg. If you have a 3 Bedroom
            Apartment, select 3BHK.
          </p>
        </div>
        <div className="tw-w-full lg:tw-w-1/2">
          <img src="/steps/step1.png" alt="select apartment type" />
        </div>
      </div>
      <div className="tw-flex tw-flex-col-reverse lg:tw-flex-row tw-py-16">
        <div className="tw-w-full lg:tw-w-1/2">
          <img src="/steps/step2.png" alt="Select Type of Bedrooms" />
        </div>
        <div className="tw-w-full lg:tw-w-1/2 lg:tw-pl-8">
          <span className="tw-text-cta tw-p-2 tw-border-cta tw-border-2 tw-rounded-full tw-w-8 tw-h-8 tw-inline-flex tw-justify-center tw-items-center tw-mr-2">
            2
          </span>
          <span className="tw-text-text2 tw-font-bold tw-text-xl">Select Type of Bedrooms</span>
          <p className="tw-text-text2 tw-pl-8 tw-mt-2">
            Then Select the type of bedrooms you would like in your newly designed apartment. Master
            bedroom is a default selection in all apartments.
          </p>
          <p className="tw-text-text2 tw-pl-8 tw-mt-2">
            Eg. If you have a 3 BHK and want a home office and a room for your children, then select
            Master Bedroom, Kids Bedroom and Home Office/Study
          </p>
        </div>
      </div>
      <div className="tw-flex tw-flex-col lg:tw-flex-row tw-py-16">
        <div className="tw-w-full lg:tw-w-1/2">
          <span className="tw-text-cta tw-p-2 tw-border-cta tw-border-2 tw-rounded-full tw-w-8 tw-h-8 tw-inline-flex tw-justify-center tw-items-center tw-mr-2">
            3
          </span>
          <span className="tw-text-text2 tw-font-bold tw-text-xl">
            Select Yes for Modular Kitchen
          </span>
          <p className="tw-text-text2 tw-pl-8 tw-mt-2">
            Next choose whether you would like a Modular Kitchen or not.
          </p>
        </div>
        <div className="tw-w-full lg:tw-w-1/2">
          <img src="/steps/step3.png" alt="Select Yes for Modular Kitchen" />
        </div>
      </div>
      <div className="tw-flex tw-flex-col-reverse lg:tw-flex-row tw-py-16">
        <div className="tw-w-full lg:tw-w-1/2">
          <img src="/steps/step4.png" alt="Enter Carpet Area" />
        </div>
        <div className="tw-w-full lg:tw-w-1/2 lg:tw-pl-8">
          <span className="tw-text-cta tw-p-2 tw-border-cta tw-border-2 tw-rounded-full tw-w-8 tw-h-8 tw-inline-flex tw-justify-center tw-items-center tw-mr-2">
            4
          </span>
          <span className="tw-text-text2 tw-font-bold tw-text-xl">Enter Carpet Area</span>
          <p className="tw-text-text2 tw-pl-8 tw-mt-2">
            And finally enter the carpet area of your apartment. (Inner wall area + floor area =
            Carpet area.)
          </p>
          <p className="tw-text-text2 tw-pl-8 tw-mt-2">
            Click on <span className="tw-text-cta">Get Estimate</span>
          </p>
        </div>
      </div>
      <div className="tw-flex tw-flex-col lg:tw-flex-row tw-py-16">
        <div className="tw-w-full lg:tw-w-1/2">
          <span className="tw-text-cta tw-p-2 tw-border-cta tw-border-2 tw-rounded-full tw-w-8 tw-h-8 tw-inline-flex tw-justify-center tw-items-center tw-mr-2">
            5
          </span>
          <span className="tw-text-text2 tw-font-bold tw-text-xl">Choose your option</span>
          <p className="tw-text-text2 tw-pl-8 tw-mt-2">
            You will see 4 options curated for you based on the information you provided. Select the
            option that best matches your budget (Don&apos;t worry you can customise the estimate to
            design your dream home, later on).
          </p>
          <p className="tw-text-text2 tw-pl-8 tw-mt-2">
            If you have not logged in, you will have to do so to view all the information.
          </p>
          <p className="tw-text-text2 tw-pl-8 tw-mt-2">
            Once you have chosen an option (Classic, Comfort, Premium, Luxury), you will see your
            Home Interiors Cost Estimate Summary.
          </p>
        </div>
        <div className="tw-w-full lg:tw-w-1/2">
          <img src="/steps/step5.png" alt="Choose your option" />
        </div>
      </div>
      <h2 className="tw-text-text2 tw-font-bold tw-border-cta tw-border-4 tw-pl-2 tw-border-b-0 tw-border-r-0 tw-border-t-0">
        Understanding your Interior Cost Estimate Summary Report
      </h2>
      <div className="tw-flex tw-flex-col lg:tw-flex-row tw-py-16">
        <div className="tw-w-full lg:tw-w-1/2">
          <span className="tw-text-cta tw-p-2 tw-border-cta tw-border-2 tw-rounded-full tw-w-8 tw-h-8 tw-inline-flex tw-justify-center tw-items-center tw-mr-2">
            1
          </span>
          <span className="tw-text-text2 tw-font-bold tw-text-xl">Cost Break Up</span>
          <p className="tw-text-text2 tw-pl-8 tw-mt-2">
            The cost break up graph gives you an overview of the cost of the various elements that
            make up your cost estimate. These include:
          </p>
          <ol className="tw-text-text2 tw-pl-8 tw-mt-2 tw-list-decimal">
            <li>
              Labour - Total cost of all the labor work/ man hours required to build all civil +
              carpentry work for the project.
            </li>
            <li>
              Materials - Total cost of all materials required for the project. Includes raw
              materials (ply, glass, etc.), finish materials (tiles, laminates, etc.) and hardware.
            </li>
            <li>
              Design Fee - Fee charged to create the design of your home. This includes designs,
              3Ds, detailed drawings & material selection.
            </li>
            <li>
              Miscellaneous Costs - Total cost of all materials required for the project. Includes
              raw materials (ply, glass, etc.), finish materials (tiles, laminates, etc.) and
              hardware.
            </li>
            <li>GST - Goods and Services Tax</li>
          </ol>
        </div>
        <div className="tw-w-full lg:tw-w-1/2">
          <img src="/steps/step6.png" alt="Cost Break Up" />
        </div>
      </div>
      <div className="tw-flex tw-flex-col-reverse lg:tw-flex-row tw-py-16">
        <div className="tw-w-full lg:tw-w-1/2">
          <img src="/steps/step7.png" alt="Time Line" />
        </div>
        <div className="tw-w-full lg:tw-w-1/2 lg:tw-pl-8">
          <span className="tw-text-cta tw-p-2 tw-border-cta tw-border-2 tw-rounded-full tw-w-8 tw-h-8 tw-inline-flex tw-justify-center tw-items-center tw-mr-2">
            2
          </span>
          <span className="tw-text-text2 tw-font-bold tw-text-xl">Time Line</span>
          <p className="tw-text-text2 tw-pl-8 tw-mt-2">
            The time line tells you how long your project is going to take in weeks. Starting from
            Design all the way to execution and handover.
          </p>
          <p className="tw-text-text2 tw-pl-8 tw-mt-2">
            The Time Line graph shows you how long each section of your home is going to take.
          </p>
          <p className="tw-text-text2 tw-pl-8 tw-mt-2">
            The Build section includes Carpentry, Painting, Electrical, False Ceiling etc.
          </p>
        </div>
      </div>
      <div className="tw-flex tw-flex-col lg:tw-flex-row tw-py-16">
        <div className="tw-w-full lg:tw-w-1/2">
          <span className="tw-text-cta tw-p-2 tw-border-cta tw-border-2 tw-rounded-full tw-w-8 tw-h-8 tw-inline-flex tw-justify-center tw-items-center tw-mr-2">
            3
          </span>
          <span className="tw-text-text2 tw-font-bold tw-text-xl">Rooms</span>
          <p className="tw-text-text2 tw-pl-8 tw-mt-2">
            The Rooms section of your summary report gives you a break up of how much each room in
            your home is going to cost.
          </p>
          <p className="tw-text-text2 tw-pl-8 tw-mt-2">
            Clicking on any room, shows you a detailed list of all items and services involved with
            that room.
          </p>
        </div>
        <div className="tw-w-full lg:tw-w-1/2">
          <img src="/steps/step8.png" alt="Rooms" />
        </div>
      </div>
      <div className="tw-flex tw-flex-col-reverse lg:tw-flex-row tw-py-16">
        <div className="tw-w-full lg:tw-w-1/2">
          <img src="/steps/step9.png" alt="Services" />
        </div>
        <div className="tw-w-full lg:tw-w-1/2 lg:tw-pl-8">
          <span className="tw-text-cta tw-p-2 tw-border-cta tw-border-2 tw-rounded-full tw-w-8 tw-h-8 tw-inline-flex tw-justify-center tw-items-center tw-mr-2">
            4
          </span>
          <span className="tw-text-text2 tw-font-bold tw-text-xl">Services</span>
          <p className="tw-text-text2 tw-pl-8 tw-mt-2">
            The Services section of your summary report gives you a break up of all the services
            involved in the design and execution of your home.
          </p>
          <p className="tw-text-text2 tw-pl-8 tw-mt-2">
            Clicking on any service, shows you a detailed list of all items in your home associated
            with that service. Eg. If you click on Carpentry, you will see a detailed list of all
            Carpentry related items in your home.
          </p>
        </div>
      </div>
      <div className="tw-flex tw-flex-col lg:tw-flex-row tw-py-16">
        <div className="tw-w-full lg:tw-w-1/2">
          <span className="tw-text-cta tw-p-2 tw-border-cta tw-border-2 tw-rounded-full tw-w-8 tw-h-8 tw-inline-flex tw-justify-center tw-items-center tw-mr-2">
            5
          </span>
          <span className="tw-text-text2 tw-font-bold tw-text-xl">View Detailed Estimate</span>
          <p className="tw-text-text2 tw-pl-8 tw-mt-2">
            Want to know more ? Click on the{' '}
            <span className="tw-text-cta tw-font-bold">View Detailed Estimate</span> to learn a
            whole lot more about your Estimate.
          </p>
        </div>
        <div className="tw-w-full lg:tw-w-1/2">
          <img src="/steps/step10.png" alt="View Detailed Estimate" />
        </div>
      </div>
    </div>
  );
};

export default Calculator;
