import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { TabbedPanel } from '../Shared/TabbedPanel';
import { MaterialSpecs } from '../ViewAssumption/MaterialSpecs';
import { Assumptions } from '../ViewAssumption/Assumptions';
import { Exclusion } from '../ViewAssumption/Exclusion';
import { Hardware } from '../ViewAssumption/Hardware';
import { Warranty } from '../ViewAssumption/Warranty';

const AssumptionsContent = () => {
  const {
    // eslint-disable-next-line no-unused-vars
    viewAssumption: { show = false, data: { loadTab } = {} },
  } = useSelector(state => state.modal);
  const [selectedTab, setSelectedTab] = useState('assumptions');

  const tabs = [
    {
      title: 'Assumptions',
      value: 'assumptions',
      component: <Assumptions />,
      viewAvailableFor: new Set(['mobile', 'web']),
    },
    {
      title: 'Material Specs',
      value: 'materialSpecs',
      component: <MaterialSpecs />,
      viewAvailableFor: new Set(['web']),
    },
    {
      title: 'Hardware Specs',
      value: 'hardware',
      component: <Hardware />,
      viewAvailableFor: new Set(['web']),
    },
    {
      title: 'Exclusions',
      value: 'exclusion',
      component: <Exclusion />,
      viewAvailableFor: new Set(['web']),
    },
    {
      title: 'Warranty',
      value: 'warranty',
      component: <Warranty />,
      viewAvailableFor: new Set(['web', 'mobile']),
    },
    {
      title: 'Material Specs',
      value: 'materialAndHardwareSpecs',
      component: (
        <>
          <MaterialSpecs />
          <div className="tw-pt-4 tw-bg-white hardware-specs">
            <h6>Hardware</h6>
            <Hardware />
          </div>
        </>
      ),
      viewAvailableFor: new Set(['mobile']),
    },
  ];

  const handleTabClick = tab => setSelectedTab(tab);
  const { component } = tabs.find(tab => tab.value === selectedTab);

  return (
    <div className="tw-container tw-mx-auto">
      <div className="tw-bg-white tw-shadow-xl tw-m-8 tw-p-4 tw-rounded-lg">
        <ul className="tw-flex tw-flex-row">
          <li className="tw-text-text2 tw-mr-2">
            <Link to="/">Home</Link>
          </li>
          <li className="tw-text-text2 tw-mr-2">/</li>
          <li className="tw-text-text2 tw-mr-2 tw-text-opacity-50">Assumptions</li>
        </ul>
        <div className="other-assumptions">
          <h2
            className="tw-text-text2 tw-font-bold tw-mb-8"
            style={{ fontSize: '2rem', margin: '1em 0' }}
          >
            Assumptions
          </h2>
          <div className="assumptions-body" style={{ padding: '0' }}>
            <TabbedPanel
              align="start"
              onTabClick={handleTabClick}
              selectedTab={selectedTab}
              bodyStyle={{
                backgroundColor: '#fff',
              }}
              tabs={tabs.filter(tab => tab.viewAvailableFor.has('web'))}
            >
              {component}
            </TabbedPanel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssumptionsContent;
