const defaultState = {
  estimations: [],
};

const dashboardReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'FETCH_DASHBOARD':
      return { ...state, ...action.payload };
    case 'UPDATE_ESTIMATE': {
      const { estimations } = state;
      const { title, boqId } = action.payload;
      const other = estimations.map(estimate => {
        if (estimate.boqId === boqId) {
          estimate.boqName = title;
        }
        return estimate;
      });

      return { ...state, estimations: [...other] };
    }
    case 'UPDATE_INVITE': {
      const { estimations } = state;
      const { boq_id: boqId, managedBoqs } = action.payload;
      const other = estimations.map(estimate => {
        if (estimate.boqId === boqId) {
          estimate.managedBoqs = managedBoqs;
        }
        return estimate;
      });

      return { ...state, estimations: [...other] };
    }
    case 'REMOVE_ESTIMATE': {
      const { estimations } = state;
      const { boqId } = action.payload;
      const other = estimations.filter(estimate => {
        return estimate.boqId !== boqId;
      });
      return { ...state, estimations: [...other] };
    }
    default:
      return { ...state };
  }
};

export { dashboardReducer };
