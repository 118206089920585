import React, { useEffect, useState } from 'react';
import { arrayOf, number, shape, string } from 'prop-types';

import { Table } from 'components/Shared';
import { toCamelCase } from 'utils';

const TableSection = ({ title, description, rows, headers, keys }) => {
  const [sectionTitle, setSectionTitle] = useState(null);
  const [sectionDescription, setSectionDescription] = useState(null);
  const [sectionHeaders, setSectionHeaders] = useState([]);
  const [sectionRows, setSectionRows] = useState([]);

  useEffect(() => {
    setSectionHeaders(headers);
    setSectionTitle(title);
    setSectionDescription(description);
    setSectionRows(rows);
  }, []);

  return (
    <div>
      {sectionTitle && <h5>{sectionTitle}</h5>}
      {sectionDescription && <p>{sectionDescription}</p>}
      <Table headers={sectionHeaders}>
        {sectionRows.map(info => {
          const camelCasedInfo = toCamelCase(info);
          const tds = keys.map(key => {
            const camelCasedKey = toCamelCase(key);
            return (
              <>
                <td>{camelCasedInfo[camelCasedKey]}</td>
              </>
            );
          });
          return tds;
        })}
      </Table>
    </div>
  );
};

TableSection.defaultProps = {
  title: null,
  description: null,
};

TableSection.propTypes = {
  title: string,
  description: string,
  rows: arrayOf({
    no: number,
  }).isRequired,
  headers: arrayOf(
    shape({
      title: string,
    }),
  ).isRequired,
  keys: arrayOf(string).isRequired,
};

export { TableSection };
