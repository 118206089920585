import { useEffect, useState } from 'react';
import { useQuery } from './useQuery';

function useFilter(items = [], filterObj = {}, updateFilter = () => {}) {
  const [dataList, setDataList] = useState([]);
  const queryParams = useQuery();
  useEffect(() => {
    setDataList(items);
  }, [items]);
  useEffect(() => {
    if (queryParams.has('filter[]')) {
      const filterQueryParam = JSON.parse(decodeURI(queryParams.get('filter[]')));
      Object.keys(filterQueryParam).forEach(key => {
        if (filterObj[key]) {
          filterObj[key].selected = new Set([
            ...filterObj[key].selected,
            ...filterQueryParam[key].split(','),
          ]);
        }
      });
      updateFilter({ ...filterObj });
    }
  }, []);
  const applyFilter = () => {
    let filteredItems = [...items];
    Object.keys(filterObj).forEach(label => {
      if (filterObj[label].selected.size > 0) {
        filteredItems = filteredItems.filter(item => {
          return [...filterObj[label].selected].some(val => {
            return RegExp(val, 'i').test(item[filterObj[label].key]);
          });
        });
      }
    });
    setDataList(filteredItems);
  };

  useEffect(() => {
    applyFilter();
  }, [filterObj]);

  return { dataList };
}

export { useFilter };
