import React from 'react';
import { Table } from 'components/Shared';

const TABLE_CONTENTS = [
  ['Custom furniture', 'Up-to 1 year, up-to 10 years on hardware'],
  ['Modular furniture', 'Up-to 1 year, up-to 10 years on hardware'],
  ['Modular kitchen', 'Up-to 1 year, up-to 10 years on hardware'],
  [
    'On-site services (Electrical, Flooring, Painting/Polishing, False Ceiling, Plumbing, Civil work)',
    'Up-to 1 year, Material warranty provided by the manufacturer',
  ],
  ['Décor / Third-party products', 'Manufacturer’s warranty'],
  ['After-sales service', 'Up-to 1 year'],
];

const GUIDELINES = [
  'The products are utilized for normal domestic use.',
  'There are no alterations or changes made to the products/site after the handover of the said premises.',
  'The products are used in the original premises and have not been transported to another location.',
  'All the payments have been made in a timely manner as per the contract.',
  'The warranty doesn’t cover damages that are natural wear and tear of finishes/surfaces or cuts and scratches arising from usage. ',
  'Hipcouch is also not liable for damages arising out of any natural calamities, water leakages, power fluctuations, usage of abrasives, vandalism, structural defects, abuse/negligence or any other external factors.',
  'Any additional charges including work done over and above fixing the identified defect will be chargeable as per the scope of work.',
  'For any third-party products, the warranty applicable is provided by the manufacturer of the said product(s).',
  'The warranty period starts from the date of handover of the premises.',
  'After-sales service will be provided on assessment of scope of work and charged accordingly.',
];

function Warranty() {
  return (
    <div className="warranty">
      <h5>Hipcouch Warranty & Service</h5>
      <p className="tw-text-black">
        Hipcouch provides full-home warranty for all the products and services delivered. The
        following table lists the warranty offerings:
      </p>
      <div>
        <Table headers={[]}>
          {TABLE_CONTENTS.map(item => (
            <>
              <td>{item[0]}</td>
              <td>{item[1]}</td>
            </>
          ))}
        </Table>
      </div>
      <div className="warranty-guidelines tw-mt-4">
        <p>Hipcouch warranty is governed by the following guidelines:</p>
        <ul className="list-group ms-0 ps-0">
          {GUIDELINES.map(line => (
            <li className="list-group-item">{line}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export { Warranty };
