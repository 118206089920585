import React from 'react';
import { arrayOf, string } from 'prop-types';

import { Amount } from 'components/Amount';

import './style.scss';

function AssumptionsForMobile({
  civilAssumptions,
  carpentryAssumptions,
  looseFurnitureAssumptions,
  kitchen,
  painting,
}) {
  const data = [
    { heading: 'Civil', items: civilAssumptions },
    { heading: 'Carpentry', items: carpentryAssumptions },
    { heading: 'Loose Furniture', items: looseFurnitureAssumptions },
    { heading: 'Kitchen', items: kitchen },
    { heading: 'Painting', items: painting },
  ];
  return (
    <div className="mobile-assumptions-div">
      {data.map(({ heading, items }) => {
        return (
          <div
            key={`assumptions-${heading}`}
            className="assumptions-div tw-bg-white tw-mt-4 tw-pt-4"
          >
            <h6 className="tw-pl-3">{heading}</h6>
            {items.map(item => {
              const [name, unit, cost, brands = ''] = item;
              return (
                <div key={name} className="tw-flex tw-border-t tw-p-4">
                  <div className="tw-w-2/3 tw-mr-2">
                    <h6>{name}</h6>
                    <p className="text-2-color tw-m-0">{brands}</p>
                  </div>
                  {unit !== '-' && (
                    <div className="tw-w-1/3 tw-flex tw-flex-row-reverse">
                      <div className="cost">
                        <Amount cost={cost} />
                        <span>/{unit}</span>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}

AssumptionsForMobile.propTypes = {
  civilAssumptions: arrayOf(string).isRequired,
  carpentryAssumptions: arrayOf(string).isRequired,
  looseFurnitureAssumptions: arrayOf(string).isRequired,
  kitchen: arrayOf(string).isRequired,
  painting: arrayOf(string).isRequired,
};

AssumptionsForMobile.defaultProps = {};

export default AssumptionsForMobile;
