import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { FinishCategoryDetail } from './FinishCategoryDetail';
import { VendorDetail } from './VendorDetail';
import { CatalogSearch } from './Search';
import { Listing } from './Listing';
import { Manage } from './Manage';
import { BrandDetail } from './BrandDetail';

import { VendorAction } from '../../actions';

const Vendor = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(VendorAction.fetchDetails());
  }, []);

  const { path } = useRouteMatch();

  return (
    <div className="container-fluid">
      <div className="vendor-container">
        <Switch>
          <Route exact path={path} component={Listing} />
          <Route exact path={`${path}/search`} component={CatalogSearch} />
          <Route exact path={`${path}/category/:slug/details`} component={FinishCategoryDetail} />
          <Route exact path={`${path}/vendor/:slug/details`} component={VendorDetail} />
          <Route exact path={`${path}/brand/:slug/details`} component={BrandDetail} />
          <Route exact path={`${path}/manage`} component={Manage} />
        </Switch>
      </div>
    </div>
  );
};

export { Vendor };
