import React, { useState } from 'react';
import { IoIosArrowBack } from 'react-icons/all';
import { toNumber } from 'lodash';
import { number, func, shape, string } from 'prop-types';

import { Btn, PrimaryBtn, TextInput } from '../Shared';
import { Amount } from '../Amount';

import './edit-rate.scss';

function EditRate({ service, formObj, currentRate, onCancel, onSubmit }) {
  const [step, setStep] = useState(1);
  const [form, setForm] = useState({ rate: currentRate });
  const [costObj, setCostObj] = useState({});

  const renderStepOne = () => {
    return (
      <div className="tw-relative" style={{ width: '20.3rem', height: '11rem' }}>
        <p className="tw-text-black tw-mb-2">
          {service === 'material' && 'Revised rate will impact all items that use this material.'}
          {service === 'labour' && 'Revised rate will impact all items using this labour rate.'}
        </p>
        <TextInput
          classes="tw-text-black tw-w-full tw-mt-3"
          onChange={val => {
            let rate = toNumber(val);
            if (Number.isNaN(rate)) {
              rate = 0;
            }
            setForm({ rate: toNumber(rate) });
          }}
          value={form.rate}
        />
        <div className="tw-flex tw-absolute tw-bottom-0 tw-right-0">
          <div className="tw-flex tw-w-1/2 tw-gap-2">
            <PrimaryBtn
              onClick={async () => {
                try {
                  const { data = {}, message = null } = await onSubmit(
                    formObj,
                    form.rate,
                    'estimate',
                  );
                  setCostObj({ ...data });
                  setStep(2);
                  if (message) {
                    throw new Error(message);
                  }
                } catch (e) {
                  console.error(e);
                }
              }}
              text="Next"
              className="tw-order-2"
            />
            <Btn
              onClick={onCancel}
              text="Cancel"
              className="btn-sm btn-primary rounded text-white w-auto p-2 tw-order-1"
            />
          </div>
        </div>
      </div>
    );
  };

  const renderStepTwo = () => {
    return (
      <div className="" style={{ width: '20.3rem', height: '11rem' }}>
        <p className="tw-text-black">Impact of new rate</p>
        <div>
          <ul className="tw-flex tw-flex-col tw-gap-2">
            <li className="tw-flex">
              <span className="list-heading tw-w-2/3">Revised {service} rate:</span>
              <Amount cost={costObj.rate || form.rate} className="tw-font-bold tw-w-1/3" />
            </li>
            <li className="tw-flex">
              <span className="list-heading tw-w-2/3">
                {service === 'material' && 'Revised cost of material:'}
                {service === 'labour' && 'Revised labour cost:'}
              </span>
              <Amount cost={costObj.cost || 0} className="tw-font-bold tw-w-1/3" />
            </li>
            <li className="tw-flex">
              <span className="list-heading tw-w-2/3">Revised total project cost:</span>
              <Amount cost={costObj.boqCost || 0} className="tw-font-bold tw-w-1/3" />
            </li>
          </ul>
        </div>
        <div className="tw-flex tw-items-center">
          <div className="tw-w-1/2 tw-cursor-pointer" onClick={() => setStep(1)}>
            <IoIosArrowBack className="tw-text-cta tw-inline" />
            <span className="tw-text-cta">Back</span>
          </div>
          <div className="tw-w-1/2 tw-flex tw-flex-row-reverse tw-gap-2">
            <Btn
              onClick={() => {
                setStep(1);
                onCancel();
              }}
              text="Cancel"
              className="text-white rounded tw-bg-white tw-text-sm tw-order-2 p-2"
            />
            <PrimaryBtn
              onClick={() => {
                onSubmit(formObj, form.rate, 'submit');
                onCancel();
              }}
              text="Apply"
              className="tw-text-sm"
            />
          </div>
        </div>
      </div>
    );
  };
  const stepRenderMapper = {
    1: renderStepOne,
    2: renderStepTwo,
  };
  return (
    <div className="edit-rate-form">
      <div className="heading tw-text-black tw-font-bold">Edit rate ({step}/2)</div>
      {stepRenderMapper[step]()}
    </div>
  );
}

EditRate.propTypes = {
  currentRate: number.isRequired,
  onCancel: func,
  onSubmit: func,
  formObj: shape({}),
  service: string,
};

EditRate.defaultProps = {
  onCancel: () => {},
  onSubmit: () => {},
  formObj: {},
  service: 'material',
};

export { EditRate };
