import React from 'react';
import classNames from 'classnames';
import { shape, string, arrayOf, node, func, bool } from 'prop-types';

/**
 * Reusable Table component
 * @param children
 * @param headers
 * @param onRowClick
 * @param classes
 * @returns {*}
 * @constructor
 */
const Table = ({ children, headers, onRowClick, classes, wrapperTR = true }) => {
  const tableClass = classNames(
    'tw-border-collapse',
    'tw-rounded',
    'table',
    'tw-text-left',
    'tw-w-full',
    classes.table || '',
  );

  const theadClass = classNames(classes.thead || '');
  return (
    <table className={tableClass}>
      <thead className={theadClass}>
        <tr>
          {headers.map((header, index) => {
            const { title } = header;
            const key = `${index}`;
            return (
              <th key={key} scope="col">
                {title}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody className="table-body-background border border-top-0">
        {wrapperTR &&
          children.map(child => {
            const { key } = child;
            return (
              <tr
                key={key}
                style={{
                  cursor: 'pointer',
                }}
                onClick={onRowClick}
              >
                {child}
              </tr>
            );
          })}
        {!wrapperTR &&
          children.map(child => {
            return child;
          })}
      </tbody>
    </table>
  );
};

Table.defaultProps = {
  onRowClick: () => {},
  wrapperTR: true,
  classes: {},
};

Table.propTypes = {
  headers: arrayOf(
    shape({
      title: string,
    }),
  ).isRequired,
  children: node.isRequired,
  onRowClick: func,
  wrapperTR: bool,
  classes: shape({}),
};

export { Table };
