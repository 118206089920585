import React from 'react';

function Stats() {
  return (
    <>
      <div className="col-6 col-md-3">
        <h3>200+</h3>
        <p>Projects designed & delivered</p>
      </div>
      <div className="col-6 col-md-3">
        <h3>10000+</h3>
        <p>Cost estimates provided</p>
      </div>
      <div className="col-6 col-md-3">
        <h3>2000 Cr+</h3>
        <p>Value of estimated projects</p>
      </div>
      <div className="col-6 col-md-3">
        <h3>50 Lakhs+</h3>
        <p>In sqft area of estimated projects</p>
      </div>
    </>
  );
}

Stats.propTypes = {};
Stats.defaultProps = {};

export { Stats };
