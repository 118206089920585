import React from 'react';
import { shape, string, func } from 'prop-types';

const CatalogItem = ({ details, onClick }) => {
  const {
    name,
    catalog_code: catalogCode,
    image_url: imageUrl,
    pattern: { name: patternName },
  } = details;

  return (
    <div
      key={catalogCode}
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundSize: 'cover',
      }}
      className="tw-bg-white tw-flex tw-items-end tw-h-64 tw-border tw-border-gray-200 tw-cursor-pointer"
      onClick={() => onClick(details)}
    >
      <div className="info tw-p-2 tw-w-full tw-m-0">
        <span className="tw-text-sm tw-text-gray-600">{patternName}</span>
        <h3 className="tw-mt-2 tw-font-semibold tw-text-gray-700 tw-text-base tw-uppercase">
          {name}
        </h3>
      </div>
    </div>
  );
};

CatalogItem.propTypes = {
  details: shape({
    name: string.isRequired,
    catalog_code: string.isRequired,
    image_url: string.isRequired,
    pattern: shape({
      name: string.isRequired,
    }),
  }).isRequired,
  onClick: func.isRequired,
};

export { CatalogItem };
