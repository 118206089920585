import React from 'react';
import { useSelector } from 'react-redux';

import { FinishCategoryList } from './FinishCategoryList';
import { VendorList } from './VendorList';
import { BrandList } from './BrandList';

const Listing = () => {
  const { finishes = [], vendors = [], brands = [] } = useSelector(state => state.vendor);

  return (
    <>
      <section className="section-container">
        <FinishCategoryList finishes={finishes} />
        <VendorList vendors={vendors} />
        <BrandList brands={brands} />
      </section>
    </>
  );
};

export { Listing };
