import React, { useState, useRef, useEffect } from 'react';
import { string, func } from 'prop-types';
import Uppy from '@uppy/core';
import { DashboardModal } from '@uppy/react';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

import { UppyToBase64 } from './plugins';

function ImageHandler({ open, onClose }) {
  const [images, setImages] = useState([]);
  const uppy = useRef(
    Uppy({
      restrictions: { maxNumberOfFiles: 20 },
      autoProceed: false,
    })
      .use(UppyToBase64, {})
      .on('file-removed', () => {})
      .on('complete', result => {
        const { successful } = result;
        const temp = [];
        for (let i = 0; i < successful.length; i += 1) {
          const image = successful[i];
          temp.push({
            base64Image: image.data,
            extension: image.extension,
            type: image.type,
            name: image.meta.name,
          });
        }
        setImages(temp);
        onClose(temp);
      }),
  );

  useEffect(() => {
    return () => uppy.current.close();
  }, []);

  return (
    <DashboardModal
      uppy={uppy.current}
      closeModalOnClickOutside
      open={open}
      onRequestClose={() => onClose(images)}
    />
  );
}

ImageHandler.propTypes = {
  open: string.isRequired,
  onClose: func.isRequired,
};
ImageHandler.defaultProps = {};

export { ImageHandler };
