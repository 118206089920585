import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-92983057-1');

export default function withAnalyticsTracker(WrappedComponent, options = {}) {
  const trackPage = page => {
    ReactGA.set({
      page,
      ...options,
    });
    ReactGA.pageview(page);
  };

  return props => {
    // eslint-disable-next-line react/prop-types
    const { location = {} } = props;
    useEffect(() => {
      if (process.env.NODE_ENV === 'production') {
        trackPage(location.pathname);
      }
    }, [location.pathname]);

    return <WrappedComponent {...props} />;
  };
}
