import React, { useState } from 'react';
import { func, bool, shape, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';

import './loginStyle.scss';

const LoginForm = ({ action, onSubmitHandler, onProcessing, error, buttonCaption }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [formError, setFormError] = useState({});

  const changeListener = e => {
    const { id, value } = e.target;
    if (id === 'email') {
      setEmail(value);
    } else if (id === 'password') {
      setPassword(value);
    }
  };

  const onSubmit = e => {
    e.preventDefault();
    if (!password || !email) {
      return;
    }

    if (action === 'signup') {
      if (password.length < 8) {
        setFormError({
          msg: 'Passwords should be minimum of 8 characters in length',
        });
        return;
      }
    }

    onSubmitHandler({
      email,
      password,
      action,
    });
  };

  const buttonText = onProcessing ? 'Logging...' : buttonCaption;
  const errorMessage = _.get(error, 'msg') || _.get(formError, 'msg');

  return (
    <div className="card-bodyd-flex  login-form">
      <img
        className="login-logo mx-auto d-block mb-2"
        alt="Hipcouch Logo"
        src="https://images.squarespace-cdn.com/content/v1/6667224025cf36142e87a84e/625cb9cb-1ea6-46ec-a908-13da49c50f8d/Hipcouch+Logos.png%3Fformat=1500w"
      />
      <form method="POST" onSubmit={onSubmit}>
        <div className="form-group error-msg">
          {errorMessage && (
            <div className="alert alert-danger">
              <FontAwesomeIcon icon={faExclamationCircle} /> {errorMessage}
            </div>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="email" className="col-form-label text-1-color ">
            Email
          </label>
          <input
            id="email"
            type="email"
            className="form-control rounded"
            name="email"
            value={email}
            required
            placeholder="Eg: johndoe@gmail.com"
            onChange={changeListener}
          />
        </div>

        <div className="form-group password-container">
          <label htmlFor="password" className="col-form-label text-1-color">
            {action === 'signup' ? 'Create your password' : 'Password'}
          </label>
          <input
            id="password"
            type="password"
            className="form-control rounded mb-2"
            placeholder="******"
            name="password"
            required
            value={password}
            onChange={changeListener}
          />
          {action === 'login' && (
            <a href="/forgot-password" className="cta tw-cursor-pointer">
              Forgotten password?
            </a>
          )}
        </div>
        <div className="form-group d-grid">
          <button
            type="submit"
            disabled={onProcessing}
            className="btn btn-primary mt-2 border border-0 btn-block btn-md rounded"
          >
            {buttonText}
          </button>
        </div>
      </form>
    </div>
  );
};

LoginForm.defaultProps = {
  onProcessing: false,
  action: 'login',
  error: {},
  buttonCaption: 'Login',
};

LoginForm.propTypes = {
  action: string,
  onSubmitHandler: func.isRequired,
  buttonCaption: string,
  onProcessing: bool,
  error: shape({
    msg: string,
  }),
};

export { LoginForm };
