import React from 'react';
import { arrayOf, string } from 'prop-types';

import { Table } from 'components/Shared';

function AssumptionsForWeb({
  civilAssumptions,
  carpentryAssumptions,
  looseFurnitureAssumptions,
  kitchen,
  painting,
}) {
  const tableHeaders = ['Items', 'Unit', 'RATE', 'Brand'];
  return (
    <div>
      <div>
        <h5>Civil Work Items</h5>
        <Table headers={tableHeaders.map(item => ({ title: item }))}>
          {civilAssumptions.map(row => {
            return (
              <>
                <td>{row[0]}</td>
                <td>{row[1]}</td>
                <td>{row[2]}</td>
                <td>{row[3]}</td>
              </>
            );
          })}
        </Table>
      </div>
      <div>
        <h5>Carpentry Work Items</h5>
        <Table headers={tableHeaders.map(item => ({ title: item }))}>
          {carpentryAssumptions.map(row => {
            return (
              <>
                <td>{row[0]}</td>
                <td>{row[1]}</td>
                <td>{row[2]}</td>
                <td>{row[3]}</td>
              </>
            );
          })}
        </Table>
      </div>
      <div>
        <h5>Loose furniture items</h5>
        <Table headers={tableHeaders.map(item => ({ title: item }))}>
          {looseFurnitureAssumptions.map(row => {
            return (
              <>
                <td>{row[0]}</td>
                <td>{row[1]}</td>
                <td>{row[2]}</td>
                <td>{row[3]}</td>
              </>
            );
          })}
        </Table>
      </div>

      <div>
        <h5>Kitchen items</h5>
        <Table headers={tableHeaders.map(item => ({ title: item }))}>
          {kitchen.map(row => {
            return (
              <>
                <td>{row[0]}</td>
                <td>{row[1]}</td>
                <td>{row[2]}</td>
                <td>{row[3]}</td>
              </>
            );
          })}
        </Table>
      </div>

      <div>
        <h5>Painting</h5>
        <Table headers={tableHeaders.map(item => ({ title: item }))}>
          {painting.map(row => {
            return (
              <>
                <td>{row[0]}</td>
                <td>{row[1]}</td>
                <td>{row[2]}</td>
                <td>{row[3]}</td>
              </>
            );
          })}
        </Table>
      </div>
    </div>
  );
}

AssumptionsForWeb.propTypes = {
  civilAssumptions: arrayOf(string).isRequired,
  carpentryAssumptions: arrayOf(string).isRequired,
  looseFurnitureAssumptions: arrayOf(string).isRequired,
  kitchen: arrayOf(string).isRequired,
  painting: arrayOf(string).isRequired,
};

AssumptionsForWeb.defaultProps = {};

export { AssumptionsForWeb };
