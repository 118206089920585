import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { useDispatch } from 'react-redux';

import { Amount } from '../../Amount';

const VendorInfo = ({ elementIds = [], details }) => {
  const dispatch = useDispatch();

  const catalogCode = details?.catalog_code;

  if (!catalogCode) {
    return null;
  }
  if (!elementIds.includes(catalogCode)) {
    return null;
  }

  const {
    _id: catalogId,
    brand: { name: brandName },
    vendor: { _id: vendorId, name: vendorName, website_url: websiteURL, address: vendorAddress },
    catalog_url: catalogUrl,
    max_price: maxPrice,
    base_price: basePrice,
  } = details || {};

  const showViewCatalogue = e => {
    e.preventDefault();
  };

  const downloadCatalog = e => {
    e.preventDefault();
    if (!catalogUrl) {
      return;
    }
    window.open(catalogUrl, '_blank');
  };

  const showRequestSample = e => {
    e.preventDefault();
    dispatch({
      type: 'SHOW_REQUEST_SAMPLE',
      payload: {
        vendor_catalog_id: catalogId,
        vendor_id: vendorId,
      },
    });
  };

  const showContactVendor = e => {
    e.preventDefault();
    dispatch({
      type: 'SHOW_CONTACT_VENDOR',
      payload: {
        vendorName,
        vendor_catalog_id: catalogId,
        vendor_id: vendorId,
      },
    });
  };

  const hasValidVendorWebsite = Boolean(websiteURL);
  const hasPriceRange = Boolean(basePrice) && Boolean(maxPrice);

  return (
    <div className="tw-mt-3 tw-flex card-info tw-border tw-border-gray-200">
      <div className="tw-w-3/4 tw-p-4 ">
        <p>
          <span className="tw-inline-block tw-w-40 tw-text-gray-600">Brand Name</span>
          <span className="tw-text-gray-700 tw-font-semibold">{brandName}</span>
        </p>
        <p>
          <span className="tw-inline-block tw-w-40 tw-text-gray-600">Catalogue Code</span>
          <span className="tw-text-gray-700 tw-font-semibold">{catalogCode}</span>
        </p>
        {hasPriceRange && (
          <p>
            <span className="tw-inline-block tw-w-40 tw-text-gray-600">Base price</span>
            <span className="tw-text-gray-700 tw-font-semibold">
              <Amount cost={basePrice} displayAsWholeValue /> -{' '}
              <Amount cost={maxPrice} displayAsWholeValue />
            </span>
          </p>
        )}
        <p>
          <span className="tw-inline-block tw-w-40 tw-text-gray-600">Vendor Name</span>
          <span className="tw-text-gray-700 tw-font-semibold">{vendorName}</span>
        </p>
        {hasValidVendorWebsite && (
          <p>
            <span className="tw-inline-block tw-w-40 tw-text-gray-600">Vendor Website</span>
            <span className="tw-text-gray-700 tw-font-semibold">
              <a href={websiteURL} className="tw-text-cta">
                {websiteURL}
              </a>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="tw-h-4 tw-w-4 tw-inline-block tw-ml-1 tw-text-cta"
                fill="none"
                style={{ transform: 'rotateZ(-38deg)' }}
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M14 5l7 7m0 0l-7 7m7-7H3" />
              </svg>
            </span>
          </p>
        )}
        <p className="tw-mb-0">
          <span className="tw-inline-block tw-w-40 tw-text-gray-600">Vendor Address</span>
          <span className="tw-text-gray-700 tw-font-semibold">{vendorAddress}</span>
        </p>
      </div>
      <div className="tw-w-1/4 tw-flex tw-flex-col tw-border-l-2 tw-border-dashed tw-border-gray-300 tw-justify-around">
        <a
          href=""
          onClick={showViewCatalogue}
          className="tw-text-left tw-text-gray-600 tw-block tw-p-3 tw-border-b tw-border-gray-30 tw-pl-20"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="tw-h-5 tw-w-5 tw-inline-block tw-mr-2"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
            <path
              fillRule="evenodd"
              d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
              clipRule="evenodd"
            />
          </svg>
          View Catalogue
        </a>
        <a
          href={catalogUrl}
          onClick={downloadCatalog}
          className="tw-text-left tw-text-gray-600 tw-block tw-p-3 tw-border-b tw-border-gray-30 tw-pl-20"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="tw-h-5 tw-w-5 tw-inline-block tw-mr-2"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
          Download Catalogue
        </a>
        <a
          href=""
          onClick={showRequestSample}
          className="tw-text-left tw-text-gray-600 tw-block tw-p-3 tw-border-b tw-border-gray-30 tw-pl-20"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="tw-h-5 tw-w-5 tw-inline-block tw-mr-2"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
              clipRule="evenodd"
            />
          </svg>
          Request Sample
        </a>
        <a
          href=""
          onClick={showContactVendor}
          className="tw-text-left tw-text-gray-600 tw-block tw-p-3 tw-pl-20"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="tw-h-5 tw-w-5 tw-inline-block tw-mr-2"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path d="M17.924 2.617a.997.997 0 00-.215-.322l-.004-.004A.997.997 0 0017 2h-4a1 1 0 100 2h1.586l-3.293 3.293a1 1 0 001.414 1.414L16 5.414V7a1 1 0 102 0V3a.997.997 0 00-.076-.383z" />
            <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z" />
          </svg>
          Contact Vendor
        </a>
      </div>
    </div>
  );
};

VendorInfo.propTypes = {
  details: shape({
    catalog_code: string.isRequired,
    brand: shape({
      name: string.isRequired,
    }),
    vendor: shape({
      name: string,
      website_url: string,
      address: string,
    }),
  }).isRequired,
  elementIds: arrayOf(string).isRequired,
};

export { VendorInfo };
