import React from 'react';
import { useSelector } from 'react-redux';

import { SimpleModal } from '../Shared';
import { ProfileSetup } from '../SignUp';

function ViewProfileSetup() {
  const {
    viewProfileSetup: { show },
  } = useSelector(state => state.modal);
  return (
    <SimpleModal open={show} maxWidth="md" fullWidth displayCloseButton={false}>
      <ProfileSetup />
    </SimpleModal>
  );
}

ViewProfileSetup.propTypes = {};

ViewProfileSetup.defaultProps = {};

export { ViewProfileSetup };
