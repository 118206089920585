import React from 'react';

import { Table } from 'components/Shared';

const Exclusion = () => {
  const civilExclusions = ['Inlay Works'];
  const plumbingExclusions = [
    'Water Purifier',
    'Water pressure pump',
    'Grease trap',
    'All CP Fittings',
    'WC and flush tank',
    'Wash Basin',
    'Urinals',
    'Shower fittings',
    'Nahani Trap jaali',
    'Waste couplin',
    'Faucet',
    'Basin Mixer',
    'Jet spray',
  ];
  const electricalExclusions = [
    'Light fittings',
    'Hanging Light',
    'Strip light',
    'Fan',
    'Exhaust Fan',
    'And any other electrical fittings',
    'Power points for AC',
  ];

  const carpentryExclusions = ['Mattresses', 'Furnishing & cushions'];

  const falseCeilingExclusions = ['Cornice & Molding Works'];

  const looseFurnitureExclusions = ['Fabric For Curtains', 'Artifacts'];
  const painting = ['Texture Paint', 'Wallpaper'];

  const whiteGoodsExclusions = [
    'T.V.',
    'Washing Machine, Dryer',
    'Refrigerator',
    'Dishwasher',
    'Microwave, Oven',
    'Music System, Speakers',
    'AV System',
    'Hobb, Chimney',
    'Grease Trap',
    'Any other electronic items',
  ];

  const kitchen = ['white goods'];
  const tableHeadings = ['Items'];

  return (
    <div>
      <div>
        <h5>Civil Work Items</h5>
        <Table headers={tableHeadings.map(heading => ({ title: heading }))}>
          {civilExclusions.map(text => {
            return (
              <>
                <td>{text}</td>
              </>
            );
          })}
        </Table>
      </div>
      <div>
        <h5>Plumbing Work Items</h5>
        <Table headers={tableHeadings.map(heading => ({ title: heading }))}>
          {plumbingExclusions.map(text => {
            return (
              <>
                <td>{text}</td>
              </>
            );
          })}
        </Table>
      </div>
      <div>
        <h5>Electrical Work Items</h5>
        <Table headers={tableHeadings.map(heading => ({ title: heading }))}>
          {electricalExclusions.map(text => {
            return (
              <>
                <td>{text}</td>
              </>
            );
          })}
        </Table>
      </div>
      <div>
        <h5>Carpentry Work Items</h5>
        <Table headers={tableHeadings.map(heading => ({ title: heading }))}>
          {carpentryExclusions.map(text => {
            return (
              <>
                <td>{text}</td>
              </>
            );
          })}
        </Table>
      </div>
      <div>
        <h5>False Ceiling Work Items</h5>
        <Table headers={tableHeadings.map(heading => ({ title: heading }))}>
          {falseCeilingExclusions.map(text => {
            return (
              <>
                <td>{text}</td>
              </>
            );
          })}
        </Table>
      </div>
      <div>
        <h5>Loose Furniture Items</h5>
        <Table headers={tableHeadings.map(heading => ({ title: heading }))}>
          {looseFurnitureExclusions.map(text => {
            return (
              <>
                <td>{text}</td>
              </>
            );
          })}
        </Table>
      </div>
      <div>
        <h5>White Goods</h5>
        <Table headers={tableHeadings.map(heading => ({ title: heading }))}>
          {whiteGoodsExclusions.map(text => {
            return (
              <>
                <td>{text}</td>
              </>
            );
          })}
        </Table>
      </div>

      <div>
        <h5>Painting</h5>
        <Table headers={tableHeadings.map(heading => ({ title: heading }))}>
          {painting.map(text => {
            return (
              <>
                <td>{text}</td>
              </>
            );
          })}
        </Table>
      </div>
      <div>
        <h5>Kitchen</h5>
        <Table headers={tableHeadings.map(heading => ({ title: heading }))}>
          {kitchen.map(text => {
            return (
              <>
                <td>{text}</td>
              </>
            );
          })}
        </Table>
      </div>
    </div>
  );
};

export { Exclusion };
