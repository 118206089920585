import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { doGet, API_BASE_URL } from 'utils';
import { Amount } from 'components/Amount';
import { Loader } from 'components/Loader';

const RecommendationTable = () => {
  const [recommendations, setRecommendations] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    doGet({
      url: `${API_BASE_URL}/recommendations`,
    }).then(({ recommendations: recd }) => {
      const rows = [];

      for (const layout of ['1bhk', '2bhk', '3bhk', '4bhk']) {
        const otherRows = [];
        for (const budgetType of ['low', 'medium', 'high', 'premium']) {
          for (const option of [true, false]) {
            const recommendation = recd.find(item => {
              const isSameLayout = item.layout_type === layout;
              const isSameBudget = item.budget_type === budgetType;
              const hasKitchen = item.has_modular_kitchen === option;
              return isSameLayout && isSameBudget && hasKitchen;
            });

            if (recommendation) {
              otherRows.push({
                ...recommendation,
                has_modular_kitchen: option,
                layout_type: layout,
                budget_type: budgetType,
                caption: '_',
                is_available: true,
              });
            } else {
              otherRows.push({
                caption: '_',
                is_available: false,
              });
            }
          }
        }
        rows.push({
          row_title: layout.toUpperCase(),
          tds: otherRows,
        });
      }

      setRecommendations(rows);
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return <Loader loading={isLoading} />;
  }

  return (
    <table className="table table-hover table-bordered tabs-container">
      <thead>
        <tr>
          <th rowSpan={2}>Layout</th>
          <th colSpan={2}>Low</th>
          <th colSpan={2}>Medium</th>
          <th colSpan={2}>High</th>
          <th colSpan={2}>Premium</th>
        </tr>
        <tr>
          <th>with Modular Kitchen</th>
          <th>w/o Modular Kitchen</th>
          <th>with Modular Kitchen</th>
          <th>w/o Modular Kitchen</th>
          <th>with Modular Kitchen</th>
          <th>w/o Modular Kitchen</th>
          <th>with Modular Kitchen</th>
          <th>w/o Modular Kitchen</th>
        </tr>
      </thead>
      <tbody>
        {recommendations.map((recommendation, recoIndex) => {
          return (
            <tr key={toString(recoIndex)}>
              <td>{recommendation.row_title}</td>
              {recommendation.tds.map((td, index) => {
                return (
                  <td key={toString(index)}>
                    {td.is_available && (
                      <>
                        <div>
                          Boq Id #
                          <Link to={{ pathname: `/boqs/${td.boq_id}/details` }}>{td.boq_id}</Link>
                        </div>
                        <div>
                          <Amount cost={td.cost} displayInLakh />
                        </div>
                      </>
                    )}
                    {!td.is_available && <>{td.caption}</>}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export { RecommendationTable };
