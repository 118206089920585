import { doGet } from '../utils';

/**
 * mapped to /revisions
 */

class RevisionHistoryAction {
  static fetchList(boqId) {
    return async dispatch => {
      try {
        const { data, meta } = await doGet({ url: `revisions?boq_id=${boqId}` });
        return dispatch({
          type: 'FETCH_REVISION_HISTORY',
          payload: { data, meta, isForceUpdate: true },
        });
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
      return [];
    };
  }
}

export { RevisionHistoryAction };
