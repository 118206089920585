import React, { useState, useEffect } from 'react';
import { string, func } from 'prop-types';
import { useSelector } from 'react-redux';
import {
  IoIosArrowDropleft,
  IoIosArrowDropright,
  IoIosExpand,
  IoIosContract,
} from 'react-icons/io';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';

import { FullPageModal } from 'components/Shared';

import { CarousalSlide } from './CarousalSlide';

function Arrow({ direction, clickFunction, className }) {
  const classes = `tw-fill-current tw-text-orange-600 ${className}`;
  return (
    <div onClick={clickFunction}>
      {direction === 'left' ? (
        <IoIosArrowDropright className={classes} />
      ) : (
        <IoIosArrowDropleft className={classes} />
      )}
    </div>
  );
}

Arrow.defaultProps = {
  direction: 'left',
  clickFunction: () => {},
  className: 'tw-w-5 tw-h-5',
};

Arrow.propTypes = {
  direction: string,
  className: string,
  clickFunction: func,
};

function Carousal() {
  const handle = useFullScreenHandle();
  const [index, setIndex] = useState(0);
  const [isFullScreen, updateIsFullScreen] = useState(false);
  const {
    carousal: { show = false, data: { items = [] } = {}, imageClassName = '' } = {},
  } = useSelector(state => state.modal);
  const content = items[index];
  const numSlides = items.length;

  const handleOnClick = {
    fullScreenMode: state => {
      updateIsFullScreen(state);
      if (state) {
        handle.enter();
      } else {
        handle.exit();
      }
    },
    onArrowClick: direction => {
      const increment = direction === 'left' ? -1 : 1;
      const newIndex = (index + increment + numSlides) % numSlides;
      setTimeout(() => {
        setIndex(newIndex);
      }, 100);
    },
  };

  const handleOnChange = {
    fullScreenMode: state => {
      updateIsFullScreen(state);
    },
  };

  useEffect(() => {
    const handleKeyDown = e => {
      if (e.keyCode === 39) {
        handleOnClick.onArrowClick('right');
      }
      if (e.keyCode === 37) {
        handleOnClick.onArrowClick('left');
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  });

  const handleClose = () => {};

  if (!show) {
    return null;
  }

  return (
    <FullPageModal open={show} onClose={handleClose}>
      <FullScreen handle={handle} onChange={handleOnChange.fullScreenMode}>
        <div
          className={
            isFullScreen
              ? 'tw-flex tw-justify-center tw-h-full'
              : 'tw-relative tw-rounded tw-shadow tw-m-2'
          }
        >
          <div className="tw-flex tw-flex-row-reverse tw-absolute tw-z-10 tw-cursor-pointer tw-right-0 tw-h-full tw-items-center">
            <Arrow
              className={isFullScreen ? 'tw-w-8 tw-h-8' : 'tw-w-5 tw-h-5'}
              direction="left"
              clickFunction={() => handleOnClick.onArrowClick('left')}
            />
          </div>
          <div className="tw-flex tw-flex-row-reverse tw-absolute tw-z-10 tw-bottom-0 tw-w-full tw-p-2">
            {isFullScreen ? (
              <IoIosContract
                className="tw-fill-current tw-text-orange-600 tw-w-8 tw-h-8"
                onClick={() => handleOnClick.fullScreenMode(false)}
              />
            ) : (
              <IoIosExpand
                className="tw-fill-current tw-text-orange-600 tw-w-6 tw-h-6"
                onClick={() => handleOnClick.fullScreenMode(true)}
              />
            )}
          </div>
          <div className="tw-flex tw-absolute tw-z-10 tw-cursor-pointer tw-left-0 tw-h-full tw-items-center">
            <Arrow
              className={isFullScreen ? 'tw-w-8 tw-h-8' : 'tw-w-5 tw-h-5'}
              direction="right"
              clickFunction={() => handleOnClick.onArrowClick('right')}
            />
          </div>
          <CarousalSlide
            content={content}
            imageClasses={imageClassName}
            isFullScreen={isFullScreen}
          />
        </div>
      </FullScreen>
    </FullPageModal>
  );
}

export { Carousal };
