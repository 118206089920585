const clearSession = () => {
  localStorage.clear();
};

const getToken = () => {
  return localStorage.getItem('hp_user_token') ?? '';
};

const setToken = token => {
  localStorage.setItem('hp_user_token', token);
};

const setCookieIdentifierIfNotExists = value => {
  if (localStorage.getItem('hp_identifier')) {
    return;
  }
  localStorage.setItem('hp_identifier', value);
};

const getCookieIdentifier = () => {
  return localStorage.getItem('hp_identifier');
};

const parseJwt = () => {
  const base64Payload = getToken().split('.')[1];
  const payload = Buffer.from(base64Payload, 'base64');
  return JSON.parse(payload.toString());
};

const isLoggedInUser = () => {
  if (!localStorage.getItem('hp_user_token')) {
    return false;
  }

  const { role = 'guest' } = parseJwt();
  return role !== 'guest';
};

module.exports = {
  setToken,
  getToken,
  isLoggedInUser,
  clearSession,
  setCookieIdentifierIfNotExists,
  getCookieIdentifier,
  parseJwt,
};
