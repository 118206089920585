import React from 'react';
import { number, string, node, oneOfType } from 'prop-types';
import Box from '@material-ui/core/Box';

const TabPanel = ({ children, value, index, id, className }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={id}
    aria-labelledby={`${id}-${index}`}
    className={className}
  >
    {value === index && <Box p={3}>{children}</Box>}
  </div>
);

TabPanel.propTypes = {
  children: node.isRequired,
  index: number.isRequired,
  value: oneOfType([string, number]).isRequired,
  id: string.isRequired,
  className: string,
};

TabPanel.defaultProps = {
  className: '',
};

export { TabPanel };
