import { useDispatch } from 'react-redux';

function useToast() {
  const dispatch = useDispatch();
  const successToast = message => {
    dispatch({
      type: 'DISPLAY_TOAST',
      payload: { type: 'success', message },
    });
  };

  const errorToast = message => {
    dispatch({
      type: 'DISPLAY_TOAST',
      payload: { type: 'error', message },
    });
  };

  return { successToast, errorToast };
}

export { useToast };
