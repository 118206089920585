import React from 'react';
import { Table } from 'components/Shared';
import { toCamelCase } from 'utils';

import {
  headers as nonStoneTypeHeaders,
  rows as nonStoneTypeRows,
} from './data/flooring-non-stone.json';

import { headers as stoneTypeHeaders, rows as stoneTypeRows } from './data/flooring-stone.json';
import { headers as floorTilesHeader, rows as floorTilesRows } from './data/floor-tiles.json';
import { headers as wallTilesHeader, rows as wallTilesRows } from '../wall-tiles.json';
import { headers as kitchenHeaders, rows as kitchenRows } from './data/kitchen-counter-tops.json';

import { FootNotes } from '../FootNotes';

const CivilAssumption = () => {
  return (
    <div>
      <div>
        <h5>Types of Flooring - Non Stone Type </h5>
        <Table headers={nonStoneTypeHeaders}>
          {nonStoneTypeRows.map(info => {
            const {
              no,
              itemName,
              description,
              availableAs,
              assumedRate,
              brandUsed,
              plankSize,
            } = toCamelCase(info);
            return (
              <>
                <td>{no}</td>
                <td>{itemName}</td>
                <td>{description}</td>
                <td>{availableAs}</td>
                <td>{assumedRate}</td>
                <td>{brandUsed}</td>
                <td>{plankSize}</td>
              </>
            );
          })}
        </Table>
      </div>
      <div>
        <h5>Types of Flooring - Stone Type </h5>
        <Table headers={stoneTypeHeaders}>
          {stoneTypeRows.map(info => {
            const { no, itemName, availableAs, assumedRate, brandUsed, slabSize } = toCamelCase(
              info,
            );
            return (
              <>
                <td>{no}</td>
                <td>{itemName}</td>
                <td>{availableAs}</td>
                <td>{assumedRate}</td>
                <td>{brandUsed}</td>
                <td>{slabSize}</td>
              </>
            );
          })}
        </Table>
      </div>
      <div>
        <h5>Vitrified Floor Tiles </h5>
        <Table headers={floorTilesHeader}>
          {floorTilesRows.map(info => {
            const { no, itemName, availableAs, assumedRate, brandUsed, slabSize } = toCamelCase(
              info,
            );
            return (
              <>
                <td>{no}</td>
                <td>{itemName}</td>
                <td>{availableAs}</td>
                <td>{assumedRate}</td>
                <td>{brandUsed}</td>
                <td>{slabSize}</td>
              </>
            );
          })}
        </Table>
      </div>
      <div>
        <h5>Vitrified Wall Tiles </h5>
        <Table headers={wallTilesHeader}>
          {wallTilesRows.map(info => {
            const { no, itemName, availableAs, assumedRate, brandUsed, slabSize } = toCamelCase(
              info,
            );
            return (
              <>
                <td>{no}</td>
                <td>{itemName}</td>
                <td>{availableAs}</td>
                <td>{assumedRate}</td>
                <td>{brandUsed}</td>
                <td>{slabSize}</td>
              </>
            );
          })}
        </Table>
      </div>
      <div>
        <h5>Kitchen Counter Tops</h5>
        <Table headers={kitchenHeaders}>
          {kitchenRows.map(info => {
            const { no, itemName, availableAs, assumedRate, brandUsed, slabSize } = toCamelCase(
              info,
            );
            return (
              <>
                <td>{no}</td>
                <td>{itemName}</td>
                <td>{availableAs}</td>
                <td>{assumedRate}</td>
                <td>{brandUsed}</td>
                <td>{slabSize}</td>
              </>
            );
          })}
        </Table>
      </div>
      <FootNotes />
    </div>
  );
};

export { CivilAssumption };
