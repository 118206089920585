import React, { useState } from 'react';
import { doPost } from 'utils';
import _ from 'lodash';
import cx from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import './forgot-password-style.scss';

const ForgotPassword = () => {
  const [email, setEmail] = useState(null);
  const [message, setMessage] = useState({ content: null, type: 'info' });

  const handleSubmit = e => {
    e.preventDefault();
    doPost({
      url: '/users/forgot-password',
      body: {
        email,
      },
    })
      .then(response => {
        setMessage({ content: _.get(response, 'message'), type: 'info' });
      })
      .catch(err => {
        setMessage({ content: _.get(err, 'message'), type: 'error' });
      });
  };

  const onChangeListener = e => {
    setEmail(e.target.value);
  };

  const { content, type } = message;

  return (
    <main className="align-center w-100 vh-100">
      <div className="login-bg vh-100 w-100" />
      <div className="forgot-password-container rounded m-4">
        <h1>Forgot your password?</h1>
        <form action="/forgot-password" onSubmit={handleSubmit}>
          <div className="form-group">
            <div className="form-group error-msg">
              {content && (
                <p
                  className={cx('alert', {
                    'alert-danger': type === 'error',
                    'alert-info': type === 'info',
                  })}
                >
                  <FontAwesomeIcon icon={faExclamationCircle} /> {content}
                </p>
              )}
            </div>
            <label forHtml="exampleInputEmail1">
              Enter your email address below and we’ll send you password reset instructions.
            </label>
            <input
              type="email"
              className="form-control rounded"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              value={email}
              required
              onChange={onChangeListener}
              placeholder="Eg: johndoe@gmail.com"
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary rounded btn-block my-2 border border-none"
          >
            Email me reset instructions
          </button>
          <hr />
          <p>
            <strong>If you don’t see your reset email </strong> be sure to check your spam filter
            for an email from support@hipcouch.com
          </p>
        </form>
        <a href="/login" className="cta">
          Back to Login
        </a>
      </div>
    </main>
  );
};

export { ForgotPassword };
