import React from 'react';
import { arrayOf, string, shape } from 'prop-types';

const VendorCatalogItem = ({ items }) => {
  return (
    <ul className="card-container">
      {items.map(item => {
        return (
          <li key={item.category_code}>
            <div
              className="card-item category-card blur-image white-background-color"
              style={{
                backgroundImage: `url(${item.image_url})`,
                backgroundSize: 'cover',
              }}
            >
              <span className="upper-case-text bold-font">{item.name}</span>
            </div>
          </li>
        );
      })}
    </ul>
  );
};

VendorCatalogItem.defaultProps = {
  items: [],
};

VendorCatalogItem.propTypes = {
  items: arrayOf(
    shape({
      name: string,
      category_code: string,
      image_url: string,
    }),
  ),
};

export { VendorCatalogItem };
