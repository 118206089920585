import React, { lazy } from 'react';

import { useScrollTo } from '../../hooks';

import { componentLoader } from '../../utils';

import './landingpage.scss';

/* prettier-ignore */
const Questions = lazy(() => componentLoader(() => import(/* webpackChunkName: "UnAuthHomePageQuestions" */'components/Home/UnAuthHomePage/Questions'), 3));
/* prettier-ignore */
const Stats = lazy(() => componentLoader(() => import(/* webpackChunkName: "SharedStats" */ 'components/Shared/Stats'), 3));
/* prettier-ignore */
const HowItWorks = lazy(() => componentLoader(() => import(/* webpackChunkName: "SharedHowItWorks" */'components/Shared/HowItWorks'), 3));
/* prettier-ignore */
const FAQ = lazy(() => componentLoader(() => import(/* webpackChunkName: "SharedHowItWorks" */'components/Shared/FAQ'), 3));
/* prettier-ignore */
const Footer = lazy(() => componentLoader(() => import(/* webpackChunkName: "SharedFooter" */'components/Shared/Footer'), 3));
/* prettier-ignore */
const Coupon = lazy(() => componentLoader(() => import(/* webpackChunkName: "SharedCoupon" */'components/Shared/Coupon'), 3));
/* prettier-ignore */
const ProductDemoVideo = lazy(() => componentLoader(() => import(/* webpackChunkName: "SummaryProductDemoVideo" */'components/Summary/ProductDemoVideo'), 3));

const LandingPage = () => {
  const [getEstimateRef, executeScrollToGetEstimate] = useScrollTo();

  return (
    <>
      <div className="container-fluid home-banner pt-5 right">
        <div className="row pt-5">
          <div className="container pt-5">
            <div className="row pt-5 align-items-center">
              <div className="col-12 col-lg-12 col-xl-8 pt-5 mx-auto">
                <h1 className="text-white text-center">Reliable estimates delivered instantly</h1>
                <h4 className="my-4 text-white text-center">
                  Get detailed, accurate & customized cost estimates for your home Interiors.
                </h4>
                <div ref={getEstimateRef} id="questionnaire">
                  <Questions />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container py-5 stats-container">
        <div className="row">
          <Stats />
        </div>
      </div>
      <HowItWorks />
      <div className="container">
        <Coupon onButtonClick={executeScrollToGetEstimate} />
      </div>
      <FAQ />
      <ProductDemoVideo />
      <Footer />
    </>
  );
};

export { LandingPage };
