import {
  FETCH_ALL_ROOMS,
  FETCH_ROOM,
  UPDATE_ROOM_FORM,
  UPDATE_REDIRECT,
} from '../constants/actionTypes';

function roomReducer(
  state = {
    rooms: [],
    formData: {
      id: '',
      name: '',
      alias: [],
    },
    redirect: {
      status: false,
      url: null,
    },
  },
  action,
) {
  switch (action.type) {
    case FETCH_ALL_ROOMS:
      return { ...state, rooms: action.payload };
    case FETCH_ROOM:
      return { ...state, formData: action.payload };
    case UPDATE_ROOM_FORM:
      return { ...state, formData: action.payload };
    case UPDATE_REDIRECT:
      return { ...state, redirect: action.payload };
    default:
      return state;
  }
}

export { roomReducer };
