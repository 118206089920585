import React from 'react';

import { Table } from 'components/Shared';

const MaterialSpecs = () => {
  const civil = [
    ['Cement', 'Ultratect, ACC, Ambuja'],
    ['Cement for fixing tiles as dado', 'Ultratect, ACC, Ambuja'],
    ['White cement', 'Birla White'],
    ['Vitrified Tiles', 'As per Basic Rate and Selection'],
    ['Waterproofing chemical', 'Roff, Sunanda, Pidilite'],
    ['Sand (only for plaster)', 'Local Available - River Sand'],
    ['Sand (only for floor bedding, masonary)', 'Local Available - Greek Sand'],
  ];

  const plumbing = [
    ['Sanitory fixtures', 'As per Basic Rate and Selection'],
    ['PVC pipes', 'Prince, Aashirwad, Astral'],
  ];

  const carpentry = [
    ['Commercial Ply', 'Glory,Excel,Ronak'],
    ['Marine / BWR ply', 'Gunjan ,Donyi'],
    ['Block Board', 'Jainam ,Premier'],
    ['Inside laminated sheet (0.92 mm thick)', 'Cherrygold'],
    ['Finish laminated sheet (1 mm thick)', 'Century, Merino, Greenlam, Euro, Mica'],
    ['Veneer', 'Sonear, Hi-club,Century ,Greenlam'],
    ['Glass (Clear)', 'Saint Gobain, Asahi'],
    ['Mirror', 'Saint Gobain, Asahi'],
    ['Aluminum section for window', 'Jindal, Geeta, Indal'],
    ['Adhesive for wood', 'Pidilite, Euro, 3M'],
    ['Shera Board', 'Shera'],
  ];

  // const hardware = [
  //   ['Ball Bearing Telescopic Channel', 'Rado, Baba'],
  //   ['Butt / L / Duck Hinges', 'Hero, Hiren'],
  //   ['Sliding Door Fittings', 'CNR, Enox'],
  //   ['Handle & Knob', 'Fab, Jippo, Toons, Klik, Mozo, Modern'],
  //   ['Bed Hydraulic Fitting', 'Vagad'],
  //   ['Magnetic Ballcage - M2', 'Goodluck'],
  //   ['Screws', 'Omni'],
  //   ['Nails', 'Badshah'],
  //   ['Tower Bolt', 'Hero, Hiren'],
  //   ['Table Chain', 'S.S. - Local make'],
  //   ['Wardrobe lock', 'Vijayan'],
  //   ['Multi lock', 'Godrej, Vijayan'],
  //   ['Door Lock & Mortise Handle', 'Hansa, Sahara, Doorset, Hero'],
  //   ['Dead Lock', 'Vijayan'],
  //   ['Main door lock', 'Europa, Godrej'],
  //   ['Hanger Rod - Round / Oval Shape', 'S.S. - Local make'],
  //   ['Collaspsible Hardware - Indian', 'Enox, CNR, Gravity, Kainya'],
  //   ['Collaspsible Hardware - Imported', 'Quba, Godrej'],
  // ];

  const kitchen = [['Modular shutters & cabinets', 'Custom made As per Base Material']];
  const falseCeiling = [
    ['Gypsum Board', 'Gypro, Mada, Saint-Gobain'],
    ['G. I. frame', 'Local make'],
    ['Plaster of Paris', 'Local make'],
  ];

  const paint = [
    ['Wall and Ceiling paint', 'Asian Paints, Nerolac, ICI, Dulux'],
    ['PU Coat', 'Asian Paints, Nerolac, ICI, Dulux'],
    ['Melamine polish Finish', 'Asian Paints, Nerolac, ICI, Dulux'],
  ];

  const electrical = [
    ['Wires', 'Polycab, Finolex, Havells, Syska'],
    ['DB, MCB', 'LeGrand, Havells, L&T, Anchor'],
    ['Switches', 'GM, Legrand, Anchor'],
    ['Lights', 'SR Light or equivalent'],
  ];

  const datas = [
    { heading: 'Civil', content: civil },
    { heading: 'Plumbing', content: plumbing },
    { heading: 'Carpentry', content: carpentry },
    // { heading: 'HARDWARE', content: hardware },
    { heading: 'Kitchen', content: kitchen },
    { heading: 'False Ceiling', content: falseCeiling },
    { heading: 'Paint', content: paint },
    { heading: 'Electrical', content: electrical },
  ];
  const tableHeadings = ['Material', 'Brand'];

  return (
    <div className="material-specs">
      {datas.map(data => (
        <>
          <h5>{data.heading}</h5>
          <div>
            <Table headers={tableHeadings.map(heading => ({ title: heading }))}>
              {data.content.map(items => {
                return (
                  <>
                    <td>{items[0]}</td>
                    <td>{items[1]}</td>
                  </>
                );
              })}
            </Table>
          </div>
        </>
      ))}
    </div>
  );
};

export { MaterialSpecs };
