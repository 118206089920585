import { useContext } from 'react';
import { hasPermission } from '../utils/PermissionUtils';
import UserContext from '../UserContext';

function useHasPermission() {
  const { role } = useContext(UserContext);
  return action => hasPermission(role, action);
}

export { useHasPermission };
