import React, { useState } from 'react';
import { string, shape, arrayOf } from 'prop-types';
import classNames from 'classnames';

import { VendorInfo } from './VendorInfo';
import { CatalogItem } from './CatalogItem';

const VendorCatalogList = ({ catalog }) => {
  const [vendorDetails, setVendorDetails] = useState({});
  const isEmpty = catalog.length === 0;

  const divisibleByFour = catalog.length % 4;
  const roundOff = 4 - divisibleByFour;
  const noOfItems = catalog.length + roundOff;

  const collection = [];
  for (let i = 0; i < noOfItems; i = i + 4) {
    const rowElements = catalog.slice(i, i + 4);
    const elementIds = rowElements.map(item => item.catalog_code);
    collection.push(rowElements);
    collection.push(<VendorInfo elementIds={elementIds} details={vendorDetails} />);
  }

  const onItemClick = details => {
    setVendorDetails(details);
  };

  return (
    <div
      className={classNames('tw-bg-white filter', {
        'tw-p-4': !isEmpty,
        'msg-center': isEmpty,
      })}
    >
      {isEmpty && <span className="error-message">No Items found matching your search</span>}
      <div className="tw-mb-3">
        {collection.map(collectionItem => {
          if (Array.isArray(collectionItem)) {
            return (
              <div
                className="tw-grid tw-gap-3 tw-grid-cols-4"
                style={{
                  minHeight: '250px',
                }}
              >
                {collectionItem.map(item => {
                  return <CatalogItem details={item} onClick={onItemClick} />;
                })}
              </div>
            );
          }

          return collectionItem;
        })}
      </div>
    </div>
  );
};

VendorCatalogList.defaultProps = {
  catalog: [],
};

VendorCatalogList.propTypes = {
  catalog: arrayOf(
    shape({
      image_url: string,
      catalog_code: string,
      name: string,
      pattern: shape({
        name: string,
      }),
    }),
  ),
};

export { VendorCatalogList };
