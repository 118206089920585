import React from 'react';

import { RecommendationTable } from './RecommendationTable';

const Settings = () => {
  return (
    <div>
      <h3>Recommendations</h3>
      <RecommendationTable />
    </div>
  );
};

export { Settings };
