import React from 'react';
import { string, func, bool, oneOfType, number } from 'prop-types';
import classNames from 'classnames';

function TextInput({ value, placeholder, onChange, classes, disabled, required, type = 'text' }) {
  const className = classNames(
    'tw-border tw-rounded tw-p-2 tw-bg-white tw-outline-none focus:tw-outline-none',
    classes,
    {
      'tw-opacity-50': disabled,
    },
  );
  return (
    <input
      className={className}
      type={type}
      value={value}
      placeholder={placeholder}
      onChange={e => onChange(e.target.value)}
      disabled={disabled}
      required={required}
    />
  );
}

TextInput.propTypes = {
  value: oneOfType([string, number]),
  onChange: func.isRequired,
  placeholder: string,
  classes: string,
  disabled: bool,
  required: bool,
  type: string,
};

TextInput.defaultProps = {
  value: '',
  placeholder: '',
  classes: '',
  disabled: false,
  required: false,
  type: 'text',
};

export { TextInput };
