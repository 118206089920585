import React from 'react';

const ViewMore = () => {
  return (
    <span
      className="upper-case-text text-grey center-content"
      style={{
        display: 'inline-grid',
      }}
    >
      VIEW MORE
    </span>
  );
};

export { ViewMore };
