import React, { useState } from 'react';

import { doPost } from '../../../utils';
import { useToast } from '../../../hooks';

const Manage = () => {
  const { errorToast, successToast } = useToast();
  const [fileInput, setFileInput] = useState(null);
  const [disable, setDisable] = useState(false);
  const [caption, setCaption] = useState('Upload');

  const handleSubmit = e => {
    e.preventDefault();
    if (!fileInput) {
      errorToast('Please select a valid excel file');
      return;
    }
    const [file] = fileInput;
    const bodyFormData = new FormData();
    bodyFormData.set('file', file);
    setCaption('Uploading...');
    setDisable(true);

    doPost({
      url: '/vendor/manage',
      body: bodyFormData,
    })
      .then(() => {
        successToast('Successfully imported');
        window.location = '/vendors';
      })
      .catch(() => {
        errorToast('Something went wrong, Please try again');
      })
      .finally(() => {
        setCaption('Upload');
        setDisable(false);
      });
  };

  return (
    <div className="manage-container">
      <form
        className="upload-form manage-container grey-border bg-white round-border"
        method="post"
        encType="multipart/form-data"
        onSubmit={handleSubmit}
      >
        <input type="file" name="file" id="file" onChange={e => setFileInput(e.target.files)} />
        <button type="submit" disabled={disable} className="btn btn-primary round-border">
          {caption}
        </button>
      </form>
    </div>
  );
};

export { Manage };
