import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { doGet, doPut } from 'utils';

import { Form } from '../Form';

function EditFinish() {
  const { finishId } = useParams();
  const [state, setState] = useState({
    name: '',
    alias: [],
    finishId: null,
    disabled: false,
  });

  async function fetchFromApi() {
    doGet({
      url: `finish/furniture/${finishId}`,
    })
      .then(({ data: { material_name, alias } }) => {
        setState({
          ...state,
          name: material_name,
          alias: alias.join('\n'),
          disabled: false,
        });
      })
      .catch(err => {
        console.error(err);
      });
  }
  useEffect(() => {
    fetchFromApi();
  }, []);

  const onChange = {
    updateForm: e => {
      const { id, value } = e.target;
      setState({ ...state, [id]: value });
    },
  };

  const onSubmit = {
    update: () => {
      const { name, alias } = state;
      const isFormInComplete = !name || !alias;
      if (isFormInComplete) {
        return;
      }
      setState({ ...state, disabled: true });
      doPut({
        url: `finish/furniture/${finishId}`,
        body: {
          name,
          alias: alias.split('\n').map(item => item.trim()),
        },
      })
        .then(() => fetchFromApi())
        .catch(() => {
          // TODO:  alert('Something failed, please try again later')
        });
    },
  };
  const { name, alias, disabled } = state;
  const caption = disabled ? 'Saving...' : 'Save';
  return (
    <>
      <Form
        name={name}
        caption={caption}
        alias={alias}
        onChange={onChange.updateForm}
        onSubmit={onSubmit.update}
      />
    </>
  );
}

export { EditFinish };
