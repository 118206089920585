import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import UserContext from '../../UserContext';
import AppContext from '../../AppContext';
import { CustomMenuItem } from '../Shared/MenuItem';
import { DropDownMenu } from '../Shared/DropDownMenu';

import { ReactComponent as ProfileImage } from '../../assets/user-profile-pic.svg';

import './menu.scss';

const Menu = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { menus, onMenuItemClick } = useContext(AppContext);
  const { isLoggedInUser, onLogout, role } = useContext(UserContext);

  const userMenu = menus.filter(item => item.align === 'right');
  const adminMenu = menus.filter(item => item.align === 'left');
  const restrictMenuByLocationPath = { login: ['summary', 'explore'] };
  const renderCommonMenu = menuItems => {
    return (
      <>
        {menuItems
          .filter(({ href = '' }) => {
            for (const path in restrictMenuByLocationPath) {
              if (href.includes(path)) {
                const paths = restrictMenuByLocationPath[path];
                return !paths.some(pathName => history.location.pathname.includes(pathName));
              }
            }
            return true;
          })
          .map(({ name, href, isActive, subMenus = [] }) => {
            if (subMenus.length === 0) {
              return (
                <li
                  className={classNames('nav-item menu-item text-center', {
                    active: isActive,
                    'active-menu': isActive,
                  })}
                  key={href}
                  onClick={() => {
                    onMenuItemClick(href);
                  }}
                >
                  <span
                    className={classNames({
                      cta: href === '/login',
                      'text-2-color': true,
                    })}
                    onClick={() => history.push(href, { from: history.location.pathname })}
                  >
                    {name}
                  </span>
                </li>
              );
            }
            return (
              <DropDownMenu
                label={name}
                menuOptions={subMenus.map(menu => ({
                  label: menu.name,
                  href: menu.href,
                  isExternalLink: menu.isExternalLink,
                }))}
              />
            );
          })}
        <li
          className={classNames('nav-item menu-item', {
            active: false,
            'active-menu': false,
          })}
          key="book_consultation"
          onClick={() => dispatch({ type: 'VIEW_CONSULT_WITH_US' })}
        >
          <div className="btn btn-outline-primary btn-sm d-block w-100 br-4 rounded bg-white book-consultation">
            Book a consultation
          </div>
        </li>
      </>
    );
  };
  return (
    <>
      {isLoggedInUser && (
        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul className="navbar-nav ms-auto tw-gap-2 tw-items-center">
            {adminMenu.map(({ name, href, isActive, subMenus = [] }) => {
              if (subMenus.length === 0) {
                return (
                  <li
                    className={classNames('nav-item menu-item', {
                      active: isActive,
                      'active-menu': isActive,
                    })}
                    key={href}
                    onClick={() => {
                      onMenuItemClick(href);
                    }}
                  >
                    <Link to={{ pathname: href }}>{name}</Link>
                  </li>
                );
              }
              return <CustomMenuItem key={name} heading={name} menuList={subMenus} />;
            })}
            {Boolean(userMenu.length) && renderCommonMenu(userMenu)}
            <li className="nav-item dropdown">
              <div
                id="navbarDropdown"
                className="nav-link tw-text-black"
                role="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <ProfileImage title="profile pic" className="mx-auto" />
              </div>

              <div className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                {role === 'admin' && (
                  <a
                    className="dropdown-item logout-dropdown"
                    onClick={() => {
                      history.push('/dashboard');
                    }}
                  >
                    Dashboard
                  </a>
                )}
                {['admin', 'designer'].includes(role) && (
                  <a
                    className="dropdown-item logout-dropdown"
                    onClick={() => {
                      history.push('/boqs/upload');
                    }}
                  >
                    Upload Boq
                  </a>
                )}
                <a className="dropdown-item logout-dropdown" onClick={onLogout}>
                  Logout
                </a>
              </div>
            </li>
          </ul>
        </div>
      )}

      {!isLoggedInUser && (
        <div className="collapse navbar-collapse common-menu-div" id="navbarTogglerDemo02">
          <ul className="navbar-nav ms-auto tw-gap-2 tw-items-center">
            {renderCommonMenu(userMenu)}
          </ul>
        </div>
      )}
    </>
  );
};

export { Menu };
