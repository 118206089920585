import {
  FETCH_CIVIL_LABOUR_ANALYSIS,
  FETCH_CARPENTRY_LABOUR_ANALYSIS,
} from '../constants/actionTypes';

function boqCarpentryLabourAnalysisReducer(
  state = {
    cost: 0,
    items: [],
    hasError: false,
    isFetchingFromAPI: false,
  },
  action,
) {
  switch (action.type) {
    case FETCH_CARPENTRY_LABOUR_ANALYSIS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

function boqCivilLabourAnalysisReducer(
  state = {
    cost: 0,
    items: [],
    hasError: false,
    isFetchingFromAPI: false,
  },
  action,
) {
  switch (action.type) {
    case FETCH_CIVIL_LABOUR_ANALYSIS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export { boqCarpentryLabourAnalysisReducer, boqCivilLabourAnalysisReducer };
