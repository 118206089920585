import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';

import { doGet } from 'utils';

function Material() {
  const [state, setState] = useState({
    columns: [],
    data: [],
  });

  useEffect(() => {
    doGet({
      url: 'http://boq.hipcouch.com/api/materials',
    }).then(({ data: materials }) => {
      setState({
        columns: [
          {
            title: 'Name',
            field: 'name',
          },
        ],
        data: materials,
      });
    });
  }, []);

  return <MaterialTable title="Materials" columns={state.columns} data={state.data} />;
}

export { Material };
