import React from 'react';
// import { string, func, shape, oneOfType, any, number } from 'prop-types';
import { useSelector } from 'react-redux';
import { Popover } from 'components/Shared/Popover';

import { EditRate } from '../EditRate';
import './view-edit-rate-popover.scss';

function ViewEditRatePopover() {
  const {
    viewEditRatePopover: {
      data: { id, handleClose, anchorEl, formObj, currentRate, onRateUpdate, onCancel, service },
    },
  } = useSelector(state => state.modal);

  return (
    <Popover
      id={`${id}-popover}`}
      handleClose={handleClose}
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
    >
      <EditRate
        formObj={formObj}
        currentRate={currentRate}
        onSubmit={onRateUpdate}
        onCancel={onCancel}
        service={service}
      />
    </Popover>
  );
}

ViewEditRatePopover.propTypes = {
  // id: string.isRequired,
  // handleClose: func.isRequired,
  // anchorEl: oneOfType([null, any]).isRequired,
  // formObj: shape({}).isRequired,
  // currentRate: number.isRequired,
  // onRateUpdate: func.isRequired,
  // onCancel: func.isRequired,
};

ViewEditRatePopover.defaultProps = {
  // id: '',
  // handleClose: () => {},
  // anchorEl: null,
  // formObj: {},
  // currentRate: 0,
  // onRateUpdate: () => {},
  // onCancel: () => {},
};

export { ViewEditRatePopover };
