import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';

import { RoomForm } from '../RoomForm';
import { RoomAction } from '../../../actions';

function EditRoom() {
  const { roomId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { formData, redirect } = useSelector(state => state.rooms);

  useEffect(() => {
    dispatch(RoomAction.get(roomId));
  }, [roomId]);

  useEffect(() => {
    if (redirect.status) {
      history.push(redirect.url);
    }
  }, [redirect.status]);

  const updateFormData = (attribute, value) => {
    switch (attribute) {
      case 'name':
        dispatch(RoomAction.updateForm({ ...formData, name: value }));
        break;
      case 'alias':
        dispatch(RoomAction.updateForm({ ...formData, alias: value.split(',') }));
        break;
      default:
        dispatch(RoomAction.updateForm({ ...formData }));
        break;
    }
  };

  const formAction = () => {
    dispatch(RoomAction.update(roomId, { ...formData }));
  };

  return (
    <RoomForm
      formData={formData}
      updateFormData={updateFormData}
      onSubmit={formAction}
      formLabel="Update"
    />
  );
}

export { EditRoom };
