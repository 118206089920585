import React from 'react';
import { bool, number, oneOfType, string } from 'prop-types';
import cx from 'classnames';

import { roundOff } from 'utils';

/**
 *
 * @param displayAsWholeValue
 * @param cost
 * @param displayPercentage
 * @param percentage
 * @param className
 * @returns {*}
 * @constructor
 */
function Amount({ cost, displayPercentage, percentage, displayAsWholeValue, className }) {
  const classes = cx('', className);
  let amount = cost;
  let displayInLakh = false;
  if (!Number.isNaN(Number(cost))) {
    amount = Number(cost);
    if (amount > 1e5) {
      amount = parseFloat((amount / 1e5).toFixed(2));
      displayInLakh = true;
    }

    if (displayAsWholeValue) {
      amount = roundOff(amount);
    }

    // for adding ₹ and placing ',' at appropriate places
    const options = {
      style: 'currency',
      currency: 'INR',
    };
    if (!displayInLakh) {
      options.minimumFractionDigits = 0;
      options.maximumFractionDigits = 0;
    }
    amount = new Intl.NumberFormat('en-IN', options).format(amount);
  }

  return (
    <span className={classes}>
      {/* eslint-disable-next-line react/no-danger */}
      {<span dangerouslySetInnerHTML={{ __html: amount }} />}
      {amount !== 'N/A' && displayInLakh && <span className="value tw-ml-1">Lacs </span>}
      {displayPercentage && <span>{percentage} %</span>}
    </span>
  );
}

Amount.defaultProps = {
  displayPercentage: false,
  displayAsWholeValue: true,
  percentage: 0,
  className: '',
};

Amount.propTypes = {
  cost: oneOfType([number, string]).isRequired,
  displayPercentage: bool,
  displayAsWholeValue: bool,
  percentage: number,
  className: string,
};

export { Amount };
