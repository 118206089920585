import React from 'react';
import { func, string } from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

function CaretDownBtn({ onClick, className }) {
  const classes = `tw-cursor-pointer tw-h-6 tw-w-6 ${className}`;
  return <FontAwesomeIcon className={classes} icon={faCaretDown} onClick={onClick} />;
}

CaretDownBtn.propTypes = {
  onClick: func.isRequired,
  className: string,
};

CaretDownBtn.defaultProps = {
  className: '',
};

export { CaretDownBtn };
