import React from 'react';
import { bool, func, string } from 'prop-types';
import classNames from 'classnames';

import './btn.scss';

function PrimaryBtn({ text, onClick, className, disabled }) {
  const classes = classNames(
    'btn-sm btn-primary rounded text-white w-auto p-2',
    className,
    `${disabled ? 'tw-cursor-not-allowed' : ''}`,
  );
  return (
    <button type="button" className={classes} onClick={onClick} disabled={disabled}>
      {text}
    </button>
  );
}

PrimaryBtn.propTypes = {
  text: string.isRequired,
  onClick: func.isRequired,
  className: string,
  disabled: bool,
};

PrimaryBtn.defaultProps = {
  className: '',
  disabled: false,
};

export { PrimaryBtn };
