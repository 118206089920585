import React, { useEffect } from 'react';
import { string, func } from 'prop-types';

import { Btn, PrimaryBtn } from '../Buttons';

import './confirmation-popup.scss';

function ConfirmationPopup({ title, description, onConfirm, onCancel }) {
  useEffect(() => {
    const originalBehaviour = document.body.style.overflow;
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = originalBehaviour;
    };
  }, []);
  return (
    <div className="confirmation-popup">
      <div className="confirmation-popup-modal">
        <div className="heading">{title}</div>
        <div className="description">{description}</div>
        <div className="footer">
          <div className="action-buttons">
            <Btn
              onClick={onCancel}
              className="btn-sm btn-primary rounded text-white w-auto p-2"
              text="Cancel"
            />
            <PrimaryBtn onClick={onConfirm} text="Ok" />
          </div>
        </div>
      </div>
    </div>
  );
}

ConfirmationPopup.propTypes = {
  title: string,
  description: string,
  onConfirm: func.isRequired,
  onCancel: func.isRequired,
};

ConfirmationPopup.defaultProps = {
  title: 'Are you sure?',
  description: 'This will permanently be deleted.',
};

export { ConfirmationPopup };
