import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
// eslint-disable-next-line import/no-named-default
import { default as MAToolTip } from '@material-ui/core/Tooltip';
import { IoIosInformationCircleOutline } from 'react-icons/all';
import { string, node, number, bool, func } from 'prop-types';
import { isMobileOnly } from 'react-device-detect';

function ToolTip({
  placement,
  children,
  content,
  width,
  maxWidth,
  autoOpen,
  interactive,
  onClose,
}) {
  const [open, setOpen] = useState(autoOpen);
  const handleClose = () => {
    onClose();
    setOpen(false);
  };

  const useStyles = makeStyles(theme => ({
    arrow: {
      '&:before': {
        border: '1px solid #E6E8ED',
      },
      color: theme.palette.common.white,
    },
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0)',
      boxShadow: theme.shadows[2],
      marginTop: '0.5rem',
      padding: '15px',
      fontSize: 13,
      fontWeight: 400,
      width,
      maxWidth,
    },
  }));

  const classes = useStyles();

  const handleOpen = () => {
    setOpen(true);
  };

  const defaultProps = {
    interactive,
    title: content,
    placement,
    arrow: true,
    open,
    classes,
  };

  if (isMobileOnly) {
    defaultProps.leaveTouchDelay = 3000;
    defaultProps.enterTouchDelay = 50;
  }
  return (
    <MAToolTip {...defaultProps} onClose={handleClose} onOpen={handleOpen}>
      <button type="button" className="tw-m-0 tw-p-0">
        {children !== undefined ? children : <IoIosInformationCircleOutline />}
      </button>
    </MAToolTip>
  );
}

ToolTip.propTypes = {
  placement: string,
  children: node,
  content: node.isRequired,
  width: number,
  maxWidth: number,
  autoOpen: bool,
  interactive: bool,
  onClose: func,
};

ToolTip.defaultProps = {
  placement: 'bottom',
  width: 300,
  maxWidth: 300,
  children: '',
  autoOpen: false,
  interactive: true,
  onClose: () => {},
};

export { ToolTip };
