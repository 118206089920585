const POINTS = {
  generalDetailsCarpentry: [
    'Area considered could be in Square feet (sqft.), Running feet (rft.) or Lump sum (ls); this depends on the type of item.',
    'As a typical thumb rule, usually the area is provided in sqft unless the item being considered has a height which is lesser than 1 foot. In this case, the area is calculated in rft.',
    'For certain items, like side tables or loose furniture (chairs, sofas), the area is typically provided in Lump sum (ls)',
  ],
  materialsUsedCarpentry: [
    'While calculating material qty, we have also accounted for material wastage',
    'Hardware costs are provided as a lump sum cost',
    'For materials like polishing (for veneers, PU, Duco and some other finishes) we have assumed cost for machine polish and not hand polish',
    'For material brands and assumed rates, you can go to the assumptions section to get more details',
  ],
  generalDetailsCivil: [
    'The total cost includes all costs for supply and installation of the item',
  ],
  workItemsCivil: [
    'The Work Item tab lists all the tasks that are needed to perform the specified activity (activity could be flooring, bathroom making, etc)',
    'Each work item will have its own labor and material cost',
  ],
  materialsUsedCivil: [
    'The material quantity includes the quantity accounted for wastage (where applicable)',
    'The material rate does not include the cost of loading, unloading and transporting the item. This is just the cost of purchasing the item',
    'All sizes for tiles, marble, granite, quartz & wooden flooring are mentioned in feet',
  ],
  generalDetailsPainting: [
    'To calculate the total wall painting area, we have used an industry thumb-rule.',
    'The per sqft. rate includes the labor and material cost',
  ],
  generalDetailsElectrical: [
    'The rate includes cost of adding some new electrical points and / or shifting some existing ones',
    'We have assumed 50% new points and 50% existing points, in the case that shifting of points needs to happen.',
    'We have used historical data to determine the actual number of electrical points',
    'The per sqft rate includes the installation cost for all light fittings, hanging lights, fans, etc.',
    'The per sqft rate includes the basic cost for switches & sockets. The costs may vary with selections',
    'The cost of the light fixtures, fans, hanging lights and main distribution board is not included in the per sqft rate.',
    'We have assumed that the existing main distribution board (MDB), switchboards, switches & sockets will be used, in case shifting of points is involved',
  ],
  electricalPoints: [
    'We have assumed that your apartment will need 50% new points and 50% existing points',
    'We have used historical analysis to come up with the actual number of electrical points',
  ],
  generalDetailsFalseCeiling: [
    "We have used historical analysis & certain formulae (based on your apartment's carpet area) to determine the false ceiling area for the bathrooms & other areas",
    'The per sqft. rate includes the labor, hardware and material cost',
  ],
  generalDetailsSoftFurnishing: [
    'We have used historical analysis & formulae based on the data we have to determine the area required for curtains',
    'The per sqft rate includes the labor, hardware & material cost',
    'The number of meters of fabric includes the fabric needed for the sheer as well as the main curtains.',
    'Thus, we have accounted for double layered curtains, when calculating the fabric quantity',
  ],
  materialsUsedSoftFurnishing: [
    'The material rate does not include the labor cost / stitching cost; it only includes the cost of the fabric',
    'We have assumed a material rate after surveying the market and understanding the selection available. We have also taken into account selections from past projects.',
    'The number of meters of fabric includes the fabric needed for the sheer as well as the main curtains.',
    'Thus, we have accounted for double layered curtains, when calculating the fabric quantity',
  ],
  projectTimeline: [
    'This a high-level time-line for the approximate number of weeks it will take to build this project end-to-end (design + build)',
    'The time-line provided is based on certain assumptions & data we have on-hand without surveying your apartment.',
    'This time-line could change (increase or decrease) after the apartment has been inspected for issues such as leakage, wall cracks, termites, structural damages, etc.',
    'In order to build the schedule, we have assumed that your building will allow a reasonable number of workers (8 to 10+) to work on site',
    'We have assumed the work hours to be 10am to 7pm with no noisy work allowed between 2 pm to 4 pm. The work days assumed are Monday to Saturday',
  ],
  referenceImages: [
    'Please note these are reference images & at times they may not be in exact sync with the costs provided.',
  ],
};

export { POINTS };
