import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import './toast.scss';

function Toast() {
  const {
    toast: {
      show,
      data: { message = 'Something went wrong!', type },
    },
  } = useSelector(state => state.modal);
  const ref = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (show) {
      setTimeout(() => dispatch({ type: 'HIDE_TOAST' }), 3000);
    }
  }, [show]);

  const errorClass = 'tw-bg-red-100 tw-text-red-900';
  const successClass = 'tw-bg-teal-100 tw-text-teal-900';

  const handleOnClose = () => {
    dispatch({ type: 'HIDE_TOAST' });
  };

  return (
    <>
      {show && (
        <div
          ref={ref}
          className="tw-w-full tw-fixed tw-flex tw-justify-center tw-top-0 tw-mt-2"
          style={{ zIndex: 9999 }}
        >
          <div
            className={`tw-w-full md:tw-w-1/2  tw-rounded-b tw-shadow-md ${
              type === 'success' ? successClass : errorClass
            }`}
            role="alert"
          >
            <div className="tw-flex tw-relative tw-px-4 tw-py-3">
              <div className="tw-py-1">
                <svg
                  className={`tw-fill-current tw-h-6 tw-w-6 tw-mr-4 ${
                    type === 'success' ? 'tw-text-teal-600' : 'tw-text-red-600'
                  }`}
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                </svg>
              </div>
              <div className="tw-py-1">
                <span className="tw-font-bold tw-py-1">{message}</span>
              </div>
              <div className="">
                <span className="tw-absolute right-10">
                  <svg
                    className={`tw-fill-current tw-h-6 tw-w-6 ${
                      type === 'success' ? 'tw-text-teal-600' : 'tw-text-red-600'
                    }`}
                    role="button"
                    onClick={() => handleOnClose()}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <title>Close</title>
                    <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                  </svg>
                </span>
              </div>
            </div>
            <div className="tw-relative">
              <div
                className={`progress-loader-div tw-absolute tw-bottom-0 tw-w-full tw-h-1 ${
                  type === 'success' ? 'tw-bg-teal-400' : 'tw-bg-red-400'
                }`}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

Toast.propTypes = {};

Toast.defaultProps = {
  message: 'Oops!',
  type: 'success',
};
export { Toast };
