import React from 'react';
import { arrayOf, string } from 'prop-types';

const FootNotes = ({ terms }) => {
  const items = [
    'Above rates are not inclusive of Loading, Unloading & Transportation Cost. It will be charged extra.',
    'Above rates are with GST.',
    'Laying Charges are not included in above rates.',
    ...terms,
  ];
  return (
    <div>
      <h5 className="mb-3">Notes</h5>
      <ul className="list-group">
        {items.map(term => {
          return <li className="list-group-item">{term}</li>;
        })}
      </ul>
    </div>
  );
};

FootNotes.defaultProps = {
  terms: [],
};

FootNotes.propTypes = {
  terms: arrayOf(string),
};

export { FootNotes };
