import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { SimpleModal } from '../../Shared';
import { VendorAction } from '../../../actions';

const AddPattern = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const { patterns, finishes } = useSelector(state => state.vendor);
  const [pattern, setPattern] = useState(null);

  const finishCategory = finishes.find(finish => finish.slug === slug) || {};

  const {
    addPattern: { show = false },
  } = useSelector(state => state.modal);

  if (!show) {
    return null;
  }

  const onCloseHandler = () => {
    dispatch({ type: 'HIDE_ADD_PATTERN' });
  };

  const onFormSubmitHandler = e => {
    e.preventDefault();
    if (!pattern) {
      return;
    }

    const existingPatterns = finishCategory?.patterns ?? [];
    setTimeout(() => {
      dispatch(
        VendorAction.updateCategory({
          slug,
          patterns: [...existingPatterns, pattern],
        }),
      )
        .then(() => {
          dispatch({ type: 'HIDE_ADD_PATTERN' });
        })
        .then(() => {
          dispatch({
            type: 'DISPLAY_TOAST',
            payload: { type: 'success', message: 'Updated successfully.' },
          });
        });
    }, 1000);
  };

  const onChangeHandler = e => {
    const chosen = e.target.value;
    setPattern(chosen);
  };

  return (
    <SimpleModal open={show} classes="tw-relative" onClose={onCloseHandler} fullWidth maxWidth="sm">
      <h4 className="center-align">Add Patterns</h4>
      <form>
        <div className="form-group">
          <label htmlFor="pattern">Select type of Pattern</label>
          <select name="pattern" id="pattern" className="form-control" onChange={onChangeHandler}>
            {patterns.map(item => {
              return <option value={item._id}>{item.name}</option>;
            })}
          </select>
        </div>
        <button type="submit" className="btn btn-primary btn-block" onClick={onFormSubmitHandler}>
          Add
        </button>
      </form>
    </SimpleModal>
  );
};
export { AddPattern };
