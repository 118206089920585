import {
  FETCH_ALL_ROOMS,
  FETCH_ROOM,
  UPDATE_ROOM_FORM,
  UPDATE_REDIRECT,
} from '../constants/actionTypes';
import { doDelete, doGet, doPost, doPut } from '../utils';

/**
 * mapped /rooms
 */
class RoomAction {
  static getAll() {
    return async dispatch => {
      try {
        const { data: payload } = await doGet({ url: '/rooms' });
        dispatch({ type: UPDATE_REDIRECT, payload: { status: false, url: null } });
        dispatch({ type: FETCH_ALL_ROOMS, payload });
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static get(roomId) {
    return async dispatch => {
      try {
        const { data: payload } = await doGet({ url: `/rooms/${roomId}` });
        dispatch({ type: FETCH_ROOM, payload });
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static create(data) {
    return async dispatch => {
      try {
        await doPost({ url: '/rooms', body: data });
        dispatch({
          type: UPDATE_ROOM_FORM,
          payload: {
            id: '',
            name: '',
            alias: [],
          },
        });
        dispatch({ type: UPDATE_REDIRECT, payload: { status: true, url: '/rooms' } });
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static update(roomId, data) {
    return async dispatch => {
      try {
        await doPut({ url: `rooms/${roomId}`, body: data });
        dispatch({ type: UPDATE_REDIRECT, payload: { status: true, url: '/rooms' } });
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static delete(roomId) {
    return async dispatch => {
      try {
        await doDelete({ url: `rooms/${roomId}` });
        dispatch(RoomAction.getAll());
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static updateForm(data) {
    return dispatch => dispatch({ type: UPDATE_ROOM_FORM, payload: data });
  }
}

export { RoomAction };
