import React from 'react';
import { MobileOnlyView, isMobileOnly } from 'react-device-detect';

import { AssumptionsForWeb } from './AssumptionsForWeb';
import AssumptionsForMobile from './AssumptionsForMobile';

const Assumptions = () => {
  const civilAssumptions = [
    ['Marble', 'Sqft', 350, 'Classic Marble Company, Kalingastone, Dreamax, Aditya Stonex'],
    ['Natural Stone', 'Sqft', 120, ''],
    ['Quartz stone', 'Sqft', 350, 'Pelican, Classic Marble Company, Kalingastone, Alicante, Pearl'],
    ['Vitrified Tiles', 'Sqft', 120, 'Nitco, Kajaria'],
    ['Ordinary Tiles', 'Sqft', 35, 'Local Purchase'],
    ['Laminated Wooden Flooring', 'Sqft', 180, 'Pergo, Kronoswiss, ARL, Euro'],
    ['Engineered Wooden Flooring', 'Sqft', 450, 'Opulo, Pergo, Kingsmen, Mikasa, Euro'],
    ['Wooden skirting', 'Rft', 90, 'D. S. Flooring, H. K. Interiors, Mahanta'],
    ['Granite', 'Sqft', 180, 'RK Marble, Aravali, ELANZA, BVL Granites'],
    ['Deck Flooring', 'Sqft', 250, 'Sundeck, SS Décor, Surfaces'],
    ['Vinyl Flooring', 'Sqft', 105, 'Wonderfloor, Poly Floor, Premier Polyfilms, Ajantha'],
    ['Debris', 'Per Trip', 6000, ''],
  ];
  const carpentryAssumptions = [
    ['MR grade Commerical Ply - 19mm', "8'x4' Sheet - Sqft", '-', 'Glory, Excel, Ronak'],
    ['Marine / BWR ply - 19mm', "8'x4' Sheet - Sqft", '-', 'Gunja, Donyi'],
    ['Veneer', 'Sqft', 150, 'Century, Greenlam'],
    [
      'External/Finished Laminates',
      "8'x4' Sheet - Sqft",
      65,
      'Merinolam , Aerolam, Century, Greenlam',
    ],
    ['Internal grey laminate', "8'x4' Sheet - Sqft", 22, 'Merinolam, Aerolam, Century, Greenlam'],
    ['Unicore Laminate', "8'x4' Sheet - Sqft", 115, 'Merinolam, Aerolam, Century, Greenlam'],
    ['Shera Ply', 'Sqft', 35, 'Shera'],
    ['Gypsum Board', 'Sqft', 48, 'Saint Gobain'],
  ];

  const carpentryAssumptionsForMobile = [
    ['MR grade Commerical Ply - 19mm', '-', '-', 'Glory, Excel, Ronak'],
    ['Marine / BWR ply - 19mm', '-', '-', 'Gunja, Donyi'],
    ['Veneer', 'Sqft', 150, 'Century, Greenlam'],
    ['External/Finished Laminates', '-', 65, 'Merinolam , Aerolam, Century, Greenlam'],
    ['Internal grey laminate', '-', 22, 'Merinolam, Aerolam, Century, Greenlam'],
    ['Unicore Laminate', '-', 115, 'Merinolam, Aerolam, Century, Greenlam'],
    ['Shera Ply', 'Sqft', 35, 'Shera'],
    ['Gypsum Board', 'Sqft', 48, 'Saint Gobain'],
  ];

  const kitchen = [
    ['SS Basket', 'Per No', 5500],
    ['Tandem drawer', 'Per No', 6500],
    ['Cutlery', 'Per No', 4000],
  ];
  const looseFurnitureAssumptions = [
    [
      'Fabric for Sofas, Curtains, Upholstrey Work',
      'Meter',
      500,
      'Vardhaman ,Walspun ,Raymond ,K P L ,Page Industries',
    ],
  ];

  const painting = [
    ['Textured paint', 'Sqft', 250, 'Asian Paints'],
    ['Wallpaper ', 'Sqft', 350, 'Asian Paints ,Marshalls ,Adornis ,Elemento ,Gratex ,Eurotex'],
  ];
  return (
    <>
      {!isMobileOnly && (
        <AssumptionsForWeb
          kitchen={kitchen}
          carpentryAssumptions={carpentryAssumptions}
          looseFurnitureAssumptions={looseFurnitureAssumptions}
          civilAssumptions={civilAssumptions}
          painting={painting}
        />
      )}
      <MobileOnlyView>
        <AssumptionsForMobile
          kitchen={kitchen}
          carpentryAssumptions={carpentryAssumptionsForMobile}
          looseFurnitureAssumptions={looseFurnitureAssumptions}
          civilAssumptions={civilAssumptions}
          painting={painting}
        />
      </MobileOnlyView>
    </>
  );
};

export { Assumptions };
