import { number } from 'prop-types';
import { useState } from 'react';

import { getCookieIdentifier, doGet } from 'utils';

function useDownloadCostEstimate(boqId, reportType = 'excel-report') {
  const [isDownloading, setIsDownloading] = useState(false);
  const download = async () => {
    try {
      let apiUrl = `/boqs/${boqId}/export`;
      if (reportType === 'summary-pdf') {
        apiUrl = `/summary/report?boq_id=${boqId}`;
      }
      setIsDownloading(true);
      const headers = {};
      if (getCookieIdentifier()) {
        headers['System-Identifier'] = getCookieIdentifier();
      }
      const {
        data: { url },
      } = await doGet({ url: apiUrl, headers });
      window.open(url, '_blank');
    } catch (e) {
      console.error(e);
    } finally {
      setIsDownloading(false);
    }
  };
  return [isDownloading, download];
}

useDownloadCostEstimate.propTypes = {
  boqId: number,
};
useDownloadCostEstimate.defaultProps = {};

export { useDownloadCostEstimate };
