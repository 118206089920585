import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const Report = () => {
  const dispatch = useDispatch();
  return (
    <>
      <div className="tw-container tw-mx-auto">
        <div className="tw-bg-white tw-shadow-xl tw-m-8 tw-p-4 tw-rounded-lg">
          <div className="tw-flex tw-flex-col lg:tw-flex-row">
            <div className="tw-w-full lg:tw-w-1/2 tw-relative tw-pr-4 tw-pb-10 lg:tw-pb-0 ">
              <ul className="tw-flex tw-flex-row">
                <li className="tw-text-text2 tw-mr-2">
                  <Link to="/">Home</Link>
                </li>
                <li className="tw-text-text2 tw-mr-2">/</li>
                <li className="tw-text-text2 tw-mr-2 tw-text-opacity-50">Contact Support</li>
              </ul>
              <h2
                className="tw-text-text2 tw-font-bold tw-text-center"
                style={{ fontSize: '2rem', margin: '1em 0' }}
              >
                Understanding your Cost Estimate
              </h2>
              <p className="tw-text-text2 tw-w-8/12 tw-text-center tw-mx-auto">
                Schedule a call with an expert who will walk you through your cost estimate.
              </p>
              <button
                type="button"
                onClick={() => dispatch({ type: 'VIEW_CONSULT_WITH_US' })}
                className="tw-bg-cta tw-font-bold tw-text-white tw-w-4/5 lg:tw-w-2/5 tw-block tw-text-center tw-rounded-lg tw-p-4 tw-mt-5 mt-5 lg:tw-mt-18 tw-mx-auto"
              >
                Schedule a call
              </button>
            </div>
            <div className="tw-w-full lg:tw-w-1/2 tw-border-t lg:tw-border-t-0 lg:tw-border-l mt-4 tw-pl-4 tw-border-gray-200">
              <h2
                className="tw-text-text2 tw-font-bold tw-text-center"
                style={{ fontSize: '2rem', margin: '1em 0' }}
              >
                Speak to an Interior Designer
              </h2>
              <p className="tw-text-text2 tw-w-8/12 tw-text-center tw-mx-auto">
                Get connected to an interior designer to design and build your dream home completely
                customized to your needs!
              </p>
              <button
                type="button"
                onClick={() => dispatch({ type: 'VIEW_CONSULT_WITH_US' })}
                className="tw-bg-cta tw-font-bold tw-text-white tw-w-4/5 lg:tw-w-2/5 tw-block tw-text-center tw-rounded-lg tw-p-4 tw-mt-5 mt-5 lg:tw-mt-18 tw-mx-auto"
              >
                Schedule a call
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Report;
