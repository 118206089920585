import React, { useContext, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { shape, node, func } from 'prop-types';

import { isLoggedInUser } from 'utils';
import UserContext from '../../UserContext';

function HOC({ children, history }) {
  const { email, role } = useContext(UserContext);
  useEffect(() => {
    if (email && role === 'user') {
      // use path name instead of absolute URL
      history.replace(`/complete-profile?redirect=${window.location.pathname}`);
    }
  }, [email]);
  return <>{children}</>;
}

HOC.propTypes = {
  children: node.isRequired,
  history: shape({
    replace: func,
  }).isRequired,
};

export default function withAuth(ComponentToProtect) {
  return props => {
    if (!isLoggedInUser()) {
      return <Redirect to="/login" />;
    }
    return (
      <HOC {...props}>
        <ComponentToProtect {...props} />
      </HOC>
    );
  };
}
