import React, { useContext } from 'react';
import { arrayOf, string, shape } from 'prop-types';
import cx from 'classnames';

import UserContext from '../../../UserContext';
import { ViewMore } from './ViewMore';
import { useCollapsable } from './useCollapsable';

import '../vendor-style.scss';

const BrandList = ({ brands = [] }) => {
  const { role } = useContext(UserContext);
  const isAdmin = role === 'admin';

  const { count, items, onItemClick } = useCollapsable({
    collection: brands,
    adminRedirectUrl: '/vendors/brand/:slug/details',
  });

  return (
    <section className="section-container">
      <h5 className="vendor-title">Brands ({count})</h5>
      <ul className="card-container">
        {items.map(brand => {
          const { type, image_url: imageURL, slug, name } = brand;
          const isViewMore = type === 'viewMore';

          return (
            <li key={slug} onClick={() => onItemClick({ slug, isAdmin })}>
              <div
                className={cx('card-item', {
                  'center-content': isViewMore,
                  'category-card': !isViewMore,
                  'blur-image': !isViewMore,
                  'white-background-color': isViewMore,
                })}
                style={{
                  backgroundImage: `url(${imageURL})`,
                  backgroundSize: 'cover',
                }}
              >
                {!isViewMore && <span className="upper-case-text bold-font">{name}</span>}
                {isViewMore && <ViewMore />}
              </div>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

BrandList.propTypes = {
  brands: arrayOf(
    shape({
      type: string,
      image_url: string,
    }),
  ).isRequired,
};
export { BrandList };
