import { doDelete, doGet, doPost, doPut } from 'utils/NetworkUtils';
import { BoqAction } from 'actions/BoqAction';
import { FETCH_CIVIL_BOQ } from 'constants/actionTypes';
import { ACTION_CONSTANTS } from 'actions/constants';
import { RevisionHistoryAction } from './RevisionHistoryAction';

class CivilAction {
  static fetchAll(boqId) {
    return async dispatch => {
      try {
        const {
          data: { items: civilWorks },
        } = await doGet({ url: `/civil?boq_id=${boqId}` });
        const sum = civilWorks.reduce((totalCost, { cost }) => {
          return totalCost + cost;
        }, 0);
        dispatch({ type: FETCH_CIVIL_BOQ, payload: { civilWorks, cost: sum } });
      } catch (e) {
        console.error(e);
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static fetchById(boqId, id) {
    return async dispatch => {
      try {
        const { data: payload } = await doGet({ url: `/civil/${id}?boq_id=${boqId}` });
        dispatch({ type: 'UPDATE_BOQ_CIVIL_ITEM_FORM', payload });
        dispatch({ type: 'UPDATE_BOQ_CIVIL_ITEM', payload });
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static get(boqId) {
    return async dispatch => {
      try {
        const { data: payload } = await doGet({ url: `civil?boq_id=${boqId}` });
        dispatch({ type: FETCH_CIVIL_BOQ, payload });
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static getEstimateForCivilItem(oldData, newData, action = 'update') {
    return async dispatch => {
      try {
        dispatch({
          type: 'UPDATE_BOQ_CIVIL_ITEM_STATE',
          payload: { displayCostLoader: true },
        });
        const formData = {
          work: newData.label,
          dimension: newData.dimension,
          room: newData.room,
        };

        if (newData.label.toLowerCase().includes('floor protection')) {
          formData.carpet_area = Number(newData.carpet_area);
        }

        if (newData.label.toLowerCase().includes('debris shifting')) {
          formData.total_trips = Number(newData.total_trips);
          formData.carpet_area = Number(newData.carpet_area);
        }

        if (newData.finish) {
          formData.finish = newData.finish.label;
        }
        const { data: payload } = await doPost({ url: 'catalog/civil/estimate', body: formData });
        // @TODO: Check it out later. Can be merged.
        if (action === 'new') {
          dispatch({ type: 'UPDATE_BOQ_CIVIL_WORK_ITEM', payload });
          dispatch({ type: 'UPDATE_BOQ_CIVIL_WORK_ITEM_FORM', payload });
        } else if (action === 'update') {
          dispatch({ type: 'UPDATE_BOQ_CIVIL_ITEM', payload });
          dispatch({ type: 'UPDATE_BOQ_CIVIL_ITEM_FORM', payload });
        }
      } catch (e) {
        dispatch({ type: 'UPDATE_BOQ_CIVIL_ITEM_FORM', payload: oldData });
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      } finally {
        dispatch({
          type: 'UPDATE_BOQ_CIVIL_ITEM_STATE',
          payload: { displayCostLoader: false },
        });
      }
    };
  }

  static create(boqId, formData) {
    return async dispatch => {
      try {
        await doPost({
          url: `/civil?boq_id=${boqId}`,
          body: {
            dimension: formData.dimension,
            room: formData.room,
            work: formData.label,
            ...(formData.finish ? { finish: formData.finish.label } : {}),
          },
        });
        // dispatch({
        //   type: 'UPDATE_BOQ_CIVIL_ITEM_STATE',
        //   payload: { displayCarpentryItem: false, formData: { count: 1 } },
        // });
        dispatch(BoqAction.fetchBoqDetails(boqId));
        dispatch(CivilAction.get(boqId));
        dispatch({ type: 'HIDE_ADD_CIVIL_WORK' });
        dispatch(RevisionHistoryAction.fetchList(boqId));
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'success', message: ACTION_CONSTANTS.itemNew },
        });
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static update(boqId, formData) {
    return async dispatch => {
      try {
        const data = {
          dimension: formData.dimension,
          room: formData.room,
          work: formData.label,
        };

        if (formData.label.toLowerCase().includes('floor protection')) {
          data.carpet_area = Number(formData.carpet_area);
        }

        if (formData.label.toLowerCase().includes('debris shifting')) {
          data.total_trips = Number(formData.total_trips);
          data.carpet_area = Number(formData.carpet_area);
        }

        if (formData.finish) {
          // @TODO: This can be omitted
          if (formData.finish.label !== 'N/A') {
            data.finish = formData.finish.label;
          }
        }
        const { id } = formData;
        await doPut({ url: `/civil/${id}?boq_id=${boqId}`, body: data });
        // dispatch({
        //   type: 'UPDATE_BOQ_CIVIL_ITEM_STATE',
        //   payload: { displayCarpentryItem: false, formData: { count: 1 } },
        // });
        dispatch(BoqAction.fetchBoqDetails(boqId));
        dispatch(CivilAction.get(boqId));
        dispatch({ type: 'HIDE_VIEW_CIVIL' });
        dispatch({
          type: 'UPDATE_BOQ_CIVIL_EDIT_MODE',
          payload: { display: false, formData: {} },
        });
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'success', message: ACTION_CONSTANTS.itemUpdated },
        });
        dispatch(RevisionHistoryAction.fetchList(boqId));
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static remove(boqId, id) {
    return async dispatch => {
      try {
        await doDelete({ url: `civil/${id}?boq_id=${boqId}` });
        dispatch({ type: 'HIDE_VIEW_CIVIL' });
        dispatch(BoqAction.fetchBoqDetails(boqId));
        dispatch(CivilAction.get(boqId));
        dispatch(RevisionHistoryAction.fetchList(boqId));
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'success', message: ACTION_CONSTANTS.itemDeleted },
        });
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.response.data.message },
        });
      }
    };
  }

  static removeMany(boqId, ids) {
    return async dispatch => {
      try {
        // const futures = [];
        const urls = [];
        ids.forEach(id => {
          urls.push(`civil/${id}?boq_id=${boqId}`);
          // futures.push(doDelete({ url: `civil/${id}?boq_id=${boqId}` }));
        });
        // await Promise.all(futures);
        for (let i = 0; i < urls.length; i += 1) {
          /* eslint-disable no-await-in-loop */
          await doDelete({ url: urls[i] });
          /* eslint-enable no-await-in-loop */
        }
        dispatch(BoqAction.fetchBoqDetails(boqId));
        dispatch(CivilAction.get(boqId));
        dispatch({ type: 'UPDATE_BOQ_CIVIL_SELECTED_ITEMS', payload: new Set([]) }); // Should it be moved to reducer?
        dispatch(RevisionHistoryAction.fetchList(boqId));
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'success', message: 'Removed!' },
        });
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.response.data.message },
        });
      }
    };
  }

  static displayRelevantRooms(civilItem, rooms) {
    let filteredRooms = rooms;
    if (civilItem.search(/bathroom/i) !== -1) {
      filteredRooms = rooms.filter(room => room.name.search(/bathroom|toilet|washroom/i) !== -1);
    }

    if (civilItem.search(/kitchen/i) !== -1) {
      filteredRooms = rooms.filter(room => room.name.search(/kitchen/i) !== -1);
    }
    // @TODO: Refactor
    return async dispatch => {
      dispatch({ type: 'UPDATE_BOQ_NEW_CIVIL_WORK_STATE', payload: { rooms: filteredRooms } });
      dispatch({ type: 'UPDATE_BOQ_CIVIL_ITEM_STATE', payload: { rooms: filteredRooms } });
    };
  }
}

export { CivilAction };
