import React from 'react';
import { string } from 'prop-types';

import './placeholder.scss';

function Placeholder({ classes }) {
  return <div className={`hc-placeholder ${classes}`} />;
}

Placeholder.propTypes = {
  classes: string,
};
Placeholder.defaultProps = {
  classes: '',
};

export { Placeholder };
