import { flatMap } from 'lodash/collection';
import { useEffect, useState } from 'react';

function useTextSearch(items = [], searchKeys = []) {
  const [matchedItems, setMatchedItems] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    setMatchedItems(items);
  }, [items]);

  const searchFn = () => {
    if (searchValue === '') {
      setMatchedItems(items);
    } else {
      let temp = [];
      for (let i = 0; i <= searchKeys.length; i += 1) {
        temp.push([]);
      }
      items.forEach(item => {
        const matchResults = [];
        searchKeys.forEach(key => {
          matchResults.push(item[key].match(new RegExp(searchValue, 'i')));
        });

        const index = matchResults.findIndex(condition => condition !== null);
        if (index !== -1) {
          temp[index].push(item);
        }
      });
      temp = flatMap(temp);
      setMatchedItems(temp);
    }
  };

  useEffect(() => {
    searchFn();
  }, [searchValue]);

  return { searchResult: matchedItems, searchFn, searchValue, setSearchValue };
}

export { useTextSearch };
