/* eslint-disable object-shorthand */
/* eslint-disable space-before-function-paren */

import React, { useState, useEffect } from 'react';
import * as ReactDOMServer from 'react-dom/server';
import { get } from 'lodash';
import { Amount } from 'components/Amount';

function useBarChart(obj = {}) {
  const [chartOptions, setChartOptions] = useState(null);
  const [chartData, setChartData] = useState({ x: [], y: [] });
  const options = {
    title: {
      text: null,
    },
    chart: {
      type: 'bar',
    },
    colors: get(obj, 'colors', ['#36A2EBFF']),
    credits: { enabled: false },
    xAxis: {
      categories: chartData.x,
      labels: {
        formatter: get(obj, 'xAxis.labels.formatter', data => data.value),
        style: {
          fontSize: '12px',
        },
      },
      useHTML: true,
      title: {
        text: null,
      },
    },
    yAxis: {
      enabled: false,
      opposite: true,
      gridLineColor: '#f6f4f4',
      gridLineDashStyle: 'dash',
      gridLineOpacity: 0,
      min: 0,
      // offset: 50,
      title: {
        text: 'Cost (In Lacs)',
        align: 'middle',
        // "textAlign": 'right',
        // rotation: 180,
        // y: 30,
        // x: 48,
        offset: 50,
      },
      labels: {
        overflow: 'justify',
        // eslint-disable-next-line func-names
        formatter: function() {
          return this.value / 1e5;
        },
      },
    },
    tooltip: {
      ...{
        // eslint-disable-next-line func-names
        formatter: function() {
          return `${this.x}: ${ReactDOMServer.renderToString(
            // eslint-disable-next-line react/jsx-filename-extension
            <Amount className="cost" cost={this.y} />,
          )}`;
        },
      },
      ...get(obj, 'yAxis.tooltip', {}),
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
        },
      },
      series: {
        pointWidth: 20,
        dataLabels: {
          // eslint-disable-next-line func-names
          formatter: function() {
            return ReactDOMServer.renderToString(<Amount className="cost" cost={this.y} />);
          },
          enabled: true,
          // allowOverlap: true,
          // align: 'left',
          // color: '#fff',
          // shadow: false,
          // x: 50,
          style: {
            // fontSize: '8px',
            color: '#999',
          },
        },
      },
    },
    legend: {
      enabled: false,
      layout: 'vertical',
      align: 'center',
      verticalAlign: 'top',
      labelFormatter: () => {
        return ' Cost (In Lacs)';
      },
      // itemMarginTop: 10,
      // itemMarginBottom: 10,
    },
    series: [
      {
        data: chartData.y,
      },
    ],
  };
  useEffect(() => {
    options.series.shift();
    options.series.unshift({ data: chartData.y });
    setChartOptions({
      ...options,
      xAxis: { ...options.xAxis, categories: chartData.x },
      series: options.series,
    });
  }, [chartData]);
  return { chartOptions, setChartData };
}

export { useBarChart };
