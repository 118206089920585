import BasePlugin from '@uppy/core/lib/Plugin';

function blobToBase64(blob) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

class UppyConvertToBase64Plugin extends BasePlugin {
  constructor(uppy, opts) {
    super(uppy, opts);
    this.id = opts.id || 'FileUploadPlugin';
    this.type = 'file-uploader';
  }

  prepareUpload = fileIDs => {
    const files = fileIDs.map(fileID => {
      const file = this.uppy.getFile(fileID);
      // preview
      const { data, name } = file;
      this.uppy.emit('preprocess-progress', file, {
        mode: 'indeterminate',
        message: `uploading ${name}`,
      });
      return blobToBase64(data).then(content => {
        this.uppy.emit('preprocess-complete', file);
        this.uppy.setFileState(fileID, { data: content });
        // return Promise.resolve({
        //   type,
        //   content,
        //   name,
        //   id,
        //   size,
        // });
      });
    });

    return Promise.all(files).then(data => {
      return Promise.resolve(data);
    });
  };

  install() {
    this.uppy.addPreProcessor(this.prepareUpload);
  }

  uninstall() {
    this.uppy.removePreProcessor(this.prepareUpload);
  }
}

export { UppyConvertToBase64Plugin };
