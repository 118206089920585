import React, { useEffect, useState } from 'react';

import { doGet } from 'utils';

import { FinishTable } from './FinishTable';
import { SectionHeader } from '../Shared/SectionHeader';

function CarpentryFinish() {
  const [finishes, updateFinishes] = useState([]);

  useEffect(() => {
    doGet({
      url: 'finish/furniture',
    }).then(({ data }) => {
      updateFinishes(data);
    });
  }, [finishes.length]);

  return (
    <>
      <SectionHeader title="Carpentry Finishes" />
      <FinishTable finishes={finishes} editUrlSegment="finishes/furniture" />
    </>
  );
}

function CivilFinish() {
  const [finishes, updateFinishes] = useState([]);

  useEffect(() => {
    doGet({
      url: 'finish/civil',
    }).then(({ data }) => {
      updateFinishes(data);
    });
  }, finishes.length > 0);

  return (
    <>
      <SectionHeader title="Civil Finishes" />
      <FinishTable finishes={finishes} editUrlSegment="finishes/civil" />
    </>
  );
}

function FalseCeilingFinish() {
  const [finishes, updateFinishes] = useState([]);

  useEffect(() => {
    doGet({
      url: 'finish/false-ceiling',
    }).then(({ data }) => {
      updateFinishes(data);
    });
  }, finishes.length > 0);

  return (
    <>
      <SectionHeader title="False Ceiling Finishes" />
      <FinishTable finishes={finishes} editUrlSegment="finishes/false-ceiling" />
    </>
  );
}

export { CarpentryFinish, CivilFinish, FalseCeilingFinish };
