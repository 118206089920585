import { FETCH_BOQ_DETAILS_FROM_API, FETCH_ROOMS_IN_BOQ } from 'constants/actionTypes';
import { doDelete, doGet, doPatch, doPost } from 'utils/NetworkUtils';
import { toCamelCase } from 'utils/ResponseMapper';

/**
 * Mapped /boqs
 */
class BoqAction {
  static fetchBoqList(queryString, existingBoqs) {
    return async dispatch => {
      try {
        let url = '/boqs';
        if (queryString) {
          url += `?${queryString}`;
        }
        let records = [];
        const { data, meta } = await doGet({ url });
        if (existingBoqs) {
          records = [...existingBoqs];
        }
        records = [...records, ...data];
        dispatch({
          type: 'FETCH_BOQ_LIST',
          payload: { boqs: records, pagination: meta },
        });
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static fetchBoqDetails(boqId) {
    return async dispatch => {
      try {
        const data = await doGet({
          url: `${process.env.REACT_APP_API_BASE_URL}/boqs?boq_id=${boqId}`,
        });
        dispatch({
          type: FETCH_BOQ_DETAILS_FROM_API,
          payload: toCamelCase(data),
        });
      } catch (e) {
        console.error(e);
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static fetchRoomsPresentInBoq(boqId) {
    return async dispatch => {
      try {
        const { data: payload } = await doGet({ url: `/rooms?boq_id=${boqId}` });
        dispatch({ type: FETCH_ROOMS_IN_BOQ, payload });
      } catch (e) {
        console.error(e);
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static sendInvites({ name, email, boqId }) {
    return async dispatch => {
      try {
        const { data, details } = await doPost({
          url: `boqs/${boqId}/invite`,
          body: { name, email },
        });
        dispatch({
          type: 'UPDATE_INVITE',
          payload: details,
        });
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'success', message: data },
        });
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static updateBoqTitle(boqId, title) {
    return async dispatch => {
      try {
        const {
          data: { title: newTitle },
        } = await doPatch({ url: `/boqs/${boqId}`, body: { title } });
        dispatch({
          type: 'UPDATE_ESTIMATE',
          payload: { title: newTitle, boqId },
        });
        dispatch({
          type: 'UPDATE_BOQ_TITLE',
          payload: { title: newTitle, boqId },
        });
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'success', message: 'Updated successfully.' },
        });
      } catch (e) {
        console.error(e);
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static remove(boqId) {
    return async dispatch => {
      try {
        await doDelete({ url: `/boqs/${boqId}` });
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'success', message: 'Deleted successfully.' },
        });
        dispatch({
          type: 'REMOVE_ESTIMATE',
          payload: { boqId },
        });
      } catch (e) {
        console.error(e);
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static clone(boqId) {
    return async dispatch => {
      try {
        await doPost({ url: `/boqs/${boqId}/clone` });
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'success', message: 'cloned successfully.' },
        });
      } catch (e) {
        console.error(e);
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static removeSubBoq(boqId, subBoqId, onSucessCallback) {
    return async dispatch => {
      try {
        const { details } = await doPatch({
          url: `/boqs/${boqId}`,
          body: { unlink_boq_id: subBoqId },
        });
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'success', message: 'Updated successfully.' },
        });
        dispatch({
          type: 'UPDATE_INVITE',
          payload: details,
        });
        onSucessCallback();
      } catch (e) {
        console.error(e);
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }
}

export { BoqAction };
