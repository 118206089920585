import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { doGet, doPut } from 'utils';

import { SectionHeader } from '../../Shared/SectionHeader';
import { Form } from '../Form';

function EditPainting() {
  const { paintingId } = useParams();
  const [state, setState] = useState({
    label: '',
    alias: '',
    description: '',
    disabled: false,
  });

  async function fetchFromApi() {
    doGet({
      url: `catalog/painting/${paintingId}`,
    })
      .then(({ data: { label, alias, description } }) => {
        setState({
          ...state,
          label,
          description,
          alias: alias.join('\n'),
        });
      })
      .catch(err => {
        console.error(err);
      });
  }

  useEffect(() => {
    setState({ ...state, paintingId });
    fetchFromApi();
  }, []);

  const onChange = {
    updateForm: e => {
      const { id, value } = e.target;
      setState({ ...state, [id]: value });
    },
  };

  const onSubmit = {
    update: () => {
      const { label, alias, description } = state;
      const isFormInComplete = !label || !alias;
      if (isFormInComplete) {
        return;
      }

      doPut({
        url: `catalog/painting/${paintingId}`,
        body: {
          label,
          description,
          alias: alias.split(',').map(item => item.trim()),
        },
      })
        .then(() => fetchFromApi())
        .catch(() => {
          console.error('Something failed, please try again later');
        })
        .then(() => {
          setTimeout(() => {
            setState({ ...state, disabled: false });
          }, 2000);
        });
    },
  };

  const { label, alias, description, disabled } = state;
  const caption = disabled ? 'Saving...' : 'Save';
  return (
    <>
      <SectionHeader title="Painting" />
      <Form
        label={label}
        caption={caption}
        alias={alias}
        description={description}
        onChange={onChange.updateForm}
        onSubmit={onSubmit.update}
      />
    </>
  );
}

export { EditPainting };
