import { first, get } from 'lodash';
import { SHOW_UNDO_DELETION_OF_FURNITURE } from 'components/BoqDetail/FurnitureDetails/Constants';

import {
  FETCH_BOQ_DETAILS_FROM_API,
  FETCH_CARPENTRY_BOQ,
  FETCH_FINISH_FOR_DROPDOWN,
  UPDATE_CARPENTRY_LIST,
  UPDATE_CARPENTRY_COST,
  REMOVE_CARPENTRY_ITEM,
  FETCH_FALSE_CEILING_BOQ,
  UPDATE_FALSE_CEILING_BOQ,
  FETCH_PAINTING_BOQ,
  UPDATE_PAINTING_BOQ,
  FETCH_ELECTRICAL_BOQ,
  UPDATE_ELECTRICAL_BOQ,
  FETCH_UNDETECTED_CARPENTRY,
  UPDATE_UNDETECTED_CARPENTRY,
  MIGRATE_UNDETECTED_CARPENTRY,
  FETCH_CIVIL_BOQ,
  FETCH_ROOMS_IN_BOQ,
  FETCH_FINISHES_FOR_FURNITURE,
  FETCH_CARPENTRY_LIST,
  UPDATE_BOQ_NEW_CARPENTRY_STATE,
  UPDATE_CARPENTRY_DATA_BY_CARPENTRY_ID,
  UPDATE_BOQ_CARPENTRY_ITEM_STATE,
} from '../constants/actionTypes';

const INITIAL_BOQ_STATE = {
  boqId: '',
  boq: {},
  boqDetails: {},
  costBreakup: {},
  boqInfo: {},
  s3Url: '',
  user: {},
  hasError: false,
  isFetchingFromAPI: false,
  view: 'list',
  packageName: 'essentials',
};

function boqDetailReducer(state = INITIAL_BOQ_STATE, action) {
  switch (action.type) {
    case FETCH_BOQ_DETAILS_FROM_API:
      return { ...state, ...action.payload };
    case 'UPDATE_BOQ_TITLE':
      return {
        ...state,
        boq_name: action.payload.title,
      };
    case 'UPDATE_BOQ_DETAILS_VIEW':
      return { ...state, view: action.payload };
    case 'UPDATE_BOQ_PACKAGE_NAME':
      return { ...state, packageName: action.payload };
    default:
      return state;
  }
}

function boqCarpentryReducer(
  state = {
    furniture: [],
    cost: 0,
    finishes: [],
    rooms: [],
    carpentryItemFinishes: {},
    editCarpentryFormData: {
      description: '',
      dimensions: '',
      room: '',
      count: 0,
      finish: '',
    },
    displayCarpentryItem: false,
    displayAddCarpentryItem: false,
    selectedCarpentryId: null,
    checkedCarpentryIds: new Set([]),
  },
  action,
) {
  switch (action.type) {
    case FETCH_CARPENTRY_BOQ:
      return { ...state, ...action.payload };
    case SHOW_UNDO_DELETION_OF_FURNITURE: {
      const { furnitureId } = action;
      const furniture = state.furniture.filter(v => v.id !== furnitureId);
      return { ...state, furniture };
    }
    case UPDATE_CARPENTRY_LIST:
      return {
        ...state,
        furniture: action.payload,
      };
    case UPDATE_CARPENTRY_COST:
      return {
        ...state,
        furniture: action.payload,
      };
    case REMOVE_CARPENTRY_ITEM:
      return {
        ...state,
        furniture: action.payload,
      };
    case 'UPDATE_CARPENTRY_FORM':
      return {
        ...state,
        editCarpentryFormData: { ...state.editCarpentryFormData, ...action.payload },
      };
    case 'UPDATE_DISPLAY_CARPENTRY_ITEM':
      return {
        ...state,
        displayCarpentryItem: action.payload.displayCarpentryItem,
        selectedCarpentryId: action.payload.selectedCarpentryId,
      };
    case 'UPDATE_BOQ_CARPENTRY_STATE':
      return { ...state, ...action.payload };
    case 'UPDATE_BOQ_CARPENTRY_SELECTED_ITEMS':
      return { ...state, checkedCarpentryIds: action.payload };
    default:
      return state;
  }
}

function boqCarpentryItemReducer(
  state = {
    isEditMode: false,
    furniture: {
      images: [],
    },
    cost: 0,
    finishes: [],
    rooms: [],
    editCarpentryFormData: {
      description: '',
      dimensions: '',
      room: '',
      count: 1,
      finish: '',
      is_loose_furniture: false,
    },
    displayCostLoader: false,
  },
  action,
) {
  switch (action.type) {
    case FETCH_CARPENTRY_BOQ:
      return { ...state, ...action.payload };
    case FETCH_FINISH_FOR_DROPDOWN:
      return {
        ...state,
        finishes: action.payload.finishes,
      };
    case UPDATE_CARPENTRY_LIST:
      return {
        ...state,
        furniture: action.payload,
      };
    case UPDATE_CARPENTRY_COST:
      return {
        ...state,
        furniture: action.payload,
      };
    case REMOVE_CARPENTRY_ITEM:
      return {
        ...state,
        furniture: action.payload,
      };
    case FETCH_ROOMS_IN_BOQ:
      return {
        ...state,
        rooms: action.payload,
      };
    case FETCH_FINISHES_FOR_FURNITURE:
      // TODO: @vinay it is always undefined, why?
      return {
        ...state,
        finishes: get(action.payload, 'state.furniture.catalog_id.finishes', ''),
      };
    case 'UPDATE_BOQ_CARPENTRY_ITEM':
      return {
        ...state,
        furniture: { ...state.furniture, ...action.payload },
      };
    case 'UPDATE_CARPENTRY_FORM':
      return {
        ...state,
        editCarpentryFormData: { ...state.editCarpentryFormData, ...action.payload },
      };
    case 'UPDATE_DISPLAY_CARPENTRY_ITEM':
      return {
        ...state,
        displayCarpentryItem: action.payload,
      };
    case 'UPDATE_BOQ_CARPENTRY_EDIT_MODE':
      return {
        ...state,
        isEditMode: action.payload.display,
        editCarpentryFormData: action.payload.formData,
      };
    case 'UPDATE_BOQ_CARPENTRY_ITEM_FORM':
      return {
        ...state,
        editCarpentryFormData: { ...state.editCarpentryFormData, ...action.payload },
      };
    case UPDATE_BOQ_CARPENTRY_ITEM_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

function boqFalseCeilingReducer(
  state = {
    cost: 0,
    items: [],
  },
  action,
) {
  switch (action.type) {
    case FETCH_FALSE_CEILING_BOQ:
      return { ...state, ...action.payload };
    case UPDATE_FALSE_CEILING_BOQ:
      return { ...state };
    default:
      return { ...state };
  }
}

function boqPaintingReducer(
  state = {
    cost: 0,
    items: [],
  },
  action,
) {
  switch (action.type) {
    case FETCH_PAINTING_BOQ:
      return { ...state, ...action.payload };
    case UPDATE_PAINTING_BOQ:
      return { ...state };
    default:
      return { ...state };
  }
}

function boqUndetectedCarpentryReducer(
  state = {
    items: [],
    furnitureCatalog: [],
    finishes: [],
  },
  action,
) {
  switch (action.type) {
    case FETCH_UNDETECTED_CARPENTRY:
      return { ...state, ...action.payload };
    case UPDATE_UNDETECTED_CARPENTRY:
      return { ...state };
    case MIGRATE_UNDETECTED_CARPENTRY:
      return { ...state };
    default:
      return { ...state };
  }
}

function boqCivilReducer(
  state = {
    cost: 0,
    items: [],
    checkedCivilIds: new Set([]),
  },
  action,
) {
  switch (action.type) {
    case FETCH_CIVIL_BOQ:
      return { ...state, ...action.payload };
    case 'UPDATE_BOQ_CIVIL_SELECTED_ITEMS':
      return { ...state, checkedCivilIds: action.payload };
    default:
      return { ...state };
  }
}

function boqNewCarpentryItemReducer(
  state = {
    fetchEstimate: false,
    carpentryList: [],
    displayForm: false,
    furniture: {
      description: '',
      dimension: '',
      room: '',
      count: 1,
      finish: '',
      material: {
        cost: 0,
        percentage: 0,
      },
      labour: {
        cost: 0,
        percentage: 0,
      },
      cost: 0,
      available_finish: [],
    },
    cost: 0,
    finishes: [],
    rooms: [],
    formData: {
      id: '',
      title: '',
      dimension: '',
      room: '',
      count: 1,
      finish: '',
    },
    pagination: {
      total_records: 0,
      current_page: 1,
    },
    filter: {},
    displayCostLoader: false,
  },
  action,
) {
  switch (action.type) {
    case FETCH_CARPENTRY_LIST:
      return {
        ...state,
        carpentryList: action.payload.data,
        pagination: action.payload.meta,
      };
    case FETCH_ROOMS_IN_BOQ:
      return {
        ...state,
        rooms: action.payload,
      };
    case FETCH_FINISHES_FOR_FURNITURE:
      return {
        ...state,
        finishes: get(action.payload, 'state.furniture.catalog_id.finishes', ''),
      };
    case UPDATE_CARPENTRY_DATA_BY_CARPENTRY_ID: {
      const furniture = { ...action.payload };
      furniture.finish = first(
        furniture.available_finish.filter(
          finish => finish.label.toLowerCase() === furniture.finish.toLowerCase(),
        ),
      );
      // furniture.description = furniture.description.join().replace(
      //   '{finish}',
      //   furniture.available_finish[0].label,
      // );
      const formData = {
        ...furniture,
        title: furniture.label,
      };
      return {
        ...state,
        furniture,
        formData,
        fetchEstimate: true,
      };
    }
    case 'UPDATE_BOQ_CARPENTRY_ITEM_FORM':
      return {
        ...state,
        formData: { ...state.formData, ...action.payload },
        furniture: action.payload,
      };
    case UPDATE_BOQ_NEW_CARPENTRY_STATE:
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
}

function boqEditCarpentryItemReducer(
  state = {
    carpentryFinishMapper: {},
    rooms: [],
    carpentryFinish: [],
    imageUrl:
      'https://images.squarespace-cdn.com/content/v1/56f2595e8a65e2db95a7d983/1465996597543-VE3VTR8P152FITMK5E4L/ke17ZwdGBToddI8pDm48kFjHcQPPx8tXQaH6UuETvRtZw-zPPgdn4jUwVcJE1ZvWhcwhEtWJXoshNdA9f1qD7Xj1nVWs2aaTtWBneO2WM-ti7ho2c7eq_6BtcfXe5fq70OuevohFQNTkYDIolHHC0g/30a243e2d8df47b3d8c9224f44b27c26.jpg?format=750w',
    formData: {
      boqId: null,
      finish: '',
      quantity: 0,
      furniture_title: 'cushion chair',
      description: '',
      dimensions: {
        length: '0\'0"',
        width: '0\'0"',
        height: '0\'0"',
        room: '',
      },
    },
    displayCostLoader: false,
  },
  action,
) {
  switch (action.type) {
    case FETCH_ROOMS_IN_BOQ:
      return {
        ...state,
        rooms: action.payload,
      };
    case FETCH_FINISHES_FOR_FURNITURE:
      return {
        ...state,
        carpentryFinishMapper: action.payload,
      };
    case 'BOQ_EDIT_CARPENTRY_ITEM_UPDATE_FORM':
      return {
        ...state,
        formData: { ...state.formData, ...action.payload },
      };
    case 'BOQ_EDIT_CARPENTRY_ITEM_POPULATE_FINISH_FOR_CARPENTRY_ITEM':
      return {
        ...state,
        carpentryFinish: action.payload,
      };
    default:
      return { ...state };
  }
}

function boqNewCivilWorkItemReducer(
  state = {
    fetchEstimate: false,
    civilWorkList: [],
    displayForm: false,
    civilWork: {
      images: [],
      description: '',
      dimension: '',
      room: '',
      count: 1,
      finish: '',
      material: {
        cost: 0,
        percentage: 0,
      },
      labour: {
        cost: 0,
        percentage: 0,
      },
      cost: 0,
      available_finish: [],
    },
    cost: 0,
    rooms: [],
    formData: {
      id: '',
      title: '',
      description: [],
      dimension: '1\'0"L X 1\'0"W X 1\'0"H',
      room: '',
      count: 1,
      finish: '',
    },
    pagination: {
      total_records: 0,
      current_page: 1,
    },
    displayCostLoader: false,
  },
  action,
) {
  switch (action.type) {
    case 'FETCH_CIVIL_WORK_LIST':
      return {
        ...state,
        civilWorkList: action.payload.data,
        pagination: action.payload.meta,
      };
    case FETCH_ROOMS_IN_BOQ:
      return {
        ...state,
        rooms: action.payload,
      };
    // case 'FETCH_FINISHES_FOR_CIVIL_WORK':
    //   return {
    //     ...state,
    //     finishes: get(action.payload, 'state.furniture.catalog_id.finishes', ''),
    //   };
    case 'UPDATE_CIVIL_WORK_DATA_BY_CIVIL_ID': {
      const civilWork = { ...action.payload };
      civilWork.finish = first(
        civilWork.available_finish.filter(finish => finish.label === civilWork.finish),
      );
      const formData = {
        ...state.formData,
        ...civilWork,
        title: civilWork.label,
      };
      return {
        ...state,
        civilWork,
        formData,
        fetchEstimate: true,
      };
    }
    case 'UPDATE_BOQ_CIVIL_WORK_ITEM':
      return {
        ...state,
        civilWork: { ...state.civilWork, ...action.payload },
      };
    case 'UPDATE_BOQ_CIVIL_WORK_ITEM_FORM':
      return {
        ...state,
        formData: { ...state.formData, ...action.payload },
        civilWork: action.payload,
      };
    case 'UPDATE_BOQ_NEW_CIVIL_WORK_STATE':
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
}

function boqCivilItemReducer(
  state = {
    isEditMode: false,
    civilWork: {
      images: [],
    },
    cost: 0,
    rooms: [],
    editCivilFormData: {
      description: '',
      dimensions: '',
      room: '',
      count: 1,
      finish: '',
    },
    displayCostLoader: false,
  },
  action,
) {
  switch (action.type) {
    case FETCH_CIVIL_BOQ:
      return { ...state, ...action.payload };
    case 'UPDATE_BOQ_CIVIL_LIST':
      return {
        ...state,
        civilWork: action.payload,
      };
    case 'REMOVE_BOQ_CIVIL_ITEM':
      return {
        ...state,
        civilWork: action.payload,
      };
    case FETCH_ROOMS_IN_BOQ:
      return {
        ...state,
        rooms: action.payload,
      };
    case 'UPDATE_BOQ_CIVIL_ITEM':
      return {
        ...state,
        civilWork: { ...state.civilWork, ...action.payload },
      };
    case 'UPDATE_DISPLAY_CIVIL_ITEM':
      return {
        ...state,
        displayCivilItem: action.payload,
      };
    case 'UPDATE_BOQ_CIVIL_EDIT_MODE':
      return {
        ...state,
        isEditMode: action.payload.display,
        editCivilFormData: action.payload.formData,
      };
    case 'UPDATE_BOQ_CIVIL_ITEM_FORM':
      return {
        ...state,
        editCivilFormData: { ...state.editCivilFormData, ...action.payload },
      };
    case 'UPDATE_BOQ_CIVIL_ITEM_STATE':
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

function boqPaintingItemReducer(
  state = {
    isEditMode: false,
    paintingWork: {
      images: [],
    },
    cost: 0,
    rooms: [],
    editPaintingFormData: {
      description: '',
      dimensions: '',
      room: '',
      count: 1,
      finish: '',
      rate: 0,
    },
    displayCostLoader: false,
  },
  action,
) {
  switch (action.type) {
    case FETCH_PAINTING_BOQ:
      return { ...state, ...action.payload };
    case 'UPDATE_BOQ_PAINTING_LIST':
      return {
        ...state,
        paintingWork: action.payload,
      };
    case 'REMOVE_BOQ_PAINTING_ITEM':
      return {
        ...state,
        paintingWork: action.payload,
      };
    case FETCH_ROOMS_IN_BOQ:
      return {
        ...state,
        rooms: action.payload,
      };
    case 'UPDATE_BOQ_PAINTING_ITEM':
      return {
        ...state,
        paintingWork: { ...state.paintingWork, ...action.payload },
      };
    case 'UPDATE_DISPLAY_PAINTING_ITEM':
      return {
        ...state,
        displayPaintingItem: action.payload,
      };
    case 'UPDATE_BOQ_PAINTING_EDIT_MODE':
      return {
        ...state,
        isEditMode: action.payload.display,
        editPaintingFormData: action.payload.formData,
      };
    case 'UPDATE_BOQ_PAINTING_ITEM_FORM':
      return {
        ...state,
        editPaintingFormData: { ...state.editPaintingFormData, ...action.payload },
      };
    case 'UPDATE_BOQ_PAINTING_ITEM_STATE':
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

function boqElectricalReducer(
  state = {
    cost: 0,
    items: [],
  },
  action,
) {
  switch (action.type) {
    case FETCH_ELECTRICAL_BOQ:
      return { ...state, ...action.payload };
    case UPDATE_ELECTRICAL_BOQ:
      return { ...state };
    default:
      return { ...state };
  }
}

function boqElectricalItemReducer(
  state = {
    isEditMode: false,
    electricalWork: {
      images: [],
    },
    cost: 0,
    rooms: [],
    editElectricalFormData: {
      description: '',
      dimensions: '',
      room: '',
      count: 1,
      finish: '',
      rate: 0,
    },
    displayCostLoader: false,
  },
  action,
) {
  switch (action.type) {
    case FETCH_ELECTRICAL_BOQ:
      return { ...state, ...action.payload };
    case 'UPDATE_BOQ_ELECTRICAL_LIST':
      return {
        ...state,
        electricalWork: action.payload,
      };
    case 'REMOVE_BOQ_ELECTRICAL_ITEM':
      return {
        ...state,
        electricalWork: action.payload,
      };
    case FETCH_ROOMS_IN_BOQ:
      return {
        ...state,
        rooms: action.payload,
      };
    case 'UPDATE_BOQ_ELECTRICAL_ITEM':
      return {
        ...state,
        electricalWork: { ...state.electricalWork, ...action.payload },
      };
    case 'UPDATE_BOQ_ELECTRICAL_EDIT_MODE':
      return {
        ...state,
        isEditMode: action.payload.display,
        editElectricalFormData: action.payload.formData,
      };
    case 'UPDATE_BOQ_ELECTRICAL_ITEM_FORM':
      return {
        ...state,
        editElectricalFormData: { ...state.editElectricalFormData, ...action.payload },
      };
    case 'UPDATE_BOQ_ELECTRICAL_ITEM_STATE':
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

function boqFalseCeilingItemReducer(
  state = {
    isEditMode: false,
    falseCeilingWork: {
      images: [],
    },
    cost: 0,
    rooms: [],
    editFalseCeilingFormData: {
      description: '',
      dimensions: '',
      room: '',
      count: 1,
      finish: '',
      rate: 0,
    },
    displayCostLoader: false,
  },
  action,
) {
  switch (action.type) {
    case FETCH_FALSE_CEILING_BOQ:
      return { ...state, ...action.payload };
    case 'UPDATE_BOQ_FALSE_CEILING_LIST':
      return {
        ...state,
        falseCeilingWork: action.payload,
      };
    case 'REMOVE_BOQ_FALSE_CEILING_ITEM':
      return {
        ...state,
        falseCeilingWork: action.payload,
      };
    case FETCH_ROOMS_IN_BOQ:
      return {
        ...state,
        rooms: action.payload,
      };
    case 'UPDATE_BOQ_FALSE_CEILING_ITEM':
      return {
        ...state,
        falseCeilingWork: { ...state.falseCeilingWork, ...action.payload },
      };
    case 'UPDATE_BOQ_FALSE_CEILING_EDIT_MODE':
      return {
        ...state,
        isEditMode: action.payload.display,
        editFalseCeilingFormData: action.payload.formData,
      };
    case 'UPDATE_BOQ_FALSE_CEILING_ITEM_FORM':
      return {
        ...state,
        editFalseCeilingFormData: { ...state.editFalseCeilingFormData, ...action.payload },
      };
    case 'UPDATE_BOQ_FALSE_CEILING_ITEM_STATE':
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

function boqSoftFurnishingReducer(
  state = {
    cost: 0,
    items: [],
  },
  action,
) {
  switch (action.type) {
    case 'FETCH_SOFT_FURNISHING_BOQ':
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
}

function boqSoftFurnishingItemReducer(
  state = {
    isEditMode: false,
    softFurnishingWork: {
      images: [],
    },
    cost: 0,
    rooms: [],
    editSoftFurnishingFormData: {
      description: '',
      room: '',
      percentage: '',
      rate: 0,
    },
    displayCostLoader: false,
  },
  action,
) {
  switch (action.type) {
    case 'FETCH_SOFT_FURNISHING_BOQ':
      return { ...state, ...action.payload };
    case 'UPDATE_BOQ_SOFT_FURNISHING_LIST':
      return {
        ...state,
        softFurnishingWork: action.payload,
      };
    case 'REMOVE_BOQ_SOFT_FURNISHING_ITEM':
      return {
        ...state,
        softFurnishingWork: action.payload,
      };
    case FETCH_ROOMS_IN_BOQ:
      return {
        ...state,
        rooms: action.payload,
      };
    case 'UPDATE_BOQ_SOFT_FURNISHING_ITEM':
      return {
        ...state,
        softFurnishingWork: { ...state.softFurnishingWork, ...action.payload },
      };
    case 'UPDATE_BOQ_SOFT_FURNISHING_EDIT_MODE':
      return {
        ...state,
        isEditMode: action.payload.display,
        editSoftFurnishingFormData: action.payload.formData,
      };
    case 'UPDATE_BOQ_SOFT_FURNISHING_ITEM_FORM':
      return {
        ...state,
        editSoftFurnishingFormData: { ...state.editSoftFurnishingFormData, ...action.payload },
      };
    case 'UPDATE_BOQ_SOFT_FURNISHING_ITEM_STATE':
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

function boqReducer(
  state = {
    boqs: [],
    pagination: {
      total_records: 0,
      current_page: 1,
    },
  },
  action,
) {
  switch (action.type) {
    case 'FETCH_BOQ_LIST':
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

export {
  boqDetailReducer,
  boqCarpentryReducer,
  boqCarpentryItemReducer,
  boqFalseCeilingReducer,
  boqPaintingReducer,
  boqElectricalReducer,
  boqUndetectedCarpentryReducer,
  boqCivilReducer,
  boqNewCarpentryItemReducer,
  boqEditCarpentryItemReducer,
  boqNewCivilWorkItemReducer,
  boqCivilItemReducer,
  boqPaintingItemReducer,
  boqElectricalItemReducer,
  boqFalseCeilingItemReducer,
  boqSoftFurnishingReducer,
  boqSoftFurnishingItemReducer,
  boqReducer,
};
