import { SHOW_UNDO_DELETION_OF_FURNITURE } from 'components/BoqDetail/FurnitureDetails/Constants';

const initState = {
  undoFurnitureDeletion: {
    show: true,
    data: null,
  },
  updateTitle: {
    show: true,
    data: {},
  },
  addFurniture: {
    show: false,
    data: {},
  },
  addPattern: {
    show: false,
    data: {},
  },
  vendorCatalog: {
    show: false,
    data: {},
  },
  bulkEdit: {
    show: false,
    data: {},
  },
  requestSample: {
    show: false,
    data: {},
  },
  contactVendor: {
    show: false,
    data: {},
  },
  viewFurniture: {
    show: false,
    data: {},
  },
  loginModal: {
    show: false,
    data: {},
  },
  carousel: {
    show: false,
    data: {},
  },
  confirmationModal: {
    show: false,
    data: {},
  },
  viewCivil: {
    show: false,
    data: {},
  },
  addCivil: {
    show: false,
    data: {},
  },
  viewPainting: {
    show: false,
    data: {},
  },
  viewElectrical: {
    show: false,
    data: {},
  },
  viewFalseCeiling: {
    show: false,
    data: {},
  },
  viewSoftFurnishing: {
    show: false,
    data: {},
  },
  viewCostSummary: {
    show: false,
    data: {},
  },
  viewAssumption: {
    show: false,
    data: {},
  },
  toast: {
    show: false,
    data: {},
  },
  viewBoqInvitationForm: {
    show: false,
    data: {},
  },
  viewScheduleGraph: {
    show: false,
    data: {},
  },
  viewConsultWithUs: {
    show: false,
    data: {},
  },
  viewProductDemo: {
    show: false,
  },
  viewEditRatePopover: {
    data: {
      id: '',
      handleClose: () => {},
      anchorEl: null,
      formObj: {},
      currentRate: 0,
      onRateUpdate: () => {},
      onCancel: () => {},
    },
  },
  viewDesignFeeForm: {
    show: false,
    data: {
      formData: {
        boqId: null,
        value: 0,
        attribute: null,
      },
      callback: () => {},
    },
  },
  viewConfirmationPopup: {
    show: false,
    data: {
      onConfirm: () => {},
      onCancel: () => {},
    },
  },
  viewProfileSetup: {
    show: false,
    data: {},
  },
};

function modalReducer(state = initState, action) {
  switch (action.type) {
    case SHOW_UNDO_DELETION_OF_FURNITURE: {
      return {
        ...state,
        undoFurnitureDeletion: {
          show: true,
          data: action.payload,
        },
      };
    }
    case 'SHOW_UPDATE_TITLE':
      return {
        ...state,
        updateTitle: {
          show: true,
          data: action.payload,
        },
      };
    case 'HIDE_UPDATE_TITLE':
      return {
        ...state,
        updateTitle: {
          show: false,
          data: action.payload,
        },
      };
    case 'SHOW_ADD_FURNITURE':
      return {
        ...state,
        addFurniture: {
          show: true,
          data: action.payload,
        },
      };
    case 'HIDE_ADD_FURNITURE':
      return {
        ...state,
        addFurniture: {
          show: false,
          data: action.payload,
        },
      };
    case 'SHOW_BULK_EDIT':
      return {
        ...state,
        bulkEdit: {
          show: true,
          data: action.payload,
        },
      };
    case 'HIDE_BULK_EDIT':
      return {
        ...state,
        bulkEdit: {
          show: false,
          data: action.payload,
        },
      };
    case 'SHOW_REQUEST_SAMPLE':
      return {
        ...state,
        requestSample: {
          show: true,
          data: action.payload,
        },
      };
    case 'HIDE_REQUEST_SAMPLE':
      return {
        ...state,
        requestSample: {
          show: false,
          data: action.payload,
        },
      };
    case 'SHOW_CONTACT_VENDOR':
      return {
        ...state,
        contactVendor: {
          show: true,
          data: action.payload,
        },
      };
    case 'HIDE_CONTACT_VENDOR':
      return {
        ...state,
        contactVendor: {
          show: false,
          data: action.payload,
        },
      };
    case 'SHOW_ADD_PATTERN':
      return {
        ...state,
        addPattern: {
          show: true,
          data: action.payload,
        },
      };
    case 'HIDE_ADD_PATTERN':
      return {
        ...state,
        addPattern: {
          show: false,
          data: action.payload,
        },
      };
    case 'SHOW_ADD_VENDOR_CATALOG':
      return {
        ...state,
        vendorCatalog: {
          show: true,
          data: action.payload,
        },
      };
    case 'HIDE_ADD_VENDOR_CATALOG':
      return {
        ...state,
        vendorCatalog: {
          show: false,
          data: action.payload,
        },
      };
    case 'SHOW_VIEW_FURNITURE':
      return {
        ...state,
        viewFurniture: {
          show: true,
          data: action.payload,
        },
      };
    case 'HIDE_VIEW_FURNITURE':
      return {
        ...state,
        viewFurniture: {
          show: false,
        },
      };
    case 'SHOW_CAROUSAL':
      return {
        ...state,
        carousal: {
          show: true,
          data: action.payload,
        },
      };
    case 'HIDE_CAROUSAL':
      return {
        ...state,
        carousal: {
          show: false,
        },
      };
    case 'SHOW_LOGIN_MODAL':
      return {
        ...state,
        loginModal: {
          show: true,
          data: action.payload,
        },
      };
    case 'HIDE_LOGIN_MODAL':
      return {
        ...state,
        loginModal: {
          show: false,
        },
      };
    case 'SHOW_CONFIRMATION_MODAL':
      return {
        ...state,
        confirmationModal: {
          show: true,
          data: action.payload,
        },
      };
    case 'HIDE_CONFIRMATION_MODAL':
      return {
        ...state,
        confirmationModal: {
          show: false,
          data: {},
        },
      };
    case 'SHOW_ADD_CIVIL_WORK':
      return {
        ...state,
        addCivilWork: {
          show: true,
          data: action.payload,
        },
      };
    case 'HIDE_ADD_CIVIL_WORK':
      return {
        ...state,
        addCivilWork: {
          show: false,
          data: action.payload,
        },
      };
    case 'SHOW_VIEW_CIVIL':
      return {
        ...state,
        viewCivil: {
          show: true,
          data: action.payload,
        },
      };
    case 'HIDE_VIEW_CIVIL':
      return {
        ...state,
        viewCivil: {
          show: false,
        },
      };
    case 'SHOW_VIEW_BOQ_PAINTING':
      return {
        ...state,
        viewPainting: {
          show: true,
          data: action.payload,
        },
      };
    case 'HIDE_VIEW_BOQ_PAINTING':
      return {
        ...state,
        viewPainting: {
          show: false,
        },
      };
    case 'SHOW_VIEW_BOQ_ELECTRICAL':
      return {
        ...state,
        viewElectrical: {
          show: true,
          data: action.payload,
        },
      };
    case 'HIDE_VIEW_BOQ_ELECTRICAL':
      return {
        ...state,
        viewElectrical: {
          show: false,
        },
      };
    case 'SHOW_VIEW_BOQ_FALSE_CEILING':
      return {
        ...state,
        viewFalseCeiling: {
          show: true,
          data: action.payload,
        },
      };
    case 'HIDE_VIEW_BOQ_FALSE_CEILING':
      return {
        ...state,
        viewFalseCeiling: {
          show: false,
        },
      };
    case 'SHOW_VIEW_BOQ_SOFT_FURNISHING':
      return {
        ...state,
        viewSoftFurnishing: {
          show: true,
          data: action.payload,
        },
      };
    case 'HIDE_VIEW_BOQ_SOFT_FURNISHING':
      return {
        ...state,
        viewSoftFurnishing: {
          show: false,
        },
      };
    case 'DISPLAY_TOAST':
      return {
        ...state,
        toast: {
          show: true,
          data: action.payload,
        },
      };
    case 'HIDE_TOAST':
      return {
        ...state,
        toast: {
          ...state.toast,
          show: false,
        },
      };
    case 'SHOW_COST_SUMMARY':
      return {
        ...state,
        viewCostSummary: {
          show: true,
          data: action.payload,
        },
      };
    case 'HIDE_COST_SUMMARY':
      return {
        ...state,
        viewCostSummary: {
          show: false,
        },
      };
    case 'SHOW_ASSUMPTION':
      return {
        ...state,
        viewAssumption: {
          show: true,
          data: action.payload,
        },
      };
    case 'HIDE_ASSUMPTION':
      return {
        ...state,
        viewAssumption: {
          show: false,
        },
      };

    case 'SHOW_BOQ_INVITATION_FORM':
      return {
        ...state,
        viewBoqInvitationForm: {
          show: true,
          data: action.payload,
        },
      };
    case 'HIDE_BOQ_INVITATION_FORM':
      return {
        ...state,
        viewBoqInvitationForm: {
          show: false,
          data: action.payload,
        },
      };
    case 'SHOW_SCHEDULE_GRAPH':
      return {
        ...state,
        viewScheduleGraph: {
          show: true,
          data: action.payload,
        },
      };
    case 'HIDE_SCHEDULE_GRAPH':
      return {
        ...state,
        viewScheduleGraph: {
          ...state.viewScheduleGraph,
          show: false,
        },
      };
    case 'VIEW_CONSULT_WITH_US':
      return {
        ...state,
        viewConsultWithUs: {
          show: true,
        },
      };
    case 'HIDE_CONSULT_WITH_US':
      return {
        ...state,
        viewConsultWithUs: {
          show: false,
        },
      };
    case 'VIEW_PRODUCT_DEMO':
      return {
        ...state,
        viewProductDemo: {
          show: true,
        },
      };
    case 'HIDE_PRODUCT_DEMO':
      return {
        ...state,
        viewProductDemo: {
          show: false,
        },
      };
    case 'VIEW_EDIT_RATE_POPOVER':
      return {
        ...state,
        viewEditRatePopover: {
          data: { ...state.viewEditRatePopover.data, ...action.payload },
        },
      };
    case 'VIEW_DESIGN_FEE_FORM':
      return {
        ...state,
        viewDesignFeeForm: {
          show: true,
          data: { ...action.payload },
        },
      };
    case 'HIDE_DESIGN_FEE_FORM':
      return {
        ...state,
        viewDesignFeeForm: {
          ...state.viewDesignFeeForm,
          show: false,
        },
      };
    case 'VIEW_CONFIRMATION_POPUP':
      return {
        ...state,
        viewConfirmationPopup: {
          show: true,
          data: { ...state.viewConfirmationPopup.data, ...action.payload },
        },
      };
    case 'HIDE_CONFIRMATION_POPUP':
      return {
        ...state,
        viewConfirmationPopup: {
          ...state.viewConfirmationPopup,
          show: false,
        },
      };
    case 'SHOW_PROFILE_SETUP_POPUP':
      return {
        ...state,
        viewProfileSetup: { ...state.viewProfileSetup, show: true },
      };
    case 'HIDE_PROFILE_SETUP_POPUP':
      return {
        ...state,
        viewProfileSetup: { ...state.viewProfileSetup, show: false },
      };
    default:
      return state;
  }
}

export { modalReducer };
