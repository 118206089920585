import React, { useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { useSelector } from 'react-redux';
import { func, shape, number } from 'prop-types';

const SideBar = ({ searchQuery, onChangeSearchQuery }) => {
  const [category, setCategory] = useState(null);

  const { finishes = [], vendors = [], brands = [] } = useSelector(state => state.vendor);

  function categoryChange(value) {
    setCategory(value);
  }

  function renderChildren(item) {
    const patterns = item?.patterns ?? [];
    return patterns.map(pattern => {
      const patternName = pattern.name;
      return (
        <li className="checkbox-container">
          <label className="tw-text-gray-600 tw-px-2" htmlFor={`${item.name}-${patternName}`}>
            {patternName}
            <input
              type="checkbox"
              name="subcategories[]"
              id={`${item.name}-${patternName}`}
              onChange={e => {
                const { checked } = e.target;
                const existing = searchQuery.pattern;
                if (!checked && existing.has(pattern._id)) {
                  existing.delete(pattern._id);
                } else {
                  existing.add(pattern._id);
                }

                onChangeSearchQuery({
                  ...searchQuery,
                  pattern: existing,
                });
              }}
            />
            <span className="checkmark" />
          </label>
        </li>
      );
    });
  }

  return (
    <div className="tw-w-1/5 tw-pr-4 tw-mt-12">
      <Accordion>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Categories</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <ul>
              {finishes.map(item => (
                <li key={item.slug}>
                  <input
                    type="radio"
                    name="category"
                    id={item.name}
                    onChange={() => categoryChange(item.name)}
                  />
                  <label className="tw-text-gray-600 tw-px-2" htmlFor={item.name}>
                    {item.name}
                  </label>
                  {item.name === category && <ul className="tw-pl-4">{renderChildren(item)}</ul>}
                </li>
              ))}
            </ul>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Vendors</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <ul>
              {vendors.map(vendor => {
                const { name: vendorName, slug } = vendor;
                return (
                  <li key={slug}>
                    <input
                      type="radio"
                      name="vendor"
                      id={slug}
                      onChange={() => {
                        onChangeSearchQuery({
                          ...searchQuery,
                          vendor: new Set().add(vendor._id),
                        });
                      }}
                    />
                    <label className="tw-text-gray-600 tw-px-2" htmlFor={slug}>
                      {vendorName}
                    </label>
                  </li>
                );
              })}
            </ul>
          </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
          <AccordionItemHeading>
            <AccordionItemButton>Brands</AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <ul>
              {brands.map(brand => {
                const { name: brandName, slug } = brand;
                return (
                  <li key={slug}>
                    <input
                      type="radio"
                      name="brand"
                      id={slug}
                      onChange={() => {
                        onChangeSearchQuery({
                          ...searchQuery,
                          brand: new Set().add(brand._id),
                        });
                      }}
                    />
                    <label className="tw-text-gray-600 tw-px-2" htmlFor={slug}>
                      {brandName}
                    </label>
                  </li>
                );
              })}
            </ul>
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

SideBar.propTypes = {
  onChangeSearchQuery: func.isRequired,
  searchQuery: shape({
    vendor: new Set(),
    pattern: new Set(),
    brand: new Set(),
    page: number,
  }).isRequired,
};

export { SideBar };
