import React from 'react';
import ImageUploading from 'react-images-uploading';
import { Link } from 'react-router-dom';
import { BrowserView } from 'react-device-detect';

const fields = {
  name: '',
  email: '',
  details: '',
  solution: '',
  image: '',
};

const Report = () => {
  const [params, setParams] = React.useState(fields);
  const [images, setImages] = React.useState([]);
  const maxNumber = 69;

  const onChange = imageList => setImages(imageList);

  function handleChange(e) {
    const { name, value } = e.target;
    const oldParams = { ...params };
    oldParams[name] = value;
    setParams(oldParams);
  }

  function handleSubmit() {
    // Handles form submission
  }

  return (
    <>
      <div className="tw-container tw-mx-auto">
        <div className="tw-bg-white tw-shadow-xl tw-m-8 tw-p-4 tw-rounded-lg">
          <div className="tw-flex tw-flex-col lg:tw-flex-row">
            <div className="tw-w-full lg:tw-w-1/2 tw-relative">
              <ul className="tw-flex tw-flex-row">
                <li className="tw-text-text2 tw-mr-2">
                  <Link to="/">Home</Link>
                </li>
                <li className="tw-text-text2 tw-mr-2">/</li>
                <li className="tw-text-text2 tw-mr-2 tw-text-opacity-50">Report Bug</li>
              </ul>
              <h2
                className="tw-text-text2 tw-font-bold"
                style={{ fontSize: '2rem', margin: '1em 0' }}
              >
                Submit a bug
              </h2>
              <p className="tw-text-text2 tw-w-full lg:tw-w-8/12">
                To ensure that we are able to quickly address and resolve issues that you&apos;re
                encountering we are requesting you to fill up the form here.
              </p>
              <BrowserView>
                <img
                  src="/pattern.svg"
                  alt="pattern"
                  className="tw-hidden lg:tw-w-32 lg:tw-absolute lg:tw-bottom-0 lg:tw-left-0 lg:tw-block"
                />
              </BrowserView>
            </div>
            <div className="tw-w-full lg:tw-w-1/2 lg:tw-border-l lg:tw-pl-4 tw-border-gray-200">
              <form onSubmit={e => handleSubmit(e)}>
                <div className="tw-mb-4">
                  <label htmlFor="name">
                    Name<em>*</em>
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={params.name}
                    onChange={e => handleChange(e)}
                    id="name"
                    className="tw-w-full tw-border tw-p-2"
                    required
                  />
                </div>
                <div className="tw-mb-4">
                  <label htmlFor="email">
                    Email Address<em>*</em>
                  </label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    value={params.email}
                    onChange={e => handleChange(e)}
                    className="tw-w-full tw-border tw-p-2"
                    required
                  />
                </div>
                <div className="tw-mb-4">
                  <label htmlFor="details">
                    Sample Details<em>*</em>
                  </label>
                  <textarea
                    name="details"
                    id="details"
                    value={params.details}
                    onChange={e => handleChange(e)}
                    className="tw-w-full tw-border tw-p-2"
                    required
                  />
                </div>
                <div className="tw-mb-4">
                  <label htmlFor="details">
                    Do you have a solution?<em>*</em>
                  </label>
                  <div>
                    <label htmlFor="solution-yes" className="tw-mr-8">
                      <input
                        type="radio"
                        name="solution"
                        id="solution-yes"
                        value={params.solution}
                        onChange={e => handleChange(e)}
                        defaultValue="1"
                        required
                      />{' '}
                      Yes
                    </label>
                    <label htmlFor="solution-no">
                      <input
                        type="radio"
                        name="solution"
                        id="solution-no"
                        value={params.solution}
                        onChange={e => handleChange(e)}
                        defaultValue="0"
                        required
                      />{' '}
                      No
                    </label>
                  </div>
                </div>
                <div className="tw-mb-4">
                  <label htmlFor="details">
                    Upload Images (Screenshots of the affected area)<em>*</em>
                  </label>
                  <ImageUploading
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                  >
                    {({ imageList, onImageUpload, onImageRemove, isDragging, dragProps }) => (
                      // write your building UI
                      <div className="upload__image-wrapper">
                        <div className="tw-p-4 tw-text-center tw-border">
                          <button
                            style={isDragging ? { color: 'red' } : undefined}
                            onClick={onImageUpload}
                            type="button"
                            {...dragProps}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="tw-h-6 tw-w-6 tw-inline-block tw-mr-2"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                              strokeWidth={2}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                              />
                            </svg>{' '}
                            Click or Drop here
                          </button>
                          &nbsp;
                          {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
                        </div>
                        {imageList.map((image, index) => (
                          <div
                            key={image._id}
                            className="image-item tw-relative tw-inline-block tw-mt-4"
                          >
                            <img src={image.data_url} alt="" width="100" />
                            <div className="image-item__btn-wrapper">
                              <button
                                onClick={() => onImageRemove(index)}
                                type="button"
                                className="tw-absolute tw-top-0 tw-right-0 tw-z-50 tw-text-text2"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="tw-h-5 tw-w-5"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </ImageUploading>
                </div>
                <div>
                  <button
                    type="button"
                    className="tw-bg-cta tw-text-white tw-w-full tw-text-center tw-rounded-lg tw-p-4 tw-mt-4"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Report;
