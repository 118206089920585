import { useEffect, useState } from 'react';

const NON_ADMIN_REDIRECT_URL = '/vendors/search';

const useCollapsable = ({ collection, adminRedirectUrl }) => {
  const [isCollapsedView, setIsCollapsedView] = useState(true);
  const [items, setItems] = useState([]);
  const count = collection.length;

  useEffect(() => {
    if (isCollapsedView) {
      const subset = collection.slice(0, 11);
      // show view more only if the items are > 12
      if (collection.length > 12) {
        subset.push({
          type: 'viewMore',
          slug: 'viewMore',
        });
      }
      setItems(subset);
    } else {
      setItems(collection);
    }
  }, [isCollapsedView, collection]);

  const onItemClick = ({ slug, isAdmin }) => {
    if (!slug) {
      return;
    }
    if (slug === 'viewMore') {
      setIsCollapsedView(false);
      return;
    }
    window.location = isAdmin ? adminRedirectUrl.replace(':slug', slug) : NON_ADMIN_REDIRECT_URL;
  };

  return {
    count,
    items,
    setIsCollapsedView,
    onItemClick,
  };
};

export { useCollapsable };
