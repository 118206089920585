import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { ChooseType } from './ChooseType';
import UserContext from '../../../UserContext';
import { doPatch, setToken } from '../../../utils';

import './profile-setup.scss';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function ProfileSetup() {
  const dispatch = useDispatch();
  const query = useQuery();
  const { setUserRole } = useContext(UserContext);
  const [userType, setUserType] = useState(null);

  const submitForm = async role => {
    try {
      const body = { role };
      const {
        data: { user, token },
      } = await doPatch({ url: '/me', body });
      setUserRole(user.role);
      setToken(token);
      const redirectPathName = query.get('redirect') ?? '/dashboard';
      dispatch({ type: 'HIDE_PROFILE_SETUP_POPUP' });
      window.location.href = redirectPathName;
    } catch (e) {
      dispatch({
        type: 'DISPLAY_TOAST',
        payload: { type: 'error', message: e },
      });
    }
  };

  return (
    <div className="profile-setup h-min-50vh">
      <div className="tw-w-full">
        <div className="tw-w-full tw-bg-white tw-flex tw-justify-center tw-items-center tw-h-full">
          <ChooseType
            userType={userType}
            onSubmit={async role => {
              setUserType(role);
              await submitForm(role);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export { ProfileSetup };
