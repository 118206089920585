import React from 'react';
import { bool, shape } from 'prop-types';

import classNames from 'classnames';

import './loaderCard.scss';

const LoaderCard = ({ loadLogo, style }) => {
  return (
    <div className={classNames('loading-container', loadLogo ? 'logo' : '')} style={{ ...style }} />
  );
};

LoaderCard.defaultProps = {
  loadLogo: false,
  style: {},
};

LoaderCard.propTypes = {
  loadLogo: bool,
  style: shape({}),
};

export { LoaderCard };
