import Rating from '@material-ui/lab/Rating';
import { number, string } from 'prop-types';
import React from 'react';

const VendorCard = ({ name, rating, reviewCount }) => {
  return (
    <>
      <span className="upper-case-text text-grey vendor-title-pad">{name}</span>
      <span className="text-grey rating-container">
        <Rating name="read-only" value={rating} readOnly size="small" />
        {Boolean(reviewCount) && <span className="rating-legend">({reviewCount} reviews)</span>}
      </span>
    </>
  );
};

VendorCard.defaultProps = {
  rating: null,
  reviewCount: null,
};

VendorCard.propTypes = {
  name: string.isRequired,
  rating: number,
  reviewCount: number,
};

export { VendorCard };
