import querystring from 'querystring';
import { doGet } from '../utils';

class CivilCatalogAction {
  static fetchAll(obj = {}) {
    const { page = 1, limit = 10, queryString = {}, civilList = [] } = obj;
    return async dispatch => {
      try {
        const qs = querystring.stringify({
          page,
          limit,
          ...queryString,
        });
        const { meta, data } = await doGet({ url: `catalog/civil?${qs}` });
        dispatch({
          type: 'FETCH_CIVIL_WORK_LIST',
          payload: { meta, data: [...civilList, ...data] },
        });
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static fetchById({ id, finish }) {
    return async dispatch => {
      try {
        const { data } = await doGet({ url: `catalog/civil/${id}` });
        data.finish = finish;
        dispatch({
          type: 'UPDATE_CIVIL_WORK_DATA_BY_CIVIL_ID',
          payload: data,
        });
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }
}

export { CivilCatalogAction };
