import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { doGet, doPost } from 'utils';

const useNetwork = ({ defaultIsLoading = false, ...others } = {}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(defaultIsLoading);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const makeCall = ({ method = 'GET', url, body, headers, hideToast }) => {
    setIsLoading(true);
    let httpReq;
    if (method === 'GET') {
      httpReq = doGet({ url });
    } else {
      httpReq = doPost({
        url,
        body,
        headers,
      });
    }

    httpReq
      .then(responseData => setData(responseData))
      .catch(err => {
        setError(err);
        if (!hideToast) {
          dispatch({
            type: 'DISPLAY_TOAST',
            payload: { type: 'error', message: err.message },
          });
        }
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (others.url) {
      makeCall(others);
    }
  }, []);

  const doPostCall = options => makeCall({ method: 'POST', ...options });
  const doGetCall = options => makeCall({ method: 'GET', ...options });

  return {
    isLoading,
    error,
    data,
    doPostCall,
    doGetCall,
  };
};

export { useNetwork };
