import React, { useEffect, useState } from 'react';

import { SideBar } from './SideBar';
import { Offers } from './Offers';
import { VendorCatalogList } from './VendorCatalogList';
import { ContactVendorModal } from './ContactVendorModal';
import { RequestSampleModal } from './RequestSampleModal';
import { Paginator } from './Paginator';

import { doGet } from '../../../utils';
import { Loader } from '../../Loader';

import 'react-accessible-accordion/dist/fancy-example.css';
import '../vendors.scss';

const CatalogSearch = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [showOffer, setShowOffer] = useState(false);
  const [searchResult, setSearchResult] = useState({
    catalog: [],
    totalCount: 0,
    resultSetCount: 0,
  });

  const isEmpty = searchResult.catalog.length === 0;

  const [searchQuery, setSearchQuery] = useState({
    vendor: new Set(),
    pattern: new Set(),
    brand: new Set(),
    page: 0,
  });

  const getValue = (query, forAll = false) => {
    if (forAll) {
      return encodeURIComponent(JSON.stringify(Array.from(query)));
    }
    return Array.from(query)[0];
  };

  const fetchCatalog = async query => {
    setIsLoading(true);
    const data = await doGet({
      url: '/vendor/vendor/catalog',
      params: {
        vendor: getValue(query.vendor),
        pattern: getValue(query.pattern, true),
        brand: getValue(query.brand),
        page: query.page,
      },
    });
    setSearchResult({
      catalog: data.catalog,
      resultSetCount: data.result_set_count,
      currentPage: data.current_page,
      totalCount: data.total_count,
      totalPages: data.total_pages,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    fetchCatalog(searchQuery);
  }, [searchQuery]);

  const toggleOffer = () => {
    setShowOffer(!showOffer);
  };

  return (
    <div className="tw-px-6 tw-py-4 tw-relative" style={{ margin: '-3%' }}>
      <Offers showOffer={showOffer} onClick={toggleOffer} />
      <ContactVendorModal />
      <RequestSampleModal />
      <div className="tw-flex tw-flex-row">
        <SideBar searchQuery={searchQuery} onChangeSearchQuery={setSearchQuery} />
        <div className="tw-w-4/5">
          <div className="tw-flex tw-justify-between tw-mb-4">
            <h3 className="tw-text-lg tw-text-gray-700">
              {!isEmpty && (
                <span>
                  Showing {searchResult.resultSetCount} of {searchResult.totalCount}
                </span>
              )}
            </h3>
            <span className="tw-font-semibold tw-text-cta tw-cursor-pointer" onClick={toggleOffer}>
              EXPLORE OFFERS
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="tw-h-4 tw-w-4 tw-inline-block"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
              </svg>
            </span>
          </div>
          <Loader loading={isLoading} />
          {!isLoading && <VendorCatalogList catalog={searchResult.catalog} />}
          <Paginator
            totalCount={searchResult.totalCount}
            totalPages={searchResult.totalPages}
            currentPage={searchResult.currentPage}
            onItemClick={pageNumber => {
              setSearchQuery({
                ...searchQuery,
                page: pageNumber,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export { CatalogSearch };
