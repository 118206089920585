import React from 'react';
import { func, string } from 'prop-types';

import DesignerIcon from 'assets/account-type-selector/designer.svg';
import HomeOwnerIcon from 'assets/account-type-selector/home-owner.svg';

function ChooseType({ userType, onSubmit }) {
  return (
    <div className="choose-profile-div tw-items-center tw-flex-col tw-flex tw-w-full h-min-50vh">
      <h4 className="tw-font-bold tw-text-black">Tell us about yourself. You are</h4>
      <p>(This helps us customise the tool for you)</p>
      <div className="tw-flex tw-gap-2 user-type-selection tw-w-full tw-mt-2 tw-justify-center tw-items-center">
        <div
          onClick={() => {
            onSubmit('home-owner');
          }}
          className={`tw-cursor-pointer tw-border tw-rounded tw-p-4 tw-shadow tw-items-center tw-flex-col tw-flex  tw-w-1/2 ${
            userType === 'home-owner' ? 'selected' : ''
          }`}
        >
          <img src={HomeOwnerIcon} alt="home-owner-icon" />
          <h7 className="tw-text-black tw-font-semibold">Home Owner</h7>
        </div>
        <div
          onClick={() => {
            onSubmit('designer');
          }}
          className={`tw-cursor-pointer tw-border tw-rounded tw-p-4 tw-shadow tw-items-center tw-flex-col tw-flex  tw-w-1/2 ${
            userType === 'designer' ? 'selected' : ''
          }`}
        >
          <img src={DesignerIcon} alt="designer-icon" />
          <h7 className="tw-text-black tw-font-semibold">Design Professional</h7>
        </div>
      </div>
    </div>
  );
}

ChooseType.propTypes = {
  userType: string.isRequired,
  onSubmit: func.isRequired,
};

export { ChooseType };
