import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { doPost } from '../../../utils';

const BrandDetail = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();

  const [buttonText, setButtonText] = useState('Save');
  const [brandImage, setBrandImage] = useState(null);

  const formSubmitHandler = e => {
    e.preventDefault();
    setButtonText('Saving...');
    const bodyFormData = new FormData();

    if (!brandImage) {
      return;
    }

    const [file] = brandImage;
    bodyFormData.set('brand_image', file);

    doPost({
      url: `/vendor/brand/${slug}/details`,
      body: bodyFormData,
    })
      .then(() => {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'success', message: 'Updated successfully.' },
        });
        setButtonText('Save');
      })
      .catch(() => {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'failure', message: 'Failed to save finish details' },
        });
      });
  };

  return (
    <div>
      <div className="section-container">
        <h2 className="vendor-title">Brand Details</h2>
        <form onSubmit={formSubmitHandler} className="v2-form">
          <div className="form-group row">
            <label htmlFor="finish_image" className="col-sm-2 col-form-label">
              Brand Image
            </label>
            <div className="col-sm-10">
              <input
                className="form-control"
                type="file"
                name="finish_image"
                id="finish_image"
                onChange={e => setBrandImage(e.target.files)}
              />
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-10 offset-sm-2">
              <button type="submit" className="btn btn-primary round-border">
                {buttonText}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export { BrandDetail };
