import React from 'react';
import { arrayOf, bool, string, func, shape, oneOfType, number } from 'prop-types';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

function SimpleSelect({ options, onChange, value, disabled, classes, placeholder }) {
  const className = `${classes}`;
  return (
    <Select
      margin="dense"
      className={className}
      variant="outlined"
      disabled={disabled}
      placeholder={placeholder}
      value={value}
      defaultValue=""
      autoWidth={false}
      onChange={event => onChange(event.target.value)}
    >
      {options.map(option => (
        <MenuItem className="ps-3" key={option.label} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </Select>
  );
}

SimpleSelect.defaultProps = {
  disabled: false,
  classes: '',
  placeholder: 'Select',
};

SimpleSelect.propTypes = {
  options: arrayOf(
    shape({
      label: string,
      value: oneOfType([string, number]),
    }),
  ).isRequired,
  onChange: func.isRequired,
  value: oneOfType([string, number]).isRequired,
  disabled: bool,
  classes: string,
  placeholder: string,
};

export { SimpleSelect };
