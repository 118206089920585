import React, { useState, useEffect } from 'react';

import { doGet } from 'utils';

import { SectionHeader } from '../Shared/SectionHeader';
import { CatalogTable } from './CatalogTable';

function FurnitureCatalog() {
  const [items, updateItems] = useState([]);
  useEffect(() => {
    doGet({
      url: 'catalog/furniture',
    }).then(({ data }) => {
      updateItems(data);
    });
  }, []);

  return (
    <>
      <SectionHeader title="Furniture Catalog" />
      <CatalogTable items={items} editUrlSegment="catalog/furniture" />
    </>
  );
}

function FalseCeilingCatalog() {
  const [items, updateItems] = useState([]);
  useEffect(() => {
    doGet({
      url: 'catalog/false-ceiling',
    }).then(({ data }) => {
      updateItems(data);
    });
  }, []);

  return (
    <>
      <SectionHeader title="False ceiling Catalog" />
      <CatalogTable items={items} editUrlSegment="catalog/false-ceiling" />
    </>
  );
}

function PaintingCatalog() {
  const [items, updateItems] = useState([]);
  useEffect(() => {
    doGet({
      url: 'catalog/painting',
    }).then(({ data }) => {
      updateItems(data);
    });
  }, []);

  return (
    <>
      <SectionHeader title="Painting Catalog" />
      <CatalogTable items={items} editUrlSegment="catalog/painting" />
    </>
  );
}

function ElectricalCatalog() {
  const [items, updateItems] = useState([]);
  useEffect(() => {
    doGet({
      url: 'catalog/electrical',
    }).then(({ data }) => {
      updateItems(data);
    });
  }, []);

  return (
    <>
      <SectionHeader title="Electrical Catalog" />
      <CatalogTable items={items} editUrlSegment="catalog/electrical" />
    </>
  );
}

function CivilCatalog() {
  const [items, updateItems] = useState([]);
  useEffect(() => {
    doGet({
      url: 'catalog/civil',
    }).then(({ data }) => {
      updateItems(data);
    });
  }, []);

  return (
    <>
      <SectionHeader title="Civil Catalog" />
      <CatalogTable items={items} editUrlSegment="catalog/civil" />
    </>
  );
}

export { FurnitureCatalog, FalseCeilingCatalog, PaintingCatalog, ElectricalCatalog, CivilCatalog };
