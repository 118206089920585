import { FETCH_ELECTRICAL_BOQ } from '../constants/actionTypes';
import { doGet, doPatch, doPost } from '../utils';
import { BoqAction } from './BoqAction';
import { ACTION_CONSTANTS } from './constants';
import { RevisionHistoryAction } from './RevisionHistoryAction';

class BoqElectricalAction {
  static get(boqId) {
    return async dispatch => {
      try {
        const { data: payload } = await doGet({ url: `electrical?boq_id=${boqId}` });
        dispatch({ type: FETCH_ELECTRICAL_BOQ, payload });
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static fetchById(boqId, id) {
    return async dispatch => {
      try {
        const { data: payload } = await doGet({ url: `/electrical/${id}?boq_id=${boqId}` });
        dispatch({ type: 'UPDATE_BOQ_ELECTRICAL_ITEM_FORM', payload });
        dispatch({ type: 'UPDATE_BOQ_ELECTRICAL_ITEM', payload });
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static getEstimateForElectricalItem(oldData, newData, action = 'update') {
    return async dispatch => {
      try {
        dispatch({
          type: 'UPDATE_BOQ_ELECTRICAL_ITEM_STATE',
          payload: { displayCostLoader: true },
        });
        const { data: payload } = await doPost({
          url: 'electrical/estimate',
          body: {
            work: newData.label,
            carpet_area: Number(newData.carpet_area),
            room: newData.room,
            rate: newData.rate,
          },
        });

        // @TODO: Check it out later. Can be merged.
        if (action === 'new') {
          dispatch({ type: 'UPDATE_BOQ_ELECTRICAL_WORK_ITEM', payload });
          dispatch({ type: 'UPDATE_BOQ_ELECTRICAL_WORK_ITEM_FORM', payload });
        } else if (action === 'update') {
          dispatch({ type: 'UPDATE_BOQ_ELECTRICAL_ITEM', payload });
          dispatch({ type: 'UPDATE_BOQ_ELECTRICAL_ITEM_FORM', payload });
        }
      } catch (e) {
        dispatch({ type: 'UPDATE_BOQ_ELECTRICAL_ITEM_FORM', payload: oldData });
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      } finally {
        dispatch({
          type: 'UPDATE_BOQ_ELECTRICAL_ITEM_STATE',
          payload: { displayCostLoader: false },
        });
      }
    };
  }

  static update(boqId, formData) {
    return async dispatch => {
      try {
        const { id } = formData;
        await doPatch({
          url: `electrical/${id}?boq_id=${boqId}`,
          body: {
            carpet_area: Number(formData.carpet_area),
            room: formData.room,
            rate: formData.rate,
          },
        });
        dispatch(BoqAction.fetchBoqDetails(boqId));
        dispatch(BoqElectricalAction.get(boqId));
        dispatch({ type: 'HIDE_VIEW_BOQ_ELECTRICAL' });
        dispatch({
          type: 'UPDATE_BOQ_ELECTRICAL_EDIT_MODE',
          payload: { display: false, formData: {} },
        });
        dispatch(RevisionHistoryAction.fetchList(boqId));
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'success', message: ACTION_CONSTANTS.itemUpdated },
        });
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }
}

export { BoqElectricalAction };
