import React, { useState, useEffect } from 'react';
import { arrayOf, shape, string, func } from 'prop-types';
import { isEmpty } from 'lodash';
import {
  IoIosArrowDown,
  IoIosArrowUp,
  IoIosCheckmarkCircleOutline,
  IoIosCloseCircleOutline,
} from 'react-icons/io';

import { DimensionField } from './DimensionField';

function DimensionDropdown({ items, value, defaultValue, onChange }) {
  const [dimensionText, updateDimensionText] = useState('');
  const [shouldDisplayList, updateShouldDisplayList] = useState(false);
  const [shouldDisplayCustomDimensionField, updateShouldDisplayCustomDimensionField] = useState(
    false,
  );
  const [dimensions, updateDimensions] = useState({
    length: {
      feet: '',
      inches: '',
      attribute: 'L',
    },
    width: {
      feet: '',
      inches: '',
      attribute: 'W',
    },
    height: {
      feet: '',
      inches: '',
      attribute: 'H',
    },
  });

  useEffect(() => {
    if (isEmpty(value)) {
      updateDimensionText(defaultValue);
      return;
    }

    const index = items.findIndex(item => item.value === value);
    if (index !== -1) {
      updateDimensionText(items[index].value);
    }
  }, [items]);

  const onClick = text => {
    updateDimensionText(text);
    updateShouldDisplayList(false);
    onChange(text);
  };

  const handleOnCustomDimensionAccept = () => {
    const dimension = Object.values(dimensions)
      .map(item => {
        let { feet, inches } = item;
        feet = Number(feet);
        inches = Number(inches);
        return `${feet}'${inches}"${item.attribute}`;
      })
      .join(' X ');
    updateDimensionText(dimension);
    updateShouldDisplayList(false);
    updateShouldDisplayCustomDimensionField(false);
    onChange(dimension);
  };

  return (
    <div className="dimension-select-wrapper tw-relative">
      <div
        className="dimension-select-header"
        onClick={() => updateShouldDisplayList(!shouldDisplayList)}
      >
        <div className="dimension-select-header-title tw-flex tw-border tw-rounded tw-w-full tw-py-2 tw-px-1 tw-pt-3 tw-pb-3">
          <span className="tw-w-1/2">{dimensionText}</span>
          <span className="tw-flex tw-w-1/2 tw-flex-row-reverse tw-items-center">
            {!shouldDisplayList && <IoIosArrowDown className="tw-cursor-pointer" />}
            {shouldDisplayList && <IoIosArrowUp className="tw-cursor-pointer" />}
          </span>
        </div>
      </div>
      {shouldDisplayList && (
        <>
          {items.length > 0 && (
            <ul className="dimension-select-list tw-relative tw-absolute tw-mb-0 tw-pl-3 tw-border-r tw-border-l tw-border-t tw-rounded-t tw-w-full tw-list-none tw-p-2 tw-bg-white tw-z-10">
              {items.map(item => (
                <li
                  value={item.value}
                  onClick={() => onClick(item.value)}
                  className="dimension-select-list-item"
                >
                  {item.label}
                </li>
              ))}
            </ul>
          )}

          <div className="tw-flex tw-absolute  tw-w-full tw-bg-white tw-z-10 tw-pl-3 tw-border tw-rounded-b">
            {!shouldDisplayCustomDimensionField && (
              <span
                className="tw-text-cta tw-cursor-pointer tw-p-2"
                onClick={() => updateShouldDisplayCustomDimensionField(true)}
              >
                Custom size
              </span>
            )}

            {shouldDisplayCustomDimensionField && (
              <>
                <div className="tw-w-1/4 tw-m-1">
                  <DimensionField
                    label="Length"
                    feet={dimensions.length.feet}
                    inches={dimensions.length.inches}
                    onChange={(attribute, attributeValue) => {
                      updateDimensions({
                        ...dimensions,
                        length: { ...dimensions.length, [attribute]: attributeValue },
                      });
                    }}
                  />
                </div>
                <div className="tw-w-1/4 tw-m-1">
                  <DimensionField
                    label="Width"
                    feet={dimensions.width.feet}
                    inches={dimensions.width.inches}
                    onChange={(attribute, attributeValue) => {
                      updateDimensions({
                        ...dimensions,
                        width: { ...dimensions.width, [attribute]: attributeValue },
                      });
                    }}
                  />
                </div>
                <div className="tw-w-1/4 tw-m-1">
                  <DimensionField
                    label="Height"
                    feet={dimensions.height.feet}
                    inches={dimensions.height.inches}
                    onChange={(attribute, attributeValue) => {
                      updateDimensions({
                        ...dimensions,
                        height: { ...dimensions.height, [attribute]: attributeValue },
                      });
                    }}
                  />
                </div>
                <div className="tw-flex tw-w-1/4 tw-mt-3 tw-mr-2 tw-ml-2 tw-items-center">
                  <IoIosCloseCircleOutline
                    className="tw-w-1/3 tw-h-10 tw-fill-current tw-text-red-600 tw-cursor-pointer"
                    onClick={() => updateShouldDisplayCustomDimensionField(false)}
                  />
                  <IoIosCheckmarkCircleOutline
                    className="tw-w-1/3 tw-h-10 tw-ml-1 tw-text-green-600 tw-cursor-pointer"
                    onClick={() => handleOnCustomDimensionAccept()}
                  />
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}

DimensionDropdown.propTypes = {
  items: arrayOf(
    shape({
      label: string.isRequired,
      value: string.isRequired,
    }),
  ),
  value: string,
  defaultValue: string,
  onChange: func,
};

DimensionDropdown.defaultProps = {
  items: [],
  value: null,
  onChange: undefined,
  defaultValue: '',
};

export { DimensionDropdown };
