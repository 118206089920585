import React from 'react';
import { string, func } from 'prop-types';
import classNames from 'classnames';

import { IoMdCreate } from 'react-icons/io';

function EditBtn({ onClick, className = '', text }) {
  const classes = classNames('tw-flex', className);
  return (
    <button type="button" className={classes} onClick={onClick}>
      <IoMdCreate className="tw-h-5 tw-w-5 tw-fill-current tw-text-cta" />
      <span className="cta tw-ml-1 d-none d-md-flex">{text}</span>
    </button>
  );
}

EditBtn.defaultProps = {
  className: '',
  text: 'Edit',
};

EditBtn.propTypes = {
  onClick: func.isRequired,
  className: string,
  text: string,
};

export { EditBtn };
