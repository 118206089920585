const mapper = {
  boq_id: 'boqId',
  budget_type: 'budgetType',
  boq_costing: 'boqDetails',
  boq_info: 'boqInfo',
  boq_file_s3_url: 's3Url',
  carpet_area: 'carpetArea',
  cost_per_sq_feet: 'costPerSqFeet',
  cost_breakup: 'costBreakup',
  material_cost: 'materialCost',
  has_modular_kitchen: 'hasModularKitchen',
  image_urls: 'imageUrls',
  image_url: 'imageUrl',
  layout_type: 'layoutType',
  last_updated: 'lastUpdated',
  labour_cost: 'labourCost',
  total_cost: 'totalCost',
  project_duration: 'projectDuration',
  project_images: 'projectImages',
  updated_at: 'updatedAt',
  item_name: 'itemName',
  available_as: 'availableAs',
  assumed_rate: 'assumedRate',
  brand_used: 'brandUsed',
  plank_size: 'plankSize',
  slab_size: 'slabSize',
  sheet_type: 'sheetType',
  sheet_size: 'sheetSize',
};

const toCamelCase = item => {
  if (!item) {
    return {};
  }

  if (typeof item === 'string') {
    // string but has no underscore,
    // so camelizing it is not required
    // return as is.
    const hasUnderscore = item.includes('_');
    if (!hasUnderscore) {
      return item;
    }

    return mapper[item];
  }

  return Object.keys(item).reduce((camelCasedObject, key) => {
    const camelCasedKey = mapper[key];
    if (camelCasedKey) {
      camelCasedObject[camelCasedKey] = item[key];
      return camelCasedObject;
    }

    camelCasedObject[key] = item[key];
    return camelCasedObject;
  }, {});
};

module.exports = { toCamelCase };
