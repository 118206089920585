import { useEffect, useState } from 'react';
import { orderBy } from 'lodash';

function useSortForListView(sortArr, items) {
  const [t, setT] = useState({});
  const [dataList, setDataList] = useState([]);
  useEffect(() => {
    const temp = {};
    sortArr.forEach(item => {
      temp[item] = 0;
    });
    setT({ ...temp });
    setDataList(items);
  }, [items]);

  const setSortAction = (key, action) => {
    if (!Object.keys(t).includes(key)) {
      return;
    }
    Object.keys(t).forEach(k => {
      if (k === key) {
        t[k] = action;
      } else {
        t[k] = 0;
      }
    });
    setT({ ...t });
  };

  const sortFn = () => {
    const sortOrder = [];
    const sortKeys = [];
    Object.keys(t).forEach(item => {
      if (t[item] !== 0) {
        sortKeys.push(item);
      }
      if (t[item] === -1) {
        sortOrder.push('asc');
      } else if (t[item] === 1) {
        sortOrder.push('desc');
      }
    });
    setDataList(orderBy(dataList, sortKeys, sortOrder));
  };

  useEffect(() => {
    sortFn();
  }, [t]);

  return [t, dataList, setSortAction];
}

export { useSortForListView };
