import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ConfirmationPopup } from 'components/Shared';

function ViewConfirmationModal() {
  const dispatch = useDispatch();
  const {
    viewConfirmationPopup: {
      show,
      data: { onConfirm, onCancel },
    },
  } = useSelector(state => state.modal);
  return (
    <div className="view-confirmation-popup">
      {show && (
        <ConfirmationPopup
          onConfirm={() => {
            onConfirm();
            dispatch({ type: 'HIDE_CONFIRMATION_POPUP' });
          }}
          onCancel={() => {
            onCancel();
            dispatch({ type: 'HIDE_CONFIRMATION_POPUP' });
          }}
        />
      )}
    </div>
  );
}

ViewConfirmationModal.propTypes = {};
ViewConfirmationModal.defaultProps = {};

export { ViewConfirmationModal };
