import { combineReducers } from 'redux';
import {
  boqDetailReducer,
  boqCarpentryReducer,
  boqCarpentryItemReducer,
  boqFalseCeilingReducer,
  boqPaintingReducer,
  boqElectricalReducer,
  boqUndetectedCarpentryReducer,
  boqCivilReducer,
  boqNewCarpentryItemReducer,
  boqEditCarpentryItemReducer,
  boqNewCivilWorkItemReducer,
  boqCivilItemReducer,
  boqPaintingItemReducer,
  boqElectricalItemReducer,
  boqFalseCeilingItemReducer,
  boqSoftFurnishingReducer,
  boqSoftFurnishingItemReducer,
  boqReducer,
} from './boqReducer';
import {
  boqCarpentryLabourAnalysisReducer,
  boqCivilLabourAnalysisReducer,
} from './boqLabourAnalysisReducer';

import {
  boqCarpentryMaterialAnalysisReducer,
  boqCivilMaterialAnalysisReducer,
} from './boqMaterialAnalysisReducer';

import { roomReducer } from './roomReducer';

import { modalReducer } from './modalReducer';
import { boqCommentsReducer } from './boqCommentsReducer';

import { filterForMobileReducer } from './filterReducer';
import { vendorReducer } from './vendorReducer';
import { dashboardReducer } from './DashboardReducer';
import { revisionHistoryReducer } from './RevisionHistoryReducer';

const rootReducer = combineReducers({
  modal: modalReducer,
  boqDetails: boqDetailReducer,
  boqCarpentry: boqCarpentryReducer,
  boqCarpentryItem: boqCarpentryItemReducer,
  boqFalseCeiling: boqFalseCeilingReducer,
  boqPainting: boqPaintingReducer,
  boqElectrical: boqElectricalReducer,
  boqUndetectedCarpentry: boqUndetectedCarpentryReducer,
  boqCivil: boqCivilReducer,
  boqLabourAnalysis: combineReducers({
    carpentry: boqCarpentryLabourAnalysisReducer,
    civil: boqCivilLabourAnalysisReducer,
  }),
  boqMaterialAnalysis: combineReducers({
    carpentry: boqCarpentryMaterialAnalysisReducer,
    civil: boqCivilMaterialAnalysisReducer,
  }),
  rooms: roomReducer,
  boqNewCarpentryItem: boqNewCarpentryItemReducer,
  boqEditCarpentryItem: boqEditCarpentryItemReducer,
  boqComments: boqCommentsReducer,
  boqNewCivilWorkItem: boqNewCivilWorkItemReducer,
  boqCivilItem: boqCivilItemReducer,
  boqPaintingItem: boqPaintingItemReducer,
  boqElectricalItem: boqElectricalItemReducer,
  boqFalseCeilingItem: boqFalseCeilingItemReducer,
  boqSoftFurnishing: boqSoftFurnishingReducer,
  boqSoftFurnishingItem: boqSoftFurnishingItemReducer,
  boqList: boqReducer,
  boqDetailsMobileFilter: filterForMobileReducer,
  vendor: vendorReducer,
  dashboard: dashboardReducer,
  revisionHistory: revisionHistoryReducer,
});

export { rootReducer };
