import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Login } from '../Login';
import { FullPageModal } from '../Shared/Modal/FullPageModal';

import './login-modal.scss';

/**
 * @returns {*}
 * @constructor
 */
const LoginModal = () => {
  const dispatch = useDispatch();
  const {
    loginModal: { show = false, data: { next, boqId, formToDisplay } = {} } = {},
  } = useSelector(state => state.modal);

  if (!show) {
    return null;
  }

  const handleClose = () => {
    dispatch({ type: 'HIDE_LOGIN_MODAL' });
  };

  return (
    <FullPageModal open={show} showClose onClose={handleClose}>
      <Login mode="modal" next={next} boqId={boqId} formToDisplay={formToDisplay} />
    </FullPageModal>
  );
};

export { LoginModal };
