import React from 'react';
import { useLocation } from 'react-router-dom';

import { isLoggedInUser } from 'utils';
import { FbIcon, InstagramIcon, MailIcon, CallIcon } from 'components/Image';

import './footer.scss';

const Footer = () => {
  const location = useLocation();
  if (isLoggedInUser() || location.pathname !== '/') {
    return null;
  }

  // this intentional typo to hide footer for now
  // hide footer to start implementing invision mockup
  if (typeof saemple === 'undefined') {
    return null;
  }

  return (
    <div className="footer">
      <div className="col-sm-12">
        <p>
          Hipcouch is an end-to-end interior design service. We work with homes and workspaces of
          every size. All our products and services are backed by warranties and we tirelessly
          ensure timely delivery.
        </p>
      </div>
      <div className="col-sm-12">
        <p>
          <span>
            <span>
              <img className="icon" alt="hipcouch-contact-number" src={CallIcon} />
              +91 97698 91528
            </span>
            <span>
              <img className="icon" alt="hipcouch-email" src={MailIcon} />
              contact@hipcouch.com
            </span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="icon"
              href="https://www.facebook.com/hipcouch"
            >
              <img className="icon" alt="hipcouch-fb-page" src={FbIcon} />
            </a>
          </span>
          <span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="icon"
              href="https://www.instagram.com/hipcouch/"
            >
              <img className="icon" alt="hipcouch-instagram-page" src={InstagramIcon} />
            </a>
          </span>
        </p>
      </div>
    </div>
  );
};

export { Footer };
