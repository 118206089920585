import React from 'react';
import { func, bool, node, string, oneOfType, oneOf, number } from 'prop-types';
import classNames from 'classnames';

import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent } from '@material-ui/core';
import { IoIosClose } from 'react-icons/all';

import './simple-modal.scss';

function SimpleModal({
  heading,
  children,
  onClose,
  open,
  classes,
  maxWidth,
  transparentBackground,
  maxHeight,
  displayCloseButton,
}) {
  const className = classNames('tw-w-full tw-h-full', classes);
  const dialogProps = {
    onClose,
    'aria-labelledby': 'simple-dialog-title',
    open,
    maxWidth,
    PaperProps: {
      style: {
        maxHeight,
        margin: 0,
      },
    },
  };
  if (transparentBackground) {
    dialogProps.PaperProps.style = {
      ...dialogProps.PaperProps.style,
      backgroundColor: 'transparent',
      boxShadow: 'none',
    };
  }
  return (
    <>
      <Dialog {...dialogProps} className="simple-modal">
        <div className={className}>
          {displayCloseButton && (
            <div className="position-absolute close-btn top-0 end-0">
              <IoIosClose
                className={`tw-w-8 tw-h-8 tw-cursor-pointer tw-mt-2 tw-mr-2 ${
                  transparentBackground ? 'tw-text-white' : ''
                }`}
                onClick={onClose}
              />
            </div>
          )}
          {heading !== '' && <DialogTitle>{heading}</DialogTitle>}
          {<DialogContent>{children}</DialogContent>}
        </div>
      </Dialog>
    </>
  );
}

SimpleModal.propTypes = {
  onClose: func,
  open: bool,
  heading: oneOfType([node, string]),
  children: node.isRequired,
  classes: string,
  fullWidth: bool,
  maxWidth: oneOfType([oneOf(['xs', 'sm', 'md', 'lg', 'xl']), bool]),
  transparentBackground: bool,
  maxHeight: oneOfType([string, number]),
  displayCloseButton: bool,
};

SimpleModal.defaultProps = {
  heading: '',
  onClose: () => {},
  open: false,
  classes: '',
  fullWidth: false,
  maxWidth: false,
  transparentBackground: false,
  maxHeight: '95vh',
  displayCloseButton: true,
};

export { SimpleModal };
