import React from 'react';
import { func } from 'prop-types';

function LoginLink({ onClick }) {
  return (
    <>
      <span>Have an account?</span>
      <span className="cta tw-cursor-pointer" onClick={onClick}>
        Login
      </span>
    </>
  );
}

LoginLink.propTypes = {
  onClick: func,
};

LoginLink.defaultProps = {
  onClick: () => {},
};
export { LoginLink };
