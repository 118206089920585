import { PERMISSIONS } from 'constants/permissions';

function hasPermission(role, action) {
  if (role === 'admin') {
    return true;
  }
  if (!PERMISSIONS[role]) {
    return false;
  }
  return PERMISSIONS[role].has(action);
}

export { hasPermission };
