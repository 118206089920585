import React from 'react';
import { bool } from 'prop-types';

import './style.scss';

const Loader = ({ loading }) => {
  if (loading) {
    return (
      <div className="loader-container">
        <div className="loader" />
      </div>
    );
  }

  return null;
};

Loader.propTypes = {
  loading: bool.isRequired,
};

export { Loader };
