const data = {
  schedule: `This a high-level time-line for the approximate number of weeks it will take to build this project end-to-end (design + build)<br/>
    Please note:<br/>
    - The time-line provided is based on certain assumptions & data we have on-hand without surveying your apartment. <br/>
    - This time-line could change (increase or decrease) after the apartment has been inspected for issues such as leakage, wall cracks, termites, structural damages, etc.<br/>
    - In order to build the schedule, we have assumed that your building will allow a reasonable numbers of workers (8 to 10+) to work on site.<br/>
    - We have assumed the work hours to be 10am to 7pm with no noisy work allowed between 2 pm to 4 pm. The work days assumed are Monday to Saturday.<br/>
`,
  projectImages: `
    These are images of projects executed or pitched by Hipcouch.<br/>
    For example: If the cost estimate provided by the tool is 30 lakhs, you will only see images of Projects whose final costs actually fell within a range of + or - 7% range.
  `,
  costPerSqft: 'This the total cost / sqft. for design + build of your space.',
  labourCost:
    'This is total cost for all the labor work or man hours required to build all civil + carpentry items for the project.',
  materialCost:
    'This is the total cost of all the materials required for the project. These include raw materials (ply, glass, etc), finish materials (tiles, laminates, etc) and hardware',
  otherCosts:
    'This includes all costs for electrical, painting and false ceiling items across the apartment. These costs typically cannot be divided into labor and material costs',
  looseFurniture:
    'This includes costs for items such as sofas, dining tables, chairs, benches, coffee tables, etc which are typically considered as loose items and are not made on site.',
  designFee:
    'The typical fees charged by Hipcouch for designing a project of this size. The design fee is charged for providing designs, 3Ds, detailed drawings &  material selections.',
  totalCost:
    'This the total cost to design & build your project. Please note that this cost does NOT include the GST. Typically GST is charged as 18% on interior projects.',
  aggregateCosts:
    'These are costs which cannot be classified by room. Such as electrical, painting, false ceiling costs, etc, which are typically estimated for the entire apartment.',
  serviceSectionCarpentry:
    'All wood work for all types of loose & fixed furniture items is listed under this section',
  serviceSectionCivil: `All activities related to civil work are listed under this section. These activities include work such as: Wall making & breaking, flooring work, kitchen counter & dado* work & bathroom work<br/>
            *Dado  = Wall tiles between kitchen platform & over head cabinets`,
  serviceSectionElectrical:
    'All activities related to electrical items needed in the apartment are listed in this section. This includes work such as adding new electrical points & shifting existing electrical points',
  serviceSectionFalseCeiling:
    'This section includes all the items that are needed to fix a false ceiling. False ceiling is the ceiling created under the main slab, using mostly Gypsum sheets which are fixed to an aluminum framework. Many apartments need a False ceiling to take care of lighting needs',
  serviceSectionDesign:
    'The typical fees charged by Hipcouch for designing a project of this size. The design fee is charged for providing designs, 3Ds, detailed drawings &  material selections.',
  serviceSectionLooseFurniture:
    'This includes costs for items such as sofas, dining tables, chairs, benches, coffee tables, etc which are typically considered as loose items and are not made on site.',
  serviceSectionPainting:
    'This section includes the costs for painting the entire house including the bathrooms. (Please note - After doing interior work, the entire apartment will need to be re-painted)',
  serviceSectionSoftFurnishing:
    'This section includes the cost of curtains for the entire apartment',
  details: {
    assumptions:
      'As an industry norm, all cost estimates are built upon certain assumptions & material specifications. This tab provides details about the assumptions made to build your cost estimate as well what type of materials are being used. This tab also lists what is NOT included in this cost estimate.',
    allItems:
      'This tab contains the list of all the items which make up your cost estimate. The items are displayed after being grouped by categories, which are available on the menu to the left of the screen.',
    materialAnalysis:
      'This tab contains the list, quantity and total cost of all the materials required across the entire project. It also shows the rates that have been assumed for every material that is a part of the project.',
    labourAnalysis:
      'This tab contains the labor rate and labor costs for all the items required across the entire project.',
  },
  materialAnalysis: {
    qty:
      'An item consists of many materials. This field reflects the quantity of each type of material required to build an item, aggregated across the project. <br> For example: If 18mm plywood is needed in 20 items across the project, then this field depicts the quantity of 18mm plywood required to build those 20 items',
    unit:
      'Each material is measured in different units. This fields defines the units in which the quantity is measured',
    rate:
      'This is the actual or assumed rate at which raw & finishing materials are purchased. You can refer to the Assumptions & Other Info section to get a detailed list of all assumed rates',
    materialName: 'These are the names of the raw or finishing materials',
    totalCost: 'This is the total material cost for each item',
    totalCarpentryCost:
      'This is the total material cost for all carpentry materials required across the entire project',
    totalCivilCost:
      'This is the total material cost for all civil materials required across the entire project',
  },
  labourAnalysis: {
    finish:
      'Depending on the type of Finish for an item / activity the labor rate changes. For example:<br>For building a veneer wardrobe the labor rate is higher than it is for building a laminate wardrobe (this is because veneer requires more skill / time).<br/>For laying marble flooring, the cost is higher than that for laying tile flooring or wooden flooring',
    rate:
      'This is the rate which a carpenter or mason charges to build an item of furniture or to do certain civil work (eg. lay flooring or break a wall)',
    area:
      'Area can be in Sqft or Rft. Labor rate is usually calculated based on the area of an item or a civil activity. The area is calculated using dimensions (length, width and height) of a room or surface or item.',
    total:
      'This is the total labor cost for building a furniture item or performing a civil activity.',
    totalCarpentryCost:
      'This is total labor cost for building all carpentry items across the entire project',
    totalCivilCost: 'This is total labor cost for all civil activities across the entire project',
  },
  generalDetails: {
    carpentry: {
      finish:
        'Furniture items need to be finished with some finishing material. The Finish field, reflects which materials are used for finishing a piece of furniture. Furniture is usually made with ply and finished with materials such as laminate (sunmica), veneer, back-painted glass, PU, duco, lustre spray or lamination, amongst some other finishes.',
      dimensions:
        'These are the dimensions that are considered for each item on your cost estimate. These are standard furniture dimensions, based on best practices in the industry. All these sizes can be modified by you, so that you can get accurate cost estimates as per your desired dimensions',
      area:
        'Each item or activity is measured by area in Rft. or Sqft. The area is used to calculate the total cost of an item. The area is determined by dimensions (length, width and height)',
      materialCost:
        'This is the total cost of all the raw materials, finishing materials & hardware that are required to build an item',
      labourCost:
        'This is cost of the total number of man hours that are taken by carpenters or masons to build an item',
    },
    civil: {
      dimensions:
        'These are the dimensions that are considered for each item on your cost estimate. These are standard dimensions, based on best practices in the industry or your apartment size. All these sizes can be modified by you, so that you can get accurate cost estimates as per your desired dimensions',
      materialCost:
        'This is the total cost of all the raw materials, finishing materials & hardware that are required to build an item',
      labourCost:
        'This is cost of the total number of man hours that are taken by carpenters or masons to build an item',
      finish:
        'This field provides the material options (or finishing options) available for finishing civil work related items such as flooring work, kitchen counter-tops & dado work, bathroom flooring and wall dado work.<br/><br/>For example, flooring can be done using either tiles, marble, wooden planks, etc. These are the different types of finishing available for flooring work. Similarly different finishes are available for different civil items.',
    },
    painting: {
      area:
        'We have used industry standard formulae and your carpet area as the input to calculate the area that needs to be painted. You can edit this area as you see fit',
    },
    falseCeiling: {
      finish:
        'This indicates the type of finish for the false ceiling. False ceiling is largely made of gypsum. The false ceiling for bathrooms is made of calcium silicate which is water proof. False ceiling could also have veneer, laminates or shera planks as finishes in some cases.',
      area:
        'We have used industry standard formulae and your carpet area as the input to calculate the area that needs to be painted. You can edit this area as you see fit',
    },
    softFurnishing: {},
  },
  materialsUsed: {
    carpentry: {
      qty:
        'An item consists of many materials. This field reflects the quantity of each type of material required to build an item. For example a storage unit may require plywood, laminate, hardware and fevicol to build. This field displays the quantity of each material required to build the final product',
      rate:
        'This is the rate at which:<br/> - We procure raw materials used to build an item (such as the rate for plywood, Fevicol, etc)<br/>- Or the rate that we have assumed for materials, which are required to build an item (such as rates for fabric, laminate sheets, veneer sheets, tiles, marble, granite, quartz, etc - mostly the finishing materials)',
    },
    civil: {
      qty:
        'An item consists of many materials. This field reflects the quantity of each type of material required to build an item',
      rate:
        'This is the rate at which:<br/>- We procure raw materials used to build an item (such as the rate for plywood, MDF sheets, Fevicol, etc)<br/>- Or the rate that we have assumed for materials, which are required to build an item (such as rates for fabric, laminate sheets, veneer sheets, tiles, marble, granite, quartz, etc - mostly the finishing materials)',
    },
    softFurnishing: {
      qty:
        'An item consists of many materials. This field reflects the quantity of each type of material required to build an item',
      rate:
        'This is the rate at which:<br/>- We procure raw materials used to build an item (such as the rate for plywood, MDF sheets, Fevicol, etc)<br/>- Or the rate that we have assumed for materials, which are required to build an item (such as rates for fabric, laminate sheets, veneer sheets, tiles, marble, granite, quartz, etc - mostly the finishing materials)',
      curtains:
        'Curtains require some hardware to be fixed. Such as track, screws for tracks, and hooks. Also some other hardware is required to fit the tracks on the pelmet or ceiling.',
    },
  },
  workItems: {
    civil: {
      workItems:
        'Each civil activity may have multiple sub-tasks / activities. These are called work items. For example an activity like flooring work, could have work items such as: laying the flooring, fixing the skirting',
      labourCost: 'This is the total labor cost for a particular work item',
      materialCost: 'This is the total material cost for a particular work item',
    },
  },
};

export { data };
