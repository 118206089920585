import React, { useEffect, useState } from 'react';
import { bool, func } from 'prop-types';

import { doGet } from '../../../utils';

import '../vendors.scss';

const Offers = ({ showOffer, onClick }) => {
  const [offers, setOffers] = useState([]);

  const fetchOffers = async () => {
    const data = await doGet({
      url: '/vendor/offers',
    });
    setOffers(data.offers);
  };

  useEffect(() => {
    fetchOffers();
  }, []);

  if (!showOffer) {
    return null;
  }

  return (
    <div className="animate__faster animate__animated animate__slideInRight tw-absolute tw-right-0 tw-top-0 tw-h-screen tw-p-2 tw-bg-white tw-w-1/4">
      <h3 className="tw-p-4 tw-flex tw-justify-between">
        <span className="tw-font-semibold tw-text-base tw-text-cta tw-uppercase">
          Explore Offers
        </span>
        <span
          className="tw-font-semibold tw-text-base tw-text-cta tw-uppercase tw-cursor-pointer"
          onClick={onClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="tw-h-5 tw-w-5 tw-inline-block tw-relative -tw-top-1"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
          CLOSE
        </span>
      </h3>
      <ul>
        {offers.map(offer => {
          return (
            <li className=" tw-bg-gray-100 tw-m-2 tw-mb-4 tw-p-4 tw-border tw-border-gray-200">
              <p className="tw-mb-0 tw-mt-2">{offer.message}</p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

Offers.propTypes = {
  showOffer: bool.isRequired,
  onClick: func.isRequired,
};

export { Offers };
