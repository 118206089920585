import {
  FETCH_CIVIL_LABOUR_ANALYSIS,
  FETCH_CARPENTRY_LABOUR_ANALYSIS,
  FETCH_CARPENTRY_MATERIAL_ANALYSIS,
  API_ERROR,
  FETCH_CIVIL_MATERIAL_ANALYSIS,
} from '../constants/actionTypes';
import { doGet } from '../utils';

/**
 * mapped /analysis
 */
class AnalysisAction {
  static getCarpentryLabour(boqId) {
    return async dispatch => {
      try {
        const { data: payload } = await doGet({
          url: `/analysis?type=carpentry-labour&boq_id=${boqId}`,
        });
        dispatch({ type: FETCH_CARPENTRY_LABOUR_ANALYSIS, payload });
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static getCivilLabour(boqId) {
    return async dispatch => {
      try {
        const { data: payload } = await doGet({
          url: `/analysis?type=civil-labour&boq_id=${boqId}`,
        });
        dispatch({ type: FETCH_CIVIL_LABOUR_ANALYSIS, payload });
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static getCarpentryMaterial(boqId) {
    return async dispatch => {
      try {
        const { data: payload } = await doGet({
          url: `/analysis?type=carpentry-material&boq_id=${boqId}`,
        });
        return dispatch({ type: FETCH_CARPENTRY_MATERIAL_ANALYSIS, payload });
      } catch (e) {
        return dispatch({ type: API_ERROR, payload: e });
      }
    };
  }

  static getCivilMaterial(boqId) {
    return async dispatch => {
      try {
        const { data: payload } = await doGet({
          url: `/analysis?type=civil-material&boq_id=${boqId}`,
        });
        return dispatch({ type: FETCH_CIVIL_MATERIAL_ANALYSIS, payload });
      } catch (e) {
        return dispatch({ type: API_ERROR, payload: e });
      }
    };
  }
}

export { AnalysisAction };
