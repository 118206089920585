const initialState = {
  data: [],
  meta: {},
  isForceUpdate: false,
};

const revisionHistoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_REVISION_HISTORY':
      return { ...state, ...action.payload };
    default:
      return { ...state };
  }
};

export { revisionHistoryReducer };
