import React from 'react';
import { node, func, string, number, bool, oneOfType } from 'prop-types';

import MUIPopover from '@material-ui/core/Popover';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import './popover.scss';

const styles = theme => {
  return createStyles({
    paper: {
      overflowX: 'unset',
      overflowY: 'unset',
      '&::before': {
        content: '""',
        position: 'absolute',
        marginRight: '-0.71em',
        bottom: 0,
        left: 'calc(50%)',
        width: 10,
        height: 10,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[1],
        transform: 'translate(-50%, 50%) rotate(135deg)',
        clipPath: 'polygon(-5px -5px, calc(100% + 5px) -5px, calc(100% + 5px) calc(100% + 5px))',
      },
    },
  });
};

const useStyles = makeStyles(theme => ({
  popover: {},
  paper: {
    ...styles(theme).paper,
    padding: theme.spacing(1),
    marginTop: theme.spacing(-1),
  },
}));

function Popover({ children, handleClose, id, anchorEl, open }) {
  const classes = useStyles();
  return (
    <div>
      <MUIPopover
        id={id}
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        {children}
      </MUIPopover>
    </div>
  );
}

Popover.propTypes = {
  children: node.isRequired,
  handleClose: func,
  id: oneOfType([string, number]).isRequired,
  anchorEl: oneOfType([null, node]),
  open: bool,
};

Popover.defaultProps = {
  handleClose: () => {},
  anchorEl: null,
  open: false,
};

export { Popover };
