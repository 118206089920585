import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { doPost } from 'utils';
import { useToast } from 'hooks';
import { hasPermission } from 'utils/PermissionUtils';

import UserContext from '../../../UserContext';

import './home.scss';

function UploadPage() {
  const { role } = useContext(UserContext);
  const history = useHistory();
  const { errorToast } = useToast();
  const [fileInput, setFileInput] = useState(null);
  const [disable, setDisable] = useState(false);
  const [caption, setCaption] = useState('Upload');

  useEffect(() => {
    if (role && !hasPermission(role, 'upload_boq')) {
      history.replace('/');
    }
  }, [role]);

  const handleSubmit = e => {
    e.preventDefault();
    if (!fileInput) {
      errorToast('Please select a valid PDF or excel file to get a BOQ quote');
      return;
    }
    const [file] = fileInput;
    const bodyFormData = new FormData();
    bodyFormData.set('file', file);
    setCaption('Uploading...');
    setDisable(true);

    doPost({
      url: `${process.env.REACT_APP_API_URL}/boqs/`,
      body: bodyFormData,
    })
      .then(({ data: { boq_id: boqId } }) => {
        window.location.assign(`/boqs/${boqId}/details`);
      })
      .catch(() => {
        // TODO: ('failed');
      })
      .then(() => {
        setCaption('Upload');
        setDisable(false);
      });
  };

  return (
    <div className="home-container">
      <div className="form-container">
        <h4>Create a BOQ</h4>

        <div className="upload-container">
          <div id="custom-templates">
            <form method="post" encType="multipart/form-data" onSubmit={handleSubmit}>
              <input
                type="file"
                name="file"
                id="file"
                onChange={e => setFileInput(e.target.files)}
              />
              <button type="submit" disabled={disable} className="btn btn-primary">
                {caption}
              </button>
              <p className="help-block">
                Please upload PDF or Excel files only.
                <br />
                Click
                <a href="/layout.pdf">here</a>
                for sample file upload
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export { UploadPage };
