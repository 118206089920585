import React from 'react';
import { func } from 'prop-types';

function SignUpLink({ onClick }) {
  return (
    <>
      <span>Don&apos;t have an account?</span>
      <span className="cta tw-cursor-pointer" onClick={onClick}>
        Sign Up
      </span>
    </>
  );
}

SignUpLink.propTypes = {
  onClick: func,
};

SignUpLink.defaultProps = {
  onClick: () => {},
};

export { SignUpLink };
