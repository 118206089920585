import { doDelete, doGet, doPost } from 'utils/NetworkUtils';
import { RevisionHistoryAction } from './RevisionHistoryAction';

class CommentsAction {
  static get(work, boqId, id) {
    return async dispatch => {
      try {
        const { data: payload } = await doGet({
          url: `comments/${id}?boq_id=${boqId}&work=${work}`,
        });
        dispatch({ type: 'FETCH_BOQ_COMMENTS', payload });
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static post(work, boqId, id, data) {
    return async dispatch => {
      try {
        await doPost({
          url: `comments/${id}?boq_id=${boqId}&work=${work}`,
          body: data,
        });
        dispatch({ type: 'RESET_BOQ_COMMENTS_FORM' });
        dispatch(CommentsAction.get(work, boqId, id));
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  // @TODO: Refactor To many params
  static deleteAttachment(work, boqId, id, data) {
    return async dispatch => {
      try {
        const { commentId, attachmentId } = data;
        await doDelete({
          url: `comments/${commentId}/${attachmentId}?boq_id=${boqId}&work=${work}`,
        });
        // dispatch({ type: 'RESET_BOQ_COMMENTS_FORM' });
        dispatch(CommentsAction.get(work, boqId, id));
        dispatch(RevisionHistoryAction.fetchList(boqId));
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }
}
export { CommentsAction };
