import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FullPageModal } from '../../Shared';
import { VendorAction } from '../../../actions';

const ContactVendorModal = () => {
  const {
    contactVendor: { data, show },
  } = useSelector(state => state.modal);
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (data) {
      setFormData(data);
    }
  }, [data]);

  function handleContactRequest(e) {
    e.preventDefault();
    if (!formData) {
      return;
    }
    dispatch(VendorAction.contactVendor(formData))
      .then(() => {
        return dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'success', message: 'Vendor will shortly contact you' },
        });
      })
      .then(() => {
        dispatch({
          type: 'HIDE_CONTACT_VENDOR',
        });
      });
  }

  if (!show) {
    return null;
  }

  const handleClose = () => {
    dispatch({
      type: 'HIDE_CONTACT_VENDOR',
    });
  };

  const onChangeHandler = e => {
    const { value, name } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <FullPageModal
      open={show}
      onClose={handleClose}
      styleClasses={['tw-max-w-4xl', 'tw-mx-auto', 'tw-max-h-4xl']}
    >
      <div className="tw-flex tw-flex-row tw-h-full">
        <div className="tw-w-1/2  tw-h-full rounded-lg tw-border-r tw-border-dashed tw-border-gray-200 tw-p-4">
          <div className="tw-bg-gray-200 tw-text-center tw-w-full tw-font-bold tw-h-64 tw-flex tw-flex-row tw-justify-center tw-items-center ">
            {formData.vendorName}
          </div>
        </div>
        <div className="tw-w-1/2 relative tw-h-full tw-flex tw-flex-row tw-relative">
          <form action="" className="tw-p-6 tw-w-full" onSubmit={handleContactRequest}>
            <h3 className="tw-text-2xl tw-pb-3 tw-border-b tw-border-gray-200 tw-mb-8">
              Contact Us
            </h3>
            <div className="tw-mb-4">
              <label className="tw-text-gray-700 tw-block" htmlFor="name">
                Name*
              </label>
              <input
                required
                className="tw-border tw-border-gray-200 tw-block tw-h-10 tw-w-full"
                type="text"
                name="name"
                id="name"
                onChange={onChangeHandler}
              />
            </div>
            <div className="tw-mb-4">
              <label className="tw-text-gray-700 tw-block" htmlFor="contact">
                Phone No*
              </label>
              <input
                required
                className="tw-border tw-border-gray-200 tw-block tw-h-10 tw-w-full"
                type="text"
                name="phone_number"
                id="phone_number"
                onChange={onChangeHandler}
              />
            </div>
            <div className="tw-mb-4">
              <label className="tw-text-gray-700 tw-block" htmlFor="email">
                Email Address*
              </label>
              <input
                required
                className="tw-border tw-border-gray-200 tw-block tw-h-10 tw-w-full"
                type="email"
                name="email"
                id="email"
                onChange={onChangeHandler}
              />
            </div>
            <div className="tw-mb-4">
              <label className="tw-text-gray-700 tw-block" htmlFor="details">
                Message*
              </label>
              <textarea
                name="message"
                id="message"
                onChange={onChangeHandler}
                required
                rows="4"
                cols="10"
                className="tw-border tw-border-gray-200 tw-block tw-h-32 tw-w-full"
              />
            </div>
            <div className="tw-absolute tw-bottom-0 tw-right-0 tw-w-full tw-flex tw-flex-row tw-justify-end">
              <button
                type="button"
                onClick={handleClose}
                className="tw-w-1/3 tw-px-4 tw-py-2 tw-text-center tw-bg-gray-200 tw-mr-5"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="tw-w-1/3 tw-px-4 tw-py-2 tw-text-center tw-bg-cta tw-text-white"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </FullPageModal>
  );
};

export { ContactVendorModal };
