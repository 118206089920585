import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { string, arrayOf, func } from 'prop-types';

import { RoomAction } from '../../actions';
import { SectionHeader } from '../Shared/SectionHeader';
import { DeleteBtn, EditBtn } from '../Shared/Buttons';

function row({ id, name, alias, handleOnClick }) {
  return (
    <tr key={id}>
      <td>
        {name}
        <div>
          {alias.map(term => {
            return (
              <span key={term} className="alias-badge badge badge-secondary">
                {term}
              </span>
            );
          })}
        </div>
      </td>
      <td>
        <EditBtn className="tw-mr-2 tw-ml-2" onClick={() => handleOnClick.navigateToEdit(id)} />
        <DeleteBtn className="tw-mr-2 tw-ml-2" onClick={() => handleOnClick.remove(id)} />
      </td>
    </tr>
  );
}

row.propTypes = {
  id: string.isRequired,
  name: string.isRequired,
  alias: arrayOf(string).isRequired,
  handleOnClick: func.isRequired,
};

function ListRooms() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { rooms } = useSelector(state => state.rooms);

  useEffect(() => {
    dispatch(RoomAction.getAll());
  }, [rooms.length]);

  const handleOnClick = {
    remove: id => dispatch(RoomAction.delete(id)),
    navigateToEdit: id => history.push(`/rooms/${id}/edit`),
    navigateToCreate: () => history.push('/rooms/new'),
  };

  return (
    <>
      <SectionHeader title="Rooms" />
      <div className="tw-flex tw-flex-row-reverse">
        <button
          type="button"
          className="tw-fill-current tw-bg-orange-500 tw-p-2 tw-rounded-md tw-mb-2"
          onClick={handleOnClick.navigateToCreate}
        >
          New
        </button>
      </div>
      <table className="table table-bordered table-hover tabs-container">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>{rooms.map(room => row({ ...room, handleOnClick }))}</tbody>
      </table>
    </>
  );
}

export { ListRooms };
