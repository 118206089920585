import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Rating from '@material-ui/lab/Rating';
import { IoIosAddCircle } from 'react-icons/io';
import cx from 'classnames';

import { VendorAction } from '../../../actions';
import { AddVendorCatalog } from './AddVendorCatalog';
import { VendorCatalogItem } from './VendorCatalogItem';
import { doGet } from '../../../utils';

const VendorDetail = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();

  const [formDetail, setFormDetail] = useState({
    email: '',
    name: '',
    address: '',
    website_url: '',
    phone_number: '',
  });
  const { vendors = [] } = useSelector(state => state.vendor);
  const [buttonText, setButtonText] = useState('Save');

  const vendor = vendors.find(item => item.slug === slug) || {};
  const [catalog, setCatalog] = useState([]);
  const isEmptyState = catalog.length === 0;

  const getValue = (query, forAll = false) => {
    if (forAll) {
      return encodeURIComponent(JSON.stringify(Array.from(query)));
    }
    return Array.from(query)[0];
  };

  const fetchDetails = async () => {
    if (!vendor._id) {
      return;
    }
    const items = await doGet({
      url: '/vendor/vendor/catalog',
      params: {
        vendor: getValue([vendor._id]),
      },
    });
    setCatalog(items.catalog);
  };

  useEffect(() => {
    fetchDetails();
    setFormDetail({
      ...vendor,
    });
  }, [vendor]);

  const formSubmitHandler = e => {
    setButtonText('Saving...');
    e.preventDefault();
    setTimeout(() => {
      dispatch(VendorAction.updateVendorDetail(formDetail)).then(() => {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'success', message: 'Updated successfully.' },
        });
      });
      setButtonText('Save');
    }, 1000);
  };

  const onRateChangeHandler = (e, value) => {
    setFormDetail({
      ...formDetail,
      rating: value,
    });
  };

  const changeHandler = e => {
    const { value, name } = e.target;
    setFormDetail({
      ...formDetail,
      [name]: value,
    });
  };

  const addVendorCatalog = () => {
    dispatch(VendorAction.getCatalogCount()).then(({ count }) => {
      dispatch({
        type: 'SHOW_ADD_VENDOR_CATALOG',
        payload: {
          count,
        },
      });
    });
  };

  return (
    <div>
      <AddVendorCatalog
        onComplete={() => {
          fetchDetails();
        }}
      />
      <div className="section-container">
        <h2 className="vendor-title">Vendor Details</h2>
        <form onSubmit={formSubmitHandler} className="v2-form">
          <div className="form-group row">
            <div className="col-sm-4">
              <label htmlFor="">Business Info</label>
            </div>
            <div className="col-sm-8">
              <label htmlFor="inputPassword3" className="col-form-label">
                Slug
              </label>
              <div className="">
                <input
                  name="slug"
                  readOnly
                  type="text"
                  className="form-control"
                  id="inputPassword3"
                  placeholder="Password"
                  value={slug}
                />
              </div>
              <label htmlFor="inputEmail3" className="col-form-label">
                Name
              </label>
              <div className="">
                <input
                  name="name"
                  type="text"
                  className="form-control"
                  id="inputEmail3"
                  value={formDetail.name}
                  onChange={changeHandler}
                />
              </div>
            </div>
          </div>

          <hr />

          <div className="form-group row">
            <div className="col-sm-4">
              <label>Contact Info</label>
            </div>
            <div className="col-sm-8">
              <div className="row">
                <div className="form-group col-sm-12">
                  <label htmlFor="inputEmail3" className="col-form-label">
                    Address
                  </label>
                  <div>
                    <input
                      name="address"
                      type="text"
                      className="form-control"
                      id="inputEmail3"
                      value={formDetail.address}
                      onChange={changeHandler}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-sm-12">
                  <label htmlFor="email" className="col-form-label">
                    Email
                  </label>
                  <div>
                    <input
                      name="email"
                      type="email"
                      className="form-control"
                      id="email"
                      value={formDetail.email}
                      onChange={changeHandler}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-sm-6">
                  <label htmlFor="inputEmail3" className="col-form-label">
                    Website
                  </label>
                  <div className="">
                    <input
                      name="website_url"
                      type="text"
                      className="form-control"
                      id="inputEmail3"
                      value={formDetail.website_url}
                      onChange={changeHandler}
                    />
                  </div>
                </div>

                <div className="form-group col-sm-6">
                  <label htmlFor="inputEmail3" className="col-form-label">
                    Phone Number
                  </label>
                  <div>
                    <input
                      name="phone_number"
                      type="text"
                      className="form-control"
                      id="inputEmail3"
                      value={formDetail.phone_number}
                      onChange={changeHandler}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div className="form-group row">
            <div className="col-sm-4">
              <label>Metrics</label>
            </div>
            <div className="col-sm-8">
              <label htmlFor="inputEmail3" className="col-form-label">
                Rating
              </label>
              <div className="">
                {Boolean(formDetail.rating) && (
                  <Rating
                    name="rating"
                    value={formDetail.rating}
                    size="small"
                    onChange={onRateChangeHandler}
                  />
                )}
              </div>
            </div>
          </div>

          <hr />
          <div className="form-group row">
            <div className="col-sm-1 offset-sm-11">
              <button type="submit" className="btn btn-primary round-border">
                {buttonText}
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="section-container">
        <h2 className="vendor-title">
          Catalog
          {!isEmptyState && <span>- ({catalog.length})</span>}
          {!isEmptyState && (
            <button onClick={addVendorCatalog} type="submit">
              <IoIosAddCircle
                style={{
                  color: '#ff8123',
                }}
              />
            </button>
          )}
        </h2>

        <div
          className={cx('col-sm-12 white-background-color  round-border grey-border', {
            'empty-state': isEmptyState,
          })}
        >
          {isEmptyState && (
            <button
              className="btn btn-primary round-border round-border"
              type="button"
              onClick={addVendorCatalog}
            >
              Add a Catalog
            </button>
          )}
          {!isEmptyState && <VendorCatalogItem items={catalog} />}
        </div>
      </div>
    </div>
  );
};

export { VendorDetail };
