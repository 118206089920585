import React, { useState, useEffect, useRef } from 'react';
import { string, func, arrayOf, bool, number, shape, oneOfType } from 'prop-types';
import { useFullscreen, useToggle } from 'react-use';
import classNames from 'classnames';
import { faExpand } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavigateRightIcon, NavigateLeftIcon, CloseIcon } from '../../Image';
import { Slide } from './Slide';
import { ToolTip } from '../ToolTip';
import { data as TERMS } from '../../GenericTerms/data';
import { POINTS as NOTES } from '../../Note/data';

import './image-slider.scss';

function Arrow({ direction, clickFunction }) {
  return (
    <div onClick={clickFunction}>
      {direction === 'left' ? (
        <img src={NavigateRightIcon} alt="arrow left" />
      ) : (
        <img src={NavigateLeftIcon} alt="arrow left" />
      )}
    </div>
  );
}

Arrow.defaultProps = {
  direction: 'left',
  clickFunction: () => {},
};

Arrow.propTypes = {
  direction: string,
  clickFunction: func,
};

function ImageSlider({
  items,
  imageClassName,
  autoSlide,
  slideTimeout,
  allowFullScreen,
  displayArrows,
  displayTerms,
  displayLabel,
  className,
}) {
  const ref = useRef(null);
  const [show, toggleFullScreenMode] = useToggle(false);
  const isFullScreen = useFullscreen(ref, show, {
    onClose: () => {
      toggleFullScreenMode(false);
    },
  });
  const [imageIndex, setImageIndex] = useState(0);
  const [autoSlideIntervalId, setAutoSlideIntervalId] = useState(null);
  const [content, updateContent] = useState({
    label: '',
    original: '',
    blurred: '',
    alt: '',
  });
  // const [keyCode, updateKeyCode] = useState(null);
  const numSlides = items.length;

  const handleOnClick = {
    // fullScreenMode: state => {
    //   updateIsFullScreen(state);
    //   if (state) {
    //     handle.enter();
    //   } else {
    //     handle.exit();
    //   }
    // },
    onArrowClick: direction => {
      const increment = direction === 'left' ? 1 : -1;
      const newIndex = (imageIndex + increment + numSlides) % numSlides;
      updateContent(items[newIndex]);
      setImageIndex(newIndex);
    },
  };

  // @TODO: This effect is affecting all images. Can we isolate?
  // useEffect(() => {
  //   if (keyCode === 39) {
  //     handleOnClick.onArrowClick('right');
  //   }
  //   if (keyCode === 37) {
  //     handleOnClick.onArrowClick('left');
  //   }
  // }, [keyCode]);

  useEffect(() => {
    let currentIndex = imageIndex;
    if (autoSlide) {
      const intervalId = setInterval(() => {
        const newIndex = (currentIndex + 1 + items.length) % items.length;
        updateContent(items[newIndex]);
        // @TODO Should it be moved to else statement?
        setImageIndex(newIndex);
        currentIndex += 1;
      }, slideTimeout);
      setAutoSlideIntervalId(intervalId);
    } else if (autoSlideIntervalId !== null) {
      clearInterval(autoSlideIntervalId);
    }
  }, [autoSlide]);

  useEffect(() => {
    if (items.length > 0) {
      updateContent(items[0]);
    }
  }, [items]);

  const contentComponent = (
    // eslint-disable-next-line react/no-danger
    <span className="text-2-color" dangerouslySetInnerHTML={{ __html: TERMS.projectImages }} />
  );

  const classes = classNames('tw-w-full tw-z-50', className);
  return (
    <div ref={ref} className={classes}>
      <div
        className={
          isFullScreen
            ? 'tw-flex tw-justify-center tw-h-full'
            : 'tw-relative tw-rounded tw-shadow tw-h-full'
        }
      >
        {content.label && displayLabel && (
          <div className="tw-transition-transform tw-bg-white tw-absolute tw-top-0 tw-left-0 tw-z-50 tw-m-4 tw-p-1 tw-rounded tw-text-xs semi-bold">
            <ToolTip content={contentComponent}>
              <span className="tw-mt-1 hover:tw-text-cta">{content.label}</span>
            </ToolTip>
          </div>
        )}
        {displayArrows && items.length > 1 && (
          <div className="tw-flex tw-flex-row-reverse tw-absolute tw-z-10 tw-cursor-pointer tw-right-0 tw-h-full tw-items-center">
            <Arrow
              className={isFullScreen ? 'tw-w-8 tw-h-8' : 'tw-w-5 tw-h-5'}
              direction="left"
              clickFunction={() => handleOnClick.onArrowClick('left')}
            />
          </div>
        )}
        {allowFullScreen && isFullScreen && (
          <div
            className="tw-absolute tw-top-0 tw-right-0 tw-z-50 tw-m-4 tw-p-1 tw-rounded tw-text-xs semi-bold text-white tw-cursor-pointer"
            onClick={() => toggleFullScreenMode(false)}
          >
            <img src={CloseIcon} alt="close icon" />
          </div>
        )}
        {allowFullScreen && !isFullScreen && (
          <div
            className="tw-absolute tw-bottom-0 tw-left-0 tw-z-50 tw-m-4 tw-p-1 tw-rounded tw-text-xs semi-bold text-white tw-cursor-pointer tw-bg-cta tw-rounded tw-pl-4 tw-pr-4 tw-font-bold"
            onClick={() => toggleFullScreenMode(true)}
          >
            <span className="">
              {imageIndex + 1}/{items.length}
            </span>
            <span className="tw-pl-1">
              View in gallery{' '}
              <FontAwesomeIcon icon={faExpand} className="tw-fill-current ml-1 text-white" />
            </span>
          </div>
        )}
        {displayArrows && items.length > 1 && (
          <div className="tw-flex tw-absolute tw-z-10 tw-cursor-pointer tw-left-0 tw-h-full tw-items-center">
            <Arrow
              className={isFullScreen ? 'tw-w-8 tw-h-8' : 'tw-w-5 tw-h-5'}
              direction="right"
              clickFunction={() => handleOnClick.onArrowClick('right')}
            />
          </div>
        )}
        <Slide content={content} imageClasses={imageClassName} isFullScreen={isFullScreen} />
      </div>
      {displayTerms && (
        <div className="alert alert-warning py-2 mt-2">
          <span className="bold">*</span>
          {NOTES.referenceImages.map((point, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <span key={index} className="">
              {point}
            </span>
          ))}
        </div>
      )}
    </div>
  );
}

ImageSlider.propTypes = {
  items: arrayOf(
    shape({
      original: string.isRequired,
      alt: oneOfType([string, number]),
      blurred: string,
      label: string,
    }),
  ),
  imageClassName: string,
  autoSlide: bool,
  slideTimeout: number,
  allowFullScreen: bool,
  displayArrows: bool,
  displayTerms: bool,
  displayLabel: bool,
  className: string,
};

ImageSlider.defaultProps = {
  items: [],
  imageClassName: '',
  autoSlide: false,
  slideTimeout: 1500,
  allowFullScreen: true,
  displayArrows: true,
  displayTerms: false,
  displayLabel: true,
  className: '',
};

export { ImageSlider };
