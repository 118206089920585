import { Plugin } from '@uppy/core';

function toBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

class UppyToBase64 extends Plugin {
  constructor(uppy, opts) {
    super(uppy, opts);
    this.id = opts.id || 'UppyImageToBase64Converter';
    this.type = 'base64converted';
    this.prepareUpload = this.prepareUpload.bind(this);
  }

  prepareUpload(fileIDs) {
    const promises = fileIDs.map(fileID => {
      const file = this.uppy.getFile(fileID);
      // return Promise.resolve(this.uppy.setFileState(fileID, { data: file.data }));
      return toBase64(file.data)
        .then(result => {
          this.uppy.setFileState(fileID, { data: result });
        })
        .catch(err => {
          this.uppy.log(err, 'warning');
        });
    });
    const emitPreprocessCompleteForAll = () => {
      fileIDs.forEach(fileID => {
        const file = this.uppy.getFile(fileID);
        this.uppy.emit('preprocess-complete', file);
      });
    };
    return Promise.all(promises).then(emitPreprocessCompleteForAll);
    // return Promise.resolve();
  }

  install() {
    this.uppy.addPreProcessor(this.prepareUpload);
  }

  uninstall() {
    this.uppy.removePreProcessor(this.prepareUpload);
  }
}

export { UppyToBase64 };
