function boqCommentsReducer(
  state = {
    boqId: null,
    id: null,
    comments: [],
    form: {
      text: '',
      attachments: [],
    },
  },
  action,
) {
  switch (action.type) {
    case 'FETCH_BOQ_COMMENTS':
      return { ...state, comments: action.payload };
    case 'UPDATE_BOQ_COMMENTS_FORM':
      return { ...state, form: { ...state.form, ...action.payload } };
    case 'RESET_BOQ_COMMENTS_FORM':
      return { ...state, form: { text: '', attachments: [] } };
    default:
      return { ...state };
  }
}

export { boqCommentsReducer };
