import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { IoIosInformationCircleOutline } from 'react-icons/all';
import { string, oneOfType, arrayOf, bool, node } from 'prop-types';

const useStyles = makeStyles(theme => ({
  arrow: {
    '&:before': {
      border: '1px solid #E6E8ED',
    },
    color: theme.palette.common.white,
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[2],
    fontSize: 14,
    width: '346px',
  },
}));

function InfoToolTip({ content, placement, displayOrderingNumber, children }) {
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const classes = useStyles();

  const handleOpen = () => {
    setOpen(true);
  };

  const title = () => {
    return (
      <>
        {content.map((item, key) => (
          <div key={item} className="tw-mt-3 tw-w-full">
            {displayOrderingNumber && <span>{key + 1}.</span>}
            <span className="tw-ml-1">{item}</span>
          </div>
        ))}
      </>
    );
  };
  return (
    <Tooltip
      classes={classes}
      open={open}
      onClose={handleClose}
      onOpen={handleOpen}
      title={title()}
      placement={placement}
      arrow
    >
      <button type="button" className="tw-m-0 tw-p-0">
        {children !== undefined ? children : <IoIosInformationCircleOutline />}
      </button>
    </Tooltip>
  );
}

InfoToolTip.propTypes = {
  content: oneOfType([string, arrayOf(string)]).isRequired,
  placement: string,
  displayOrderingNumber: bool,
  children: node,
};

InfoToolTip.defaultProps = {
  placement: 'right',
  displayOrderingNumber: true,
  children: undefined,
};

export { InfoToolTip };
