import { doGet, doPatch } from 'utils/NetworkUtils';

/**
 * Mapper /dashboard
 */
class DashboardAction {
  static getEstimates() {
    return async dispatch => {
      try {
        const { estimations = [] } = await doGet({
          url: '/dashboard/estimates',
        });
        dispatch({
          type: 'FETCH_DASHBOARD',
          payload: { estimations },
        });
      } catch (e) {
        console.error(e);
      }
    };
  }

  static updateBoqTitle(boqId, title) {
    return async dispatch => {
      try {
        await doPatch({ url: `/boqs/${boqId}`, body: { title } });
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'success', message: 'Updated successfully.' },
        });
      } catch (e) {
        console.error(e);
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }
}

export { DashboardAction };
