import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import * as FullStory from '@fullstory/browser';
/* eslint-disable no-unused-vars */
// for bootstrap 4 to work

import $ from 'jquery';

/* eslint-enable no-unused-vars */

import App from './App';

import { rootReducer } from './reducers';
import { AppProvider } from './AppContext';

import './index.scss';
import './tailwind.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

import 'bootstrap/dist/js/bootstrap.min';

const store = createStore(rootReducer, applyMiddleware(thunk));

if (process.env.NODE_ENV === 'production') {
  FullStory.init({ orgId: process.env.REACT_APP_FULL_STORY_ID });
}

ReactDOM.render(
  <Provider store={store}>
    <AppProvider>
      <App />
    </AppProvider>
  </Provider>,
  document.getElementById('root'),
);
