import React, { useState } from 'react';
import { shape, string, number, func, arrayOf, bool } from 'prop-types';
import { isString } from 'lodash';
import { useSelector } from 'react-redux';

import { Amount } from 'components/Amount';
import {
  DimensionDropdown,
  SimpleSelect,
  CostSectionSkeletonLoader,
  MultiSelect,
} from 'components/Shared';

import './carpentry-form.scss';

/**
 Can we change the name to UserCarpentryForm and AdminCarpentryForm?
 */
function CarpentryForm({
  furniture,
  rooms,
  onFormUpdate,
  onFormSubmit,
  submitBtnText,
  displayCostLoader,
}) {
  const {
    description = [],
    dimension = '',
    count = 1,
    finish = '',
    material = { cost: 0, percentage: 0 },
    labour = { cost: 0, percentage: 0 },
    cost = 0,
    available_finish: availableFinish = [],
    available_dimensions: availableDimensions = [],
    is_component: isComponent = false,
    component_of: componentOf = null,
    is_loose_furniture: isLooseFurnitureFromProps = false,
  } = furniture;
  const { furniture: furnitureItems } = useSelector(state => state.boqCarpentry);

  let selectedParentId = new Set([]);
  if (componentOf !== null) {
    if (isString(componentOf)) {
      selectedParentId = new Set([componentOf]);
    } else if (componentOf instanceof Object && componentOf.id) {
      selectedParentId = new Set([componentOf.id]);
    }
  }

  const [isLooseFurniture, setLooseFurniture] = useState(isLooseFurnitureFromProps);

  return (
    <div className="w-100 ml-n4">
      {isComponent && (
        <div className="w-100 tw-mb-4">
          <div className="sub-title-2 bold ">Part of</div>
          <MultiSelect
            onRemove={() => {
              onFormUpdate({ param: 'component_of', value: null });
            }}
            searchInput
            onSelect={({ value }) => {
              onFormUpdate({ param: 'component_of', value });
            }}
            maxSelection={1}
            selectedItems={selectedParentId}
            items={furnitureItems.map(item => ({
              value: item.id,
              label: `${item.label}(${item.room})`,
            }))}
          />
        </div>
      )}
      <div>
        <div className="sub-title-2 bold ">Description</div>
        <ul>
          {description.map((line, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={index}>{line}</li>
          ))}
        </ul>
      </div>
      <div className="w-100 tw-mt-5">
        <label className="sub-title-2 bold ">Finish</label>
        <div className="tw-relative">
          <MultiSelect
            placeholder="Select"
            searchInput
            onSelect={option => {
              return onFormUpdate({
                param: 'finish',
                value: {
                  value: option.value,
                  label: option.label,
                },
              });
            }}
            maxSelection={1}
            selectedItems={new Set([finish.id])}
            items={availableFinish.map(finishItem => ({
              value: finishItem.id,
              label: finishItem.label,
            }))}
          />
        </div>
      </div>

      <div className="w-100 tw-mt-5">
        <label className="sub-title-2 bold ">Dimensions</label>
        <div>
          <DimensionDropdown
            items={availableDimensions.map(availableDimension => ({
              label: availableDimension,
              value: availableDimension,
            }))}
            value={dimension}
            onChange={value => onFormUpdate({ param: 'dimension', value })}
          />
        </div>
      </div>

      <div className="tw-flex tw-w-100 tw-space-x-5 tw-mt-5">
        <div className="tw-w-1/2">
          <label className="sub-title-2 bold ">Room</label>
          <div>
            <MultiSelect
              placeholder="Select"
              searchInput
              onSelect={option => {
                return onFormUpdate({ param: 'room', value: option.value });
              }}
              maxSelection={1}
              selectedItems={new Set([furniture.room])}
              items={rooms.map(roomItem => ({ value: roomItem.name, label: roomItem.name }))}
            />
          </div>
        </div>
        <div className="tw-w-1/2">
          <label className="sub-title-2 bold mb-1 ">Item Qty</label>
          <div>
            <SimpleSelect
              classes="tw-w-full"
              options={Array.from({ length: 10 }, (x, i) => ({
                value: i + 1,
                label: String(i + 1),
              }))}
              onChange={value => onFormUpdate({ param: 'count', value })}
              value={count}
            />
          </div>
        </div>
      </div>
      <div className="tw-w-2/3 tw-mt-5">
        <input
          className="checkbox-selector tw-mr-1"
          type="checkbox"
          id="is_loose_furniture"
          name="is_loose_furniture"
          onChange={() => {
            onFormUpdate({ param: 'is_loose_furniture', value: !isLooseFurniture });
            setLooseFurniture(!isLooseFurniture);
          }}
          checked={isLooseFurniture}
        />
        <span>Tag as loose furniture</span>
      </div>
      {displayCostLoader && <CostSectionSkeletonLoader />}
      {!displayCostLoader && (
        <div className="tw-flex tw-w-full tw-space-x-6 tw-mt-5">
          <div className="tw-w-1/3 tw-flex">
            <div>
              <label className="sub-title-1 bold ">Material and labour cost</label>
              <div className="body-1">
                <span>
                  <Amount cost={material.cost} />
                </span>
                <span className="tw-ml-1 tw-mr-1">+</span>
                <span>
                  <Amount cost={labour.cost} />
                </span>
              </div>
            </div>
          </div>
          <div
            className="tw-w-1/8 tw-flex tw-items-center tw-justify-center"
            style={{ marginLeft: 0 }}
          >
            <div className="tw-flex tw-items-center tw-justify-center tw-rounded-full tw-h-8 tw-w-8 tw-border-2  oval">
              <div>
                <div className="equals" />
                <div className="equals" />
              </div>
            </div>
          </div>
          <div className="tw-w-1/4 tw-flex tw-flex-row-reverse">
            <div>
              <label className="sub-title-1 bold  tw-m-0">Total Price</label>
              <div>
                <span className="bold sub-title-2">
                  <span>
                    <Amount cost={cost} />
                  </span>
                </span>
                <span className="tw-ml-1 font-size-special ">Inclusive tax</span>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="tw-w-2/3 tw-mt-5">
        <button
          type="button"
          className="tw-bg-cta tw-text-white tw-w-full tw-p-2"
          onClick={onFormSubmit}
        >
          {submitBtnText}
        </button>
      </div>
    </div>
  );
}

/*
  Please have a look at propTypes and defaultProps.
*/
CarpentryForm.propTypes = {
  furniture: shape({
    description: arrayOf(string),
    dimension: string,
    room: string,
    count: number,
    finish: shape({}),
    material: shape({}),
    labour: shape({}),
    available_finish: arrayOf(
      shape({
        label: string,
      }),
    ),
    available_dimensions: arrayOf(string),
  }),
  rooms: arrayOf(shape({})),
  onFormUpdate: func.isRequired,
  onFormSubmit: func.isRequired,
  submitBtnText: string,
  displayCostLoader: bool.isRequired,
};

CarpentryForm.defaultProps = {
  furniture: {
    description: [],
    dimension: '',
    room: '',
    count: 1,
    finish: {},
    material: { cost: 0, percentage: 0 },
    labour: { cost: 0, percentage: 0 },
    cost: 0,
    available_finish: [],
    available_dimensions: [],
  },
  rooms: [],
  submitBtnText: 'Add item',
};

export { CarpentryForm };
