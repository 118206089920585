import React from 'react';
import { shape, string, bool } from 'prop-types';

function CarousalSlide({ imageClasses, isFullScreen, content: { url, alt } }) {
  const classes = {
    image: `tw-rounded tw-object-fill ${
      isFullScreen ? 'tw-flex' : 'tw-absolute tw-w-full'
    } tw-h-full ${imageClasses}`,
  };

  return (
    <div className={isFullScreen ? 'tw-flex tw-justify-center' : 'tw-relative tw-pb-64'}>
      <img className={classes.image} src={url} alt={alt} />
    </div>
  );
}

CarousalSlide.defaultProps = {
  imageClasses: '',
  isFullScreen: false,
};

CarousalSlide.propTypes = {
  imageClasses: string,
  isFullScreen: bool,
  content: shape({
    url: string,
    alt: string,
  }).isRequired,
};

export { CarousalSlide };
