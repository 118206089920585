import { FETCH_FALSE_CEILING_BOQ } from '../constants/actionTypes';
import { BoqAction } from './BoqAction';
import { doGet, doPatch, doPost } from '../utils';
import { ACTION_CONSTANTS } from './constants';
import { RevisionHistoryAction } from './RevisionHistoryAction';

class FalseCeilingAction {
  static get(boqId) {
    return async dispatch => {
      try {
        const { data: payload } = await doGet({ url: `/false-ceiling?boq_id=${boqId}` });
        dispatch({ type: FETCH_FALSE_CEILING_BOQ, payload });
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static update(boqId, formData) {
    return async dispatch => {
      try {
        const { id } = formData;
        await doPatch({
          url: `/false-ceiling/${id}?boq_id=${boqId}`,
          body: {
            finish: formData.finish.label,
            carpet_area: formData.carpet_area,
            dimension: formData.dimension,
            room: formData.room,
            rate: Number(formData.rate),
          },
        });
        dispatch(BoqAction.fetchBoqDetails(boqId));
        dispatch(FalseCeilingAction.get(boqId));
        dispatch({ type: 'HIDE_VIEW_BOQ_FALSE_CEILING' });
        dispatch({
          type: 'UPDATE_BOQ_FALSE_CEILING_EDIT_MODE',
          payload: { display: false, formData: {} },
        });
        dispatch(RevisionHistoryAction.fetchList(boqId));
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'success', message: ACTION_CONSTANTS.itemUpdated },
        });
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static fetchById(boqId, id) {
    return async dispatch => {
      try {
        const { data: payload } = await doGet({ url: `/false-ceiling/${id}?boq_id=${boqId}` });
        dispatch({ type: 'UPDATE_BOQ_FALSE_CEILING_ITEM_FORM', payload });
        dispatch({ type: 'UPDATE_BOQ_FALSE_CEILING_ITEM', payload });
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static getEstimateForFalseCeilingItem(oldData, newData, action = 'update') {
    return async dispatch => {
      try {
        dispatch({
          type: 'UPDATE_BOQ_FALSE_CEILING_ITEM_STATE',
          payload: { displayCostLoader: true },
        });
        const { data: payload } = await doPost({
          url: '/false-ceiling/estimate',
          body: {
            work: newData.label,
            finish: newData.finish.label,
            carpet_area: newData.carpet_area,
            dimension: newData.dimension,
            room: newData.room,
            rate: newData.rate,
          },
        });

        payload.finish = newData.finish;
        // @TODO: Check it out later. Can be merged.
        if (action === 'new') {
          dispatch({ type: 'UPDATE_BOQ_FALSE_CEILING_WORK_ITEM', payload });
          dispatch({ type: 'UPDATE_BOQ_FALSE_CEILING_WORK_ITEM_FORM', payload });
        } else if (action === 'update') {
          dispatch({ type: 'UPDATE_BOQ_FALSE_CEILING_ITEM', payload });
          dispatch({ type: 'UPDATE_BOQ_FALSE_CEILING_ITEM_FORM', payload });
        }
      } catch (e) {
        dispatch({ type: 'UPDATE_BOQ_FALSE_CEILING_ITEM_FORM', payload: oldData });
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      } finally {
        dispatch({
          type: 'UPDATE_BOQ_FALSE_CEILING_ITEM_STATE',
          payload: { displayCostLoader: false },
        });
      }
    };
  }
}

export { FalseCeilingAction };
