import querystring from 'querystring';
import {
  FETCH_CARPENTRY_LIST,
  UPDATE_CARPENTRY_DATA_BY_CARPENTRY_ID,
} from '../constants/actionTypes';
import { doGet } from '../utils';

class CarpentryCatalogAction {
  // @TODO: logic is too confusing
  static fetchAll(obj = {}) {
    const { page = 1, filter = {}, carpentryItems = [] } = obj;
    return async dispatch => {
      try {
        const qs = querystring.stringify({
          page,
          limit: 10,
          ...filter,
        });
        const { data, meta } = await doGet({ url: `/catalog/furniture?${qs}` });
        const items = [...carpentryItems, ...data];
        dispatch({ type: FETCH_CARPENTRY_LIST, payload: { meta, data: items } });
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static fetchById(id, finish) {
    return async dispatch => {
      try {
        const { data: payload } = await doGet({ url: `/catalog/furniture/${id}` });
        dispatch({
          type: UPDATE_CARPENTRY_DATA_BY_CARPENTRY_ID,
          payload: {
            ...payload,
            finish,
            count: 1,
          },
        });
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }
}

export { CarpentryCatalogAction };
