import { isObject } from 'lodash';

import { doDelete, doGet, doPost, doPut } from 'utils';

import {
  FETCH_CARPENTRY_BOQ,
  UPDATE_CARPENTRY_LIST,
  UPDATE_BOQ_NEW_CARPENTRY_STATE,
} from '../constants/actionTypes';
import { BoqAction } from './BoqAction';
import { ACTION_CONSTANTS } from './constants';
import { RevisionHistoryAction } from './RevisionHistoryAction';
/**
 * mapped to \carpentry
 */
class CarpentryAction {
  static fetchAll(boqId) {
    return async dispatch => {
      try {
        const {
          data: { items: furniture },
        } = await doGet({ url: `/carpentry?boq_id=${boqId}` });
        const sum = furniture.reduce((totalCost, { cost: furnitureCost }) => {
          return totalCost + furnitureCost;
        }, 0);
        // furniture = sortBy(furniture, item => item.room);
        dispatch({ type: FETCH_CARPENTRY_BOQ, payload: { furniture, cost: sum } });
      } catch (e) {
        console.error(e);
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static fetchById(boqId, id) {
    return async dispatch => {
      try {
        const { data: payload } = await doGet({ url: `/carpentry/${id}?boq_id=${boqId}` });
        dispatch({ type: 'UPDATE_BOQ_CARPENTRY_ITEM_FORM', payload });
        dispatch({ type: 'UPDATE_BOQ_CARPENTRY_ITEM', payload });
      } catch (e) {
        console.error(e);
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static update(boqId, formData) {
    return async dispatch => {
      try {
        const { id } = formData;
        const data = { ...formData };
        data.finish_id = data.finish.id;
        const dimension = {};
        data.dimension
          .toLowerCase()
          .split('x')
          .forEach(item => {
            const temp = item.trim().split('');
            dimension[temp.pop()] = temp.join('');
          });
        data.dimension = dimension;
        data.finish = data.finish.label;
        delete data.available_finish;
        delete data.materials;
        if (isObject(data.component_of)) {
          data.component_of = data.component_of.id;
        }
        await doPut({ url: `/carpentry/${id}?boq_id=${boqId}`, body: data });
        dispatch({
          type: 'UPDATE_BOQ_CARPENTRY_STATE',
          payload: { displayCarpentryItem: false, formData: { count: 1 } },
        });
        dispatch(BoqAction.fetchBoqDetails(boqId));
        dispatch(CarpentryAction.fetchAll(boqId));
        dispatch({ type: 'HIDE_VIEW_FURNITURE' });
        dispatch({
          type: UPDATE_BOQ_NEW_CARPENTRY_STATE,
          payload: { displayForm: false },
        });
        dispatch({
          type: 'UPDATE_BOQ_CARPENTRY_ITEM_STATE',
          payload: { isEditMode: false },
        });
        dispatch(RevisionHistoryAction.fetchList(boqId));
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'success', message: ACTION_CONSTANTS.itemUpdated },
        });
      } catch (e) {
        console.error(e);
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static remove(boqId, furnitureId) {
    return async dispatch => {
      try {
        await doDelete({ url: `/carpentry/${furnitureId}?boq_id=${boqId}` });
        dispatch({ type: 'HIDE_VIEW_FURNITURE' });
        dispatch(CarpentryAction.fetchAll(boqId));
        dispatch(BoqAction.fetchBoqDetails(boqId));
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'success', message: ACTION_CONSTANTS.itemDeleted },
        });
        dispatch(RevisionHistoryAction.fetchList(boqId));
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static removeMany(boqId, furnitureIds) {
    return async dispatch => {
      try {
        // const futures = [];
        const urls = [];
        furnitureIds.forEach(furnitureId => {
          urls.push(`/carpentry/${furnitureId}?boq_id=${boqId}`);
          // futures.push(doDelete({ url: `/carpentry/${furnitureId}?boq_id=${boqId}` }));
        });
        // await Promise.all(futures);
        for (let i = 0; i < urls.length; i += 1) {
          /* eslint-disable no-await-in-loop */
          await doDelete({ url: urls[i] });
          /* eslint-enable no-await-in-loop */
        }
        dispatch(CarpentryAction.fetchAll(boqId));
        dispatch(BoqAction.fetchBoqDetails(boqId));
        dispatch({ type: 'UPDATE_BOQ_CARPENTRY_SELECTED_ITEMS', payload: new Set([]) }); // Should it be moved to reducer?
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'success', message: 'Removed!' },
        });
        dispatch(RevisionHistoryAction.fetchList(boqId));
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static updateList(furniture) {
    return dispatch => {
      dispatch({ type: UPDATE_CARPENTRY_LIST, payload: furniture });
    };
  }

  static getEstimateForCarpentryItem(oldData, newData) {
    return async dispatch => {
      try {
        dispatch({ type: UPDATE_BOQ_NEW_CARPENTRY_STATE, payload: { displayCostLoader: true } });
        const { data: payload } = await doPost({
          url: '/catalog/furniture/estimate',
          body: {
            ...newData,
            finish: newData.finish.label,
          },
        });
        dispatch({ type: 'UPDATE_BOQ_CARPENTRY_ITEM', payload });
        dispatch({ type: 'UPDATE_BOQ_CARPENTRY_ITEM_FORM', payload });
      } catch (e) {
        console.error(e);
        dispatch({ type: 'UPDATE_BOQ_CARPENTRY_ITEM_FORM', payload: oldData });
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      } finally {
        dispatch({
          type: UPDATE_BOQ_NEW_CARPENTRY_STATE,
          payload: { displayCostLoader: false },
        });
      }
    };
  }

  static create(boqId, formData) {
    return async dispatch => {
      try {
        const data = { ...formData };
        data.furniture = data.title;
        data.finish_id = data.finish.id;
        const dimension = {};
        data.dimension
          .toLowerCase()
          .split('x')
          .forEach(item => {
            const temp = item.trim().split('');
            dimension[temp.pop()] = temp.join('');
          });
        data.dimension = dimension;
        data.finish = data.finish.label;
        await doPost({
          url: `/carpentry?boq_id=${boqId}`,
          body: data,
        });
        dispatch({
          type: UPDATE_BOQ_NEW_CARPENTRY_STATE,
          payload: { displayForm: false, formData: { count: 1 } },
        });
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'success', message: ACTION_CONSTANTS.itemNew },
        });
        dispatch(RevisionHistoryAction.fetchList(boqId));
      } catch (e) {
        console.error(e);
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }
}

export { CarpentryAction };
