import React from 'react';
import { node } from 'prop-types';

import { doGet } from 'utils/NetworkUtils';

const AppContext = React.createContext();
const { Provider, Consumer } = AppContext;

class AppProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      layouts: [],
      budgetTypes: [],
      menus: [],
      onMenuItemClick: this.handleMenuItemClick,
      navigateToHome: this.handleHomeNavigation,
    };
  }

  componentDidMount() {
    doGet({ url: '/' })
      .then(data => {
        this.setState({
          layouts: data.layouts,
          budgetTypes: data.budget,
          menus: data.menus,
        });

        // if the page refresh happens
        const { pathname } = window.location;
        this.handleMenuItemClick(pathname);
      })
      .catch(() => {
        console.error('');
      });
  }

  handleMenuItemClick = href => {
    const skippedLinks = ['/login'];
    if (skippedLinks.includes(href)) {
      return;
    }
    const { menus } = this.state;
    this.setState({
      menus: menus.map(menu => ({
        ...menu,
        isActive: href.includes(menu.href),
      })),
    });
  };

  handleHomeNavigation = () => {
    const { menus } = this.state;
    this.setState({
      menus: menus.map(menu => ({
        ...menu,
        isActive: false,
      })),
    });
  };

  render() {
    const { children } = this.props;
    return (
      <Provider
        value={{
          ...this.state,
        }}
      >
        {children}
      </Provider>
    );
  }
}

AppProvider.propTypes = {
  children: node.isRequired,
};

export default AppContext;
export { AppProvider, Consumer as AppConsumer };
