const SUCCESS = 'SUCCESS';
const ERROR = 'ERROR';
const FORM_ERROR = 'FORM_ERROR';
const API_ERROR = 'API_ERROR';

const FETCH_BOQ_DETAILS_FROM_API = 'FETCH_BOQ_DETAILS_FROM_API';

const FETCH_CARPENTRY_BOQ = 'FETCH_CARPENTRY_BOQ';
const UPDATE_CARPENTRY_BOQ = 'UPDATE_CARPENTRY_BOQ';
const UPDATE_CARPENTRY_LIST = 'UPDATE_CARPENTRY_LIST';
const UPDATE_CARPENTRY_COST = 'UPDATE_CARPENTRY_COST';
const REMOVE_CARPENTRY_ITEM = 'REMOVE_CARPENTRY_COST';

const FETCH_FALSE_CEILING_BOQ = 'FETCH_FALSE_CEILING_BOQ';
const UPDATE_FALSE_CEILING_BOQ = 'UPDATE_FALSE_CEILING_BOQ';

const FETCH_PAINTING_BOQ = 'FETCH_PAINTING_BOQ';
const UPDATE_PAINTING_BOQ = 'UPDATE_PAINTING_BOQ';

const FETCH_ELECTRICAL_BOQ = 'FETCH_ELECTRICAL_BOQ';
const UPDATE_ELECTRICAL_BOQ = 'UPDATE_ELECTRICAL_BOQ';

const FETCH_FINISH_FOR_DROPDOWN = 'FETCH_FINISH_FOR_DROPDOWN';

const FETCH_UNDETECTED_CARPENTRY = 'FETCH_UNDETECTED_CARPENTRY';
const UPDATE_UNDETECTED_CARPENTRY = 'UPDATE_UNDETECTED_CARPENTRY';
const MIGRATE_UNDETECTED_CARPENTRY = 'MIGRATE_UNDETECTED_CARPENTRY';

const FETCH_FINISHES_FOR_FURNITURE = 'FETCH_FINISHES_FOR_FURNITURE';

const FETCH_CIVIL_BOQ = 'FETCH_CIVIL_BOQ';

const FETCH_CIVIL_LABOUR_ANALYSIS = 'FETCH_CIVIL_LABOUR_ANALYSIS';
const FETCH_CARPENTRY_LABOUR_ANALYSIS = 'FETCH_CARPENTRY_LABOUR_ANALYSIS';

const FETCH_CIVIL_MATERIAL_ANALYSIS = 'FETCH_CIVIL_MATERIAL_ANALYSIS';
const FETCH_CARPENTRY_MATERIAL_ANALYSIS = 'FETCH_CARPENTRY_MATERIAL_ANALYSIS';

const CREATE_ROOM = 'CREATE_ROOM';
const FETCH_ALL_ROOMS = 'FETCH_ALL_ROOMS';
const FETCH_ROOM = 'FETCH_ROOM';
const UPDATE_ROOM = 'UPDATE_ROOM';
const DELETE_ROOM = 'DELETE_ROOM';
const UPDATE_ROOM_FORM = 'UPDATE_ROOM_FORM';

const UPDATE_REDIRECT = 'UPDATE_REDIRECT';

const FETCH_ROOMS_IN_BOQ = 'FETCH_ROOMS_IN_BOQ';

const FETCH_CARPENTRY_LIST = 'FETCH_CARPENTRY_LIST';
const UPDATE_BOQ_NEW_CARPENTRY_FORM = 'UPDATE_BOQ_NEW_CARPENTRY_FORM';
const UPDATE_BOQ_NEW_CARPENTRY_STATE = 'UPDATE_BOQ_NEW_CARPENTRY_STATE';
const UPDATE_CARPENTRY_DATA_BY_CARPENTRY_ID = 'UPDATE_CARPENTRY_DATA_BY_CARPENTRY_ID';

const UPDATE_BOQ_CARPENTRY_ITEM_STATE = 'UPDATE_BOQ_CARPENTRY_ITEM_STATE';

export {
  SUCCESS,
  ERROR,
  FORM_ERROR,
  API_ERROR,
  FETCH_BOQ_DETAILS_FROM_API,
  FETCH_CARPENTRY_BOQ,
  UPDATE_CARPENTRY_BOQ,
  UPDATE_CARPENTRY_LIST,
  UPDATE_CARPENTRY_COST,
  REMOVE_CARPENTRY_ITEM,
  FETCH_FINISH_FOR_DROPDOWN,
  FETCH_FALSE_CEILING_BOQ,
  UPDATE_FALSE_CEILING_BOQ,
  FETCH_PAINTING_BOQ,
  UPDATE_PAINTING_BOQ,
  FETCH_ELECTRICAL_BOQ,
  UPDATE_ELECTRICAL_BOQ,
  FETCH_UNDETECTED_CARPENTRY,
  UPDATE_UNDETECTED_CARPENTRY,
  MIGRATE_UNDETECTED_CARPENTRY,
  FETCH_FINISHES_FOR_FURNITURE,
  FETCH_CIVIL_BOQ,
  FETCH_CIVIL_LABOUR_ANALYSIS,
  FETCH_CARPENTRY_LABOUR_ANALYSIS,
  FETCH_CARPENTRY_MATERIAL_ANALYSIS,
  FETCH_CIVIL_MATERIAL_ANALYSIS,
  CREATE_ROOM,
  FETCH_ALL_ROOMS,
  FETCH_ROOM,
  UPDATE_ROOM,
  DELETE_ROOM,
  UPDATE_ROOM_FORM,
  UPDATE_REDIRECT,
  FETCH_ROOMS_IN_BOQ,
  FETCH_CARPENTRY_LIST,
  UPDATE_BOQ_NEW_CARPENTRY_FORM,
  UPDATE_BOQ_NEW_CARPENTRY_STATE,
  UPDATE_CARPENTRY_DATA_BY_CARPENTRY_ID,
  UPDATE_BOQ_CARPENTRY_ITEM_STATE,
};
