import React from 'react';
import { number, func } from 'prop-types';
import { Pagination } from 'react-bootstrap';

const Paginator = ({ totalCount, totalPages, currentPage, onItemClick }) => {
  if (totalCount === 0 || !totalCount || totalPages === 1) {
    return null;
  }
  const items = [];
  for (let pageNumber = 0; pageNumber < totalPages; pageNumber = pageNumber + 1) {
    const currentPageNumber = pageNumber + 1;
    items.push(
      <Pagination.Item
        key={pageNumber}
        active={pageNumber === currentPage}
        onClick={() => {
          onItemClick(pageNumber);
        }}
      >
        {currentPageNumber}
      </Pagination.Item>,
    );
  }
  return (
    <div className="search-footer">
      <Pagination>{items}</Pagination>
    </div>
  );
};

Paginator.propTypes = {
  totalCount: number.isRequired,
  totalPages: number.isRequired,
  currentPage: number.isRequired,
  onItemClick: func.isRequired,
};

export { Paginator };
