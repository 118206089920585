import React from 'react';

import woodData from './data/wood.json';
import externalFinishes from './data/external-finishes.json';
import blockBoard from './data/block-board.json';
import gypsumData from './data/gypsum.json';
import sheraPly from './data/shera-ply.json';
import wallpaper from './data/wallpaper.json';
import cncJali from './data/cnc-jali.json';
import mdfJali from './data/mdf-jali.json';
import paint from './data/paints.json';

import { TableSection } from '../TableSection';
import { FootNotes } from '../FootNotes';

const CarpentryAssumption = () => {
  const terms = [
    'All wood & wood pattis need to be polished or painted as per design & instructions given by the designer or architect.',
    'Rates may vary from brand to brand and depends upon the selection. These are average rates, which we user for at all Hipcouch projects.',
    'Shera ply need to be treated in paints or polish as per the design',
    'Gypsum Sheets need to be treated in paints or other treatments like texture paint or wallpaper, etc. as per design & instructions given by designer & architect.',
    'Wallpaper roll size may vary for brands. Above rates are inclusive of installation charges.',
    'Texture Paint cost may vary for brands & depends upon the texture selection.',
    'Rates for jali is not inclusive of installation charges.',
  ];
  return (
    <div>
      <TableSection {...woodData} />
      <TableSection {...externalFinishes} />
      <TableSection {...blockBoard} />
      <TableSection {...gypsumData} />
      <TableSection {...sheraPly} />
      <TableSection {...wallpaper} />
      <TableSection {...cncJali} />
      <TableSection {...mdfJali} />
      <TableSection {...paint} />
      <FootNotes terms={terms} />
    </div>
  );
};

export { CarpentryAssumption };
