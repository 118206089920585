import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { ErrorView } from 'components/ErrorView';
import withAuth from 'components/Login/withAuth';
import { Home } from 'components/Home';
import ResourceCenter from 'components/ResourceCenter';
import { ConfirmationModal } from 'components/Shared';
import {
  FurnitureCatalog,
  FalseCeilingCatalog,
  PaintingCatalog,
  ElectricalCatalog,
  CivilCatalog,
} from 'components/Catalog';
import {
  CarpentryFinish,
  CivilFinish,
  EditFinish,
  FalseCeilingFinish,
  EditCivilFinish,
  EditFalseCeilingFinish,
} from 'components/Finish';
import { Material } from 'components/Material';
import { Settings } from 'components/Settings';
import { Footer } from 'components/Footer';
import { MenuBar } from 'components/MenuBar';
import { NotFound } from 'components/NotFound';
import { Login } from 'components/Login';
import { ForgotPassword } from 'components/ForgotPassword';
import { ResetPassword } from 'components/ResetPassword';
import { ListRooms, AddRoom, EditRoom } from 'components/Rooms';
import { EditFurniture, EditPainting, EditFalseCeiling } from 'components/Catalog/Edit';
import { EditElectrical } from 'components/Catalog/Edit/EditElectrical';
import { EditCivil } from 'components/Catalog/Edit/EditCivil';
import { EditFurniture as BoqEditFurniture } from 'components/EditFurniture';
import { Carousal } from 'components/Carousal';
import { LoginModal } from 'components/LoginModal';
import { Toast } from 'components/Shared/Toast';
import { UploadPage } from 'components/Home/AuthHomePage';
import { ViewEditRatePopover } from 'components/ViewEditRatePopover';
import withAnalyticsTracker from 'hooks/withAnalyticsTracker';
import { Loader } from 'components/Loader';
import ConsultWithUs from 'components/ConsultWithUs';
import { ViewConfirmationModal } from 'components/ViewConfirmationModal/ViewConfirmationModal';
import { ProfileSetup } from 'components/SignUp';
import Report from 'components/ResourceCenter/Report';
import Request from 'components/ResourceCenter/Request';
import FAQ from 'components/ResourceCenter/FAQ';
import Calculator from 'components/ResourceCenter/Calculator';
import Support from 'components/ResourceCenter/Support';
import Assumptions from 'components/ResourceCenter/Assumptions';
import Glossary from 'components/ResourceCenter/Glossary';
import Updates from 'components/ResourceCenter/Updates';
import { Vendor } from 'components/Vendor';
import { ViewProfileSetup } from 'components/ViewProfileSetup';

import { componentLoader } from 'utils';
import { UserProvider } from './UserContext';
import './styles/app.scss';
import './App.scss';
import 'lightgallery/scss/lightgallery.scss';
import 'lightgallery/scss/lg-zoom.scss';

/* prettier-ignore */ const FurnitureDetails = lazy(() => componentLoader(() => import('components/BoqDetail/FurnitureDetails/index')));
// eslint-disable-next-line max-len
// const Gallery = lazy(() => componentLoader(() => import('components/BoqDetail/FurnitureDetails/Gallery')));
/* prettier-ignore */ const FurnitureDetailsEdit = lazy(() => componentLoader(() => import('components/BoqDetail/FurnitureDetails/Edit')));
/* prettier-ignore */ const FurnitureDetailsList = lazy(() => componentLoader(() => import('components/BoqDetail/FurnitureDetails/List')));
/* prettier-ignore */ const FurnitureDetailsAdd = lazy(() => componentLoader(() => import('components/BoqDetail/FurnitureDetails/Add')));
/* prettier-ignore */ const LightGallery = lazy(() => componentLoader(() => import('lightgallery/react')));

const Boq = lazy(() => {
  return componentLoader(() => import(/* webpackChunkName: "Boq" */ 'components/Boq'));
});
const BoqDetail = lazy(() => {
  return componentLoader(() => import(/* webpackChunkName: "BoqDetail" */ 'components/BoqDetail'));
});

const Dashboard = lazy(() => {
  return componentLoader(() => import(/* webpackChunkName: "Dashboard" */ 'components/Dashboard'));
});
const Explore = lazy(() => {
  return componentLoader(() => import(/* webpackChunkName: "Explore" */ 'components/Explore'));
});

const Summary = lazy(() => {
  return componentLoader(() => import(/* webpackChunkName: "Summary" */ 'components/Summary'));
});
// These are being used at multiple place for web and mobile.
// Need to optimize these for proper invocation.
/* prettier-ignore */
const ViewConsultWithUs = lazy(() => componentLoader(() => import(/* webpackChunkName: "ViewConsultWithUs" */'components/ViewConsultWithUs'), 3));
/* prettier-ignore */
const ProductDemo = lazy(() => componentLoader(() => import(/* webpackChunkName: "ViewProductDemo" */ 'components/ViewProductDemo'), 3));
/* prettier-ignore */
const ViewAssumption = lazy(() => componentLoader(() => import(/* webpackChunkName: "ViewAssumption" */'components/ViewAssumption'), 3));
/* prettier-ignore */
const ViewScheduleGraph = lazy(() => componentLoader(() => import(/* webpackChunkName: "ViewScheduleGraph" */'components/ViewScheduleGraph'), 3));

Bugsnag.start({
  apiKey: 'e0af4ad05098aeb1822e2529baf08805',
  plugins: [new BugsnagPluginReact()],
});

let ErrorBoundary = ErrorView;
if (process.env.NODE_ENV === 'production') {
  ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
}

function mapRoutes() {
  return (
    <Switch>
      <Route exact path="/" component={withAnalyticsTracker(Home)} />
      <Route exact path="/boqs/upload" component={UploadPage} />
      <Route exact path="/report-bug" component={Report} />
      <Route exact path="/request-a-feature" component={Request} />
      <Route exact path="/frequently-asked-questions" component={FAQ} />
      <Route exact path="/calculator" component={Calculator} />
      <Route exact path="/support" component={Support} />
      <Route exact path="/assumptions" component={Assumptions} />
      <Route exact path="/glossary" component={Glossary} />
      <Route exact path="/updates" component={Updates} />
      <Route exact path="/boqs/:boqId/summary" component={withAnalyticsTracker(Summary)} />
      <Route exact path="/boqs/:boqId/details/add" component={withAuth(FurnitureDetailsList)} />
      <Route
        exact
        path="/boqs/:boqId/details/add/:furnitureId/:finishId"
        component={withAuth(FurnitureDetailsAdd)}
      />

      <Route
        exact
        path="/boqs/:boqId/details/:furnitureId"
        component={withAuth(FurnitureDetails)}
      />

      <Route
        exact
        path="/boqs/:boqId/details/:furnitureId/gallery"
        component={withAuth(LightGallery)}
      />

      <Route
        exact
        path="/boqs/:boqId/details/:furnitureId/edit"
        component={withAuth(FurnitureDetailsEdit)}
      />
      <Route exact path="/boqs/:boqId/details" component={withAuth(BoqDetail)} />
      <Route exact path="/boqs" component={withAuth(Boq)} />
      <Route exact path="/boqs/:boqId/furniture/:furnitureId" component={BoqEditFurniture} />
      <Route exact path="/catalog/furniture/:furnitureId" component={withAuth(EditFurniture)} />
      <Route exact path="/catalog/furniture" component={withAuth(FurnitureCatalog)} />
      <Route
        exact
        path="/catalog/false-ceiling/:falseCeilingId"
        component={withAuth(EditFalseCeiling)}
      />
      <Route exact path="/catalog/false-ceiling" component={withAuth(FalseCeilingCatalog)} />
      <Route exact path="/catalog/painting/:paintingId" component={withAuth(EditPainting)} />
      <Route exact path="/catalog/painting" component={withAuth(PaintingCatalog)} />
      <Route exact path="/catalog/electrical/:electricalId" component={withAuth(EditElectrical)} />
      <Route exact path="/catalog/electrical" component={withAuth(ElectricalCatalog)} />
      <Route exact path="/catalog/civil/:civilId" component={withAuth(EditCivil)} />
      <Route exact path="/catalog/civil" component={withAuth(CivilCatalog)} />
      <Route path="/finishes/furniture/:finishId" component={withAuth(EditFinish)} />
      <Route path="/finishes/furniture" component={withAuth(CarpentryFinish)} />
      <Route exact path="/finishes/civil/:finishId" component={withAuth(EditCivilFinish)} />
      <Route exact path="/finishes/civil" component={withAuth(CivilFinish)} />
      <Route
        exact
        path="/finishes/false-ceiling/:finishId"
        component={withAuth(EditFalseCeilingFinish)}
      />
      <Route exact path="/finishes/false-ceiling" component={withAuth(FalseCeilingFinish)} />
      <Route path="/materials" component={withAuth(Material)} />
      <Route exact path="/dashboard" component={withAuth(Dashboard)} />
      <Route path="/explore" component={withAnalyticsTracker(Explore)} />
      <Route path="/settings" component={Settings} />
      <Route path="/login" component={withAnalyticsTracker(Login)} />
      <Route path="/signup" component={withAnalyticsTracker(Login)} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route exact path="/rooms/:roomId/edit" component={withAuth(EditRoom)} />
      <Route exact path="/rooms/new" component={withAuth(AddRoom)} />
      <Route exact path="/rooms" component={withAuth(ListRooms)} />
      <Route exact path="/consult-with-us" component={ConsultWithUs} />
      <Route path="/vendors" component={Vendor} />
      <Route exact path="/complete-profile" component={ProfileSetup} />
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
}

function suspenseView() {
  return (
    <div>
      <Loader loading />
    </div>
  );
}

function App() {
  return (
    <div className="App">
      <ErrorBoundary FallbackComponent={ErrorView}>
        <GoogleOAuthProvider clientId="30354100037-t2lt4t5nmkocvf11qfkv9gj719li2itf.apps.googleusercontent.com">
          <Router>
            <UserProvider>
              <Suspense fallback={suspenseView()}>
                <MenuBar />
                <ResourceCenter />
                <div className="app-body">{mapRoutes()}</div>
                {/* modals that should use state data to render and work */}
                <Toast />
                <LoginModal />
                <Carousal />
                <Footer />
                <ViewAssumption />
                <ConfirmationModal />
                <ViewConsultWithUs />
                <ProductDemo />
                <ViewScheduleGraph />
                <ViewEditRatePopover />
                <ViewConfirmationModal />
                <ViewProfileSetup />
              </Suspense>
            </UserProvider>
          </Router>
        </GoogleOAuthProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
