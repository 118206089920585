import React from 'react';
import { arrayOf, shape, func, bool, string, oneOfType } from 'prop-types';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

function AutoCompleteSelect({ options, onChange, value, disabled, classes }) {
  return (
    <Autocomplete
      filterOptions={opts => {
        return opts.filter(item => item.label.match(new RegExp(value.label, 'i')));
      }}
      disabled={disabled}
      className={`${classes}`}
      disableClearable
      options={options}
      getOptionLabel={option => option.label}
      onChange={(event, newValue) => onChange(newValue.value)}
      value={value || ''}
      renderInput={params => (
        <TextField
          {...params}
          style={{ margin: 0 }}
          margin="normal"
          variant="outlined"
          size="small"
          onChange={event => onChange(event.target.value)}
          InputProps={{ ...params.InputProps, type: 'search' }}
        />
      )}
    />
  );
}

AutoCompleteSelect.propTypes = {
  options: arrayOf(
    shape({
      label: string,
    }),
  ).isRequired,
  onChange: func.isRequired,
  disabled: bool,
  value: oneOfType([string, shape({})]),
  classes: string,
};

AutoCompleteSelect.defaultProps = {
  disabled: false,
  value: '',
  classes: '',
};

export { AutoCompleteSelect };
