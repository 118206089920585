const defaultState = {
  finishes: [],
  vendors: [],
  brands: [],
  patterns: [],
};

const vendorReducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'FETCH_VENDOR':
      return { ...state, ...action.payload };
    case 'UPDATE_CATEGORY': {
      const { finishes } = state;
      const { slug } = action.payload;
      const otherFinishes = finishes.filter(finish => finish.slug !== slug);
      return {
        ...state,
        finishes: [...otherFinishes, action.payload],
      };
    }
    case 'UPDATE_VENDOR_DETAIL': {
      const { vendors } = state;
      const { slug } = action.payload;
      const other = vendors.filter(vendor => vendor.slug !== slug);
      return {
        ...state,
        vendors: [...other, action.payload],
      };
    }
    default:
      return { ...state };
  }
};

export { vendorReducer };
