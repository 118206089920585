import { toInteger } from 'lodash';

import { FETCH_PAINTING_BOQ } from '../constants/actionTypes';
import { BoqAction } from './BoqAction';
import { doDelete, doGet, doPatch, doPost } from '../utils';
import { ACTION_CONSTANTS } from './constants';
import { RevisionHistoryAction } from './RevisionHistoryAction';

/**
 * mapped to /painting
 */
class PaintingAction {
  static get(boqId) {
    return async dispatch => {
      try {
        const { data: payload } = await doGet({ url: `/painting?boq_id=${boqId}` });
        dispatch({ type: FETCH_PAINTING_BOQ, payload });
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static fetchById(boqId, id) {
    return async dispatch => {
      try {
        const { data: payload } = await doGet({ url: `/painting/${id}?boq_id=${boqId}` });
        dispatch({ type: 'UPDATE_BOQ_PAINTING_ITEM_FORM', payload });
        dispatch({ type: 'UPDATE_BOQ_PAINTING_ITEM', payload });
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static update(boqId, formData) {
    return async dispatch => {
      try {
        const { id } = formData;
        await doPatch({
          url: `/painting/${id}?boq_id=${boqId}`,
          body: {
            carpet_area: Number(formData.carpet_area),
            dimension: formData.dimension,
            room: formData.room,
            rate: formData.rate,
          },
        });
        dispatch(BoqAction.fetchBoqDetails(boqId));
        dispatch(PaintingAction.get(boqId));
        dispatch(RevisionHistoryAction.fetchList(boqId));
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'success', message: ACTION_CONSTANTS.itemUpdated },
        });
        dispatch({ type: 'HIDE_VIEW_BOQ_PAINTING' });
        dispatch({
          type: 'UPDATE_BOQ_PAINTING_EDIT_MODE',
          payload: { display: false, formData: {} },
        });
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      }
    };
  }

  static getEstimateForPaintingItem(oldData, newData, action = 'update') {
    return async dispatch => {
      try {
        dispatch({
          type: 'UPDATE_BOQ_PAINTING_ITEM_STATE',
          payload: { displayCostLoader: true },
        });
        const { data: payload } = await doPost({
          url: '/painting/estimate',
          body: {
            work: newData.label,
            carpet_area: Number(newData.carpet_area),
            dimension: newData.dimension,
            room: newData.room,
            rate: toInteger(newData.rate),
          },
        });

        // @TODO: Check it out later. Can be merged.
        if (action === 'new') {
          dispatch({ type: 'UPDATE_BOQ_PAINTING_WORK_ITEM', payload });
          dispatch({ type: 'UPDATE_BOQ_PAINTING_WORK_ITEM_FORM', payload });
        } else if (action === 'update') {
          dispatch({ type: 'UPDATE_BOQ_PAINTING_ITEM', payload });
          dispatch({ type: 'UPDATE_BOQ_PAINTING_ITEM_FORM', payload });
        }
      } catch (e) {
        dispatch({ type: 'UPDATE_BOQ_PAINTING_ITEM_FORM', payload: oldData });
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.message },
        });
      } finally {
        dispatch({
          type: 'UPDATE_BOQ_PAINTING_ITEM_STATE',
          payload: { displayCostLoader: false },
        });
      }
    };
  }

  static remove(boqId, id) {
    return async dispatch => {
      try {
        await doDelete({ url: `/painting/${id}?boq_id=${boqId}` });
        dispatch({ type: 'HIDE_VIEW_CIVIL' });
        dispatch(BoqAction.fetchBoqDetails(boqId));
        dispatch(PaintingAction.get(boqId));
        dispatch(RevisionHistoryAction.fetchList(boqId));
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'success', message: 'Removed!' },
        });
      } catch (e) {
        dispatch({
          type: 'DISPLAY_TOAST',
          payload: { type: 'error', message: e.response.data.message },
        });
      }
    };
  }
}

export { PaintingAction };
