import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BrowserView, MobileView } from 'react-device-detect';

import { TabbedPanel } from '../Shared/TabbedPanel';
import { CivilAssumption } from '../ViewAssumption/CivilAssumptions';
import { CarpentryAssumption } from '../ViewAssumption/CarpentryAssumptions';

const Glossary = () => {
  const {
    // eslint-disable-next-line no-unused-vars
    viewAssumption: { show = false, data: { loadTab } = {} },
  } = useSelector(state => state.modal);
  const [selectedTab, setSelectedTab] = useState('civil');

  const tabs = [
    {
      title: 'Civil Glossary',
      value: 'civil',
      component: <CivilAssumption />,
      viewAvailableFor: new Set(['web']),
    },
    {
      title: 'Carpentry Glossary',
      value: 'carpentry',
      component: <CarpentryAssumption />,
      viewAvailableFor: new Set(['web']),
    },
  ];

  const handleTabClick = tab => setSelectedTab(tab);
  const { component } = tabs.find(tab => tab.value === selectedTab);

  return (
    <div className="tw-container tw-mx-auto">
      <div className="tw-bg-white tw-shadow-xl tw-m-8 tw-p-4 tw-rounded-lg">
        <ul className="tw-flex tw-flex-row">
          <li className="tw-text-text2 tw-mr-2">
            <Link to="/">Home</Link>
          </li>
          <li className="tw-text-text2 tw-mr-2">/</li>
          <li className="tw-text-text2 tw-mr-2 tw-text-opacity-50">Glossary</li>
        </ul>
        <BrowserView>
          <div className="other-assumptions">
            <h2
              className="tw-text-text2 tw-font-bold tw-mb-8"
              style={{ fontSize: '2rem', margin: '1em 0' }}
            >
              Glossary
            </h2>
            <div className="assumptions-body" style={{ padding: '0', overflow: 'scroll' }}>
              <TabbedPanel
                align="start"
                onTabClick={handleTabClick}
                selectedTab={selectedTab}
                bodyStyle={{
                  backgroundColor: '#fff',
                }}
                tabs={tabs.filter(tab => tab.viewAvailableFor.has('web'))}
              >
                {component}
              </TabbedPanel>
            </div>
          </div>
        </BrowserView>
        <MobileView>
          <div className="mobile-other-assumptions">
            <h2 className="tw-text-text2 tw-font-bold tw-mb-8">Glossary</h2>
            <div className="assumptions-body" style={{ overflow: 'scroll' }}>
              <TabbedPanel
                align="start"
                onTabClick={handleTabClick}
                selectedTab={selectedTab}
                bodyStyle={{
                  backgroundColor: '#fafafa',
                }}
                tabs={tabs.filter(tab => tab.viewAvailableFor.has('mobile'))}
              >
                {component}
              </TabbedPanel>
            </div>
          </div>
        </MobileView>
      </div>
    </div>
  );
};

export default Glossary;
