import React from 'react';
import { bool, func, string } from 'prop-types';

import { IoMdTrash } from 'react-icons/io';

import classNames from 'classnames';

function DeleteBtn({ onClick, text, className, disabled }) {
  const classes = classNames('tw-flex', className);
  return (
    <button type="button" className={classes} onClick={onClick} {...(disabled ? 'disabled' : '')}>
      <IoMdTrash className="tw-h-5 tw-w-5 tw-fill-current tw-text-cta " />
      <span className="cta tw-ml-1  d-none d-md-flex">{text}</span>
    </button>
  );
}

DeleteBtn.propTypes = {
  className: string,
  onClick: func.isRequired,
  text: string,
  disabled: bool,
};

DeleteBtn.defaultProps = {
  className: '',
  text: 'Delete',
  disabled: false,
};

export { DeleteBtn };
