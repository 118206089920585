import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { Table } from '../Shared/Table';

import './faq.scss';

const FAQ = () => {
  const [index, setIndex] = useState();

  const handleSelect = selectedIndex => {
    if (selectedIndex === index) {
      setIndex(-1);
      return;
    }
    setIndex(selectedIndex);
  };

  return (
    <div className="faq-page">
      <div className="tw-container tw-mx-auto">
        <div className="tw-bg-white tw-shadow-xl tw-m-8 tw-p-4 tw-rounded-lg">
          <ul className="tw-flex tw-flex-row">
            <li className="tw-text-text2 tw-mr-2">
              <Link to="/">Home</Link>
            </li>
            <li className="tw-text-text2 tw-mr-2">/</li>
            <li className="tw-text-text2 tw-mr-2 tw-text-opacity-50">FAQ</li>
          </ul>
          <h2
            className="tw-text-text2 tw-font-bold tw-mb-8"
            style={{ fontSize: '2rem', margin: '1em 0' }}
          >
            Frequently Asked Questions
          </h2>

          <Accordion>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="0"
                onClick={() => handleSelect(0)}
                className={classNames('', index === 0 ? 'active' : '')}
              >
                <p className="faq-question">
                  What is the Hipcouch Cost Estimate Calculator for home interiors?
                </p>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    Our Cost Estimate Calculator is an easy and transparent way to instantly
                    calculate the cost of designing, furnishing and renovating your home.
                  </p>
                  <p>
                    Enter a few basic details about your home and our calculator will instantly
                    generate an accurate and detailed report that gives you the overall cost of the
                    project, timeline and a break up of all the materials that will be used. The
                    report gives you complete clarity on every detail from design fee to labour cost
                    and material costs.
                  </p>
                  <p>
                    What’s more you can tweak the report, by adding, removing and changing
                    items/materials and you will see a revised cost and timeline in realtime.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="1"
                onClick={() => handleSelect(1)}
                className={classNames('', index === 1 ? 'active' : '')}
              >
                <p className="faq-question">How can I best use this cost estimate?</p>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <ul>
                    <li>
                      As an accurate starting point for you to start planning your home interiors
                      project, in terms of time and budget.
                    </li>
                    <li>
                      As a reference when selecting/negotiating with designers/ contractors etc.
                    </li>
                    <li>
                      To instantly see how your project cost changes when you add/ remove new
                      materials, finishes and items to your project.
                    </li>
                  </ul>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="2"
                onClick={() => handleSelect(2)}
                className={classNames('', index === 2 ? 'active' : '')}
              >
                <p className="faq-question">
                  I have received lower rates and a cheaper cost estimate from an interior
                  designer/contractor? How could this have happened?
                </p>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p>
                    An Interior designer/ contractor’s fee varies depending on multiple factors such
                    as their qualification and experience, your location and the overall budget of
                    your project and finally the level of detail of the BOQ (estimate) they provide
                    you. Many designers initially offer a rough estimate and only provide a detailed
                    estimate when they are assured of getting your project.
                  </p>
                  <p>
                    Similarly there is a wide variety of materials and finishes that can be used to
                    complete your interiors. The cost of your your project will fluctuate depending
                    on the quality of materials used.
                  </p>
                  <p>
                    Our cost estimates were built using the rates in Mumbai for high quality
                    materials and experienced designers/ contractors / labor. These rates are not
                    meant to be the cheapest rates, they are rates at which a high quality
                    end-product is delivered.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="3"
                onClick={() => handleSelect(3)}
                className={classNames('', index === 3 ? 'active' : '')}
              >
                <p className="faq-question">
                  How accurate is the cost estimate provided by the Hipcouch Cost Estimate
                  Calculator?
                </p>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <p>
                    The estimate generated by the calculator is very accurate and will be within +/-
                    5% of the actual final cost. The tool allows for a great deal of customisation
                    after the estimate is generated. The more inputs you provide, the greater the
                    accuracy of your estimate.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="4"
                onClick={() => handleSelect(4)}
                className={classNames('', index === 4 ? 'active' : '')}
              >
                <p className="faq-question">Can I customise this estimate for my apartment?</p>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="4">
                <Card.Body>
                  <p>
                    Our cost estimate calculator allows for a great deal of customisation after the
                    estimate is generated. You can add, delete or modify items as per your
                    requirement and you will end up with an accurate estimate that is customised for
                    your space.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="5"
                onClick={() => handleSelect(5)}
                className={classNames('', index === 5 ? 'active' : '')}
              >
                <p className="faq-question">
                  Is this cost estimate for a Warm Shell apartment, Bare Shell apartment or a
                  complete home renovation?
                </p>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="5">
                <Card.Body>
                  <p>
                    The cost estimate is generated for new apartments which are warm shell
                    apartments handed over by the builder.
                  </p>
                  <p>
                    However the cost estimate can be customised to give an accurate estimate for
                    bare shell apartments and apartments that need complete renovation.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="6"
                onClick={() => handleSelect(6)}
                className={classNames('', index === 6 ? 'active' : '')}
              >
                <p className="faq-question">
                  Labor and material rates vary based on location. Which cities can your cost
                  estimate be used for?{' '}
                </p>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="6">
                <Card.Body>
                  <p>
                    Our calculator currently generates cost estimates using Mumbai rates for good
                    quality material and experienced contractors / labor. We are in the process of
                    updating the tool to include more cities.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="9"
                onClick={() => handleSelect(9)}
                className={classNames('', index === 9 ? 'active' : '')}
              >
                <p className="faq-question">
                  If I am in another city (not Mumbai), can I use the tool to get my cost estimate?
                </p>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="9">
                <Card.Body>
                  <p>
                    Yes you can. Refer to the table below to see how you can get approximate rates
                    for your city.
                  </p>
                  <Table
                    headers={[
                      { title: 'City Name' },
                      { title: 'Avg. Labor Rates (as compared to Mumbai)' },
                      { title: 'Avg. Material Rates (as compared to Mumbai)' },
                      { title: 'Overall Impact to Your Cost Estimate' },
                    ]}
                  >
                    {[
                      {
                        city: 'Delhi',
                        labourRate: '7% to 8% lower than Mumbai',
                        materialRate: '10% to 11% lower than Mumbai',
                        overallImpact: '8% to 10% lower than Mumbai',
                      },
                      {
                        city: 'Bangalore',
                        labourRate: '10% higher than Mumbai',
                        materialRate: '15% higher than Mumbai',
                        overallImpact: '12% to 13% higher than Mumbai',
                      },
                      {
                        city: 'Chennai',
                        labourRate: '5% lower than Mumbai',
                        materialRate: '10% lower than Mumbai',
                        overallImpact: '8% to 9% lower than Mumbai',
                      },
                      {
                        city: 'Gurugram',
                        labourRate: '7% to 8% lower than Mumbai',
                        materialRate: '10% to 11% lower than Mumbai',
                        overallImpact: '8% to 10% lower than Mumbai',
                      },
                      {
                        city: 'Hyderabad',
                        labourRate: '10% lower than Mumbai',
                        materialRate: '11% to 12% lower than Mumbai',
                        overallImpact: '11% to 11.5% lower than Mumbai',
                      },
                      {
                        city: 'Pune',
                        labourRate: 'Same as Mumbai',
                        materialRate: '4% to 5% higher than Mumbai',
                        overallImpact: '3% to 3.5% higher than Mumbai',
                      },
                    ].map(row => {
                      return (
                        <>
                          <td className="tw-text-center tw-p-2">{row.city}</td>
                          <td className="tw-text-center tw-p-2">{row.labourRate}</td>
                          <td className="tw-text-center tw-p-2">{row.materialRate}</td>
                          <td className="tw-text-center tw-p-2">{row.overallImpact}</td>
                        </>
                      );
                    })}
                  </Table>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
