import React from 'react';
import ReactDOM from 'react-dom';
import { useSelector, useDispatch } from 'react-redux';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { CarpentryAction, CivilAction } from '../../../../actions';

const ConfirmationModal = () => {
  const {
    confirmationModal: {
      show,
      data: { heading = 'Are you sure!', content = '', ...others },
    },
  } = useSelector(state => state.modal);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch({ type: 'HIDE_CONFIRMATION_MODAL' });
  };

  const handleConfirmation = () => {
    if (others.type === 'DELETE_BOQ_FURNITURE') {
      // @TODO: Sridhar
      dispatch(CarpentryAction.remove(...others.params));
    }

    if (others.type === 'DELETE_BOQ_CIVIL') {
      // @TODO: Sridhar
      dispatch(CivilAction.remove(...others.params));
    }

    dispatch({ type: 'HIDE_CONFIRMATION_MODAL' });
  };

  return ReactDOM.createPortal(
    <Dialog open={show} onClose={handleClose} aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">{heading}</DialogTitle>
      {content && (
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
      )}

      <DialogActions className="p-4 pt-0">
        <Button
          autoFocus
          onClick={() => handleClose()}
          className="p-2 ml-n3 rounded tw-fill-current text-capitalize btn btn-primary text-white"
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleConfirmation()}
          className="p-2 rounded tw-fill-current text-capitalize btn btn-primary text-white"
          color="primary"
          autoFocus
        >
          Ok
        </Button>
      </DialogActions>
    </Dialog>,
    document.body,
  );
};

export { ConfirmationModal };
