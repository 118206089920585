import { useDispatch } from 'react-redux';
import { doPatch } from '../utils';

import { BoqAction } from '../actions';

function useMaterialRateUpdate(boqId, type, onSubmitCallback = () => {}) {
  const dispatch = useDispatch();
  return async (formData, rate, action = 'estimate') => {
    let result = {};
    try {
      const { id = null, service = null, label, oldRate = null } = formData;
      let body = { service, id, action };
      if (type.match(/material/)) {
        body = { ...body, material: { [label.toLowerCase()]: { rate, oldRate } } };
      } else if (type.match(/labour/)) {
        body = { ...body, ...formData, rate };
      }
      result = await doPatch({
        url: `analysis?boq_id=${boqId}&type=${type}`,
        body,
      });
      if (action === 'submit') {
        dispatch(BoqAction.fetchBoqDetails(boqId));
        onSubmitCallback();
        // dispatch(AnalysisAction.getCarpentryMaterial(boqId));
      }
    } catch (e) {
      console.error(e);
    }
    return result;
  };
}

function useLaborRateUpdate() {}

export { useMaterialRateUpdate, useLaborRateUpdate };
