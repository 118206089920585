import React, { useContext } from 'react';
import { arrayOf, shape, string } from 'prop-types';
import cx from 'classnames';

import UserContext from '../../../UserContext';
import { ViewMore } from './ViewMore';
import { useCollapsable } from './useCollapsable';

import '../vendor-style.scss';

const FinishCategoryList = ({ finishes = [] }) => {
  const { role } = useContext(UserContext);
  const isAdmin = role === 'admin';

  const { count, items, onItemClick } = useCollapsable({
    collection: finishes,
    adminRedirectUrl: '/vendors/category/:slug/details',
  });

  return (
    <section className="section-container">
      <h5 className="vendor-title header-section">
        Finish Categories ({count})
        <span
          style={{
            marginRight: '3%',
          }}
        >
          {isAdmin && (
            <a href="/vendors/manage" className="a-cta">
              Manage
            </a>
          )}
        </span>
      </h5>
      <ul className="card-container">
        {items.map(finish => {
          const { slug, type } = finish;
          const isViewMore = type === 'viewMore';
          return (
            <li key={slug} onClick={() => onItemClick({ slug, isAdmin })}>
              <div
                className={cx('card-item', {
                  'center-content': isViewMore,
                  'category-card': !isViewMore,
                  'blur-image': !isViewMore,
                  'white-background-color': isViewMore,
                })}
                style={{
                  backgroundImage: `url(${finish.image_url})`,
                  backgroundSize: 'cover',
                }}
              >
                {!isViewMore && <span className="upper-case-text bold-font">{finish.name}</span>}
                {isViewMore && <ViewMore />}
              </div>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

FinishCategoryList.propTypes = {
  finishes: arrayOf(
    shape({
      slug: string,
      type: string,
      image_url: string,
    }),
  ).isRequired,
};

export { FinishCategoryList };
