import React from 'react';
import { Link } from 'react-router-dom';
import { string, arrayOf, shape } from 'prop-types';

import './style.scss';

const row = ({ id, label, alias = [], editUrlSegment }) => {
  return (
    <tr>
      <td>
        <Link to={`/${editUrlSegment}/${id}`} editUrlSegment={editUrlSegment}>
          {label}
        </Link>
        <div>
          {alias.map(term => {
            return <span className="alias-badge badge badge-secondary">{term}</span>;
          })}
        </div>
      </td>
    </tr>
  );
};

const FinishTable = ({ finishes, editUrlSegment }) => {
  return (
    <table className="table table-bordered table-hover tabs-container">
      <thead>
        <tr>
          <th scope="col">Finishing Material</th>
        </tr>
      </thead>
      <tbody>{finishes.map(finish => row({ ...finish, editUrlSegment }))}</tbody>
    </table>
  );
};

FinishTable.propTypes = {
  finishes: arrayOf(
    shape({
      id: string,
      label: string,
      alias: arrayOf(string),
    }),
  ).isRequired,
  editUrlSegment: string.isRequired,
};

export { FinishTable };
