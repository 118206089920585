import { useRef } from 'react';

function useScrollTo() {
  const elRef = useRef(null);
  const executeScroll = () => {
    elRef.current.scrollIntoView(true, {
      behavior: 'smooth',
      block: 'start',
      // inline: 'center',
    });
  };
  return [elRef, executeScroll];
}

export { useScrollTo };
