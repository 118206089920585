import React from 'react';
import { shape, string, bool, oneOfType, number } from 'prop-types';

import { Placeholder } from '../../Loader/Card/index';

function Slide({ imageClasses, isFullScreen, content: { original, blurred, alt } }) {
  const computeDivClass = () => {
    if (isFullScreen) {
      return 'tw-w-full tw-items-center tw-justify-center tw-flex';
    }
    if (blurred) {
      return 'tw-flex tw-justify-center tw-relative tw-pb-48 tw-bg-cover tw-h-full';
    }
    return 'tw-relative h-80-screen';
  };

  const computeImageClass = () => {
    if (isFullScreen) {
      return 'tw-flex tw-object-fill';
    }
    if (blurred) {
      return 'tw-absolute tw-h-full tw-object-fill';
    }
    return 'tw-absolute tw-w-full tw-h-full tw-object-contain';
  };

  const classes = {
    image: `tw-select-none tw-rounded ${computeImageClass()} ${imageClasses}`,
    div: computeDivClass(),
  };

  let backgroundStyles = {};
  if (blurred) {
    backgroundStyles = {
      ...backgroundStyles,
      backgroundImage: `url(${blurred})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      // filter: 'blur(40px)',
      // height: '100%',
      // backgroundPosition: 'center',
    };
  }

  return (
    <div className={classes.div} style={backgroundStyles}>
      {original && <img className={classes.image} src={original} alt={alt} />}
      {!original && <Placeholder />}
    </div>
  );
}

Slide.defaultProps = {
  imageClasses: '',
  isFullScreen: false,
};

Slide.propTypes = {
  imageClasses: string,
  isFullScreen: bool,
  content: shape({
    url: string,
    alt: oneOfType([string, number]),
    blurred: string,
  }).isRequired,
};

export { Slide };
