import React from 'react';

import './skeleton-loader.scss';

function CostSectionSkeletonLoader() {
  return (
    <div className="cost-section-skeleton-loader tw-flex tw-w-2/3 tw-space-x-2 tw-mt-5 tw-animate-pulse">
      <div className="tw-w-1/3">
        <div className="tw-h-4 tw-bg-gray-200 tw-w-full" />
        <div className="tw-mt-4 tw-h-4 tw-bg-gray-200 tw-w-1/2" />
      </div>
      <div className="tw-w-1/3 tw-flex tw-items-center tw-justify-center" style={{ marginLeft: 0 }}>
        <div className="tw-flex tw-items-center tw-justify-center tw-rounded-full tw-h-8 tw-w-8 tw-border-2  oval">
          <div>
            <div className="equals" />
            <div className="equals" />
          </div>
        </div>
      </div>
      <div className="tw-w-1/3 tw-float-right">
        <div className="tw-h-4 tw-bg-gray-200 tw-w-full" />
        <div className="tw-mt-4 tw-h-4 tw-bg-gray-200 tw-w-1/2 tw-float-right" />
      </div>
    </div>
  );
}

export { CostSectionSkeletonLoader };
