import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { number, shape, string } from 'prop-types';
import { useHistory } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import jwtDecode from 'jwt-decode';
import { isMobileOnly } from 'react-device-detect';

import { API_BASE_URL, doPost } from 'utils';

import { LoginForm } from '../LoginForm';
import UserContext from '../../UserContext';
import { LoginLink } from '../LoginForm/LoginLink';
import { SignUpLink } from '../LoginForm/SignUpLink';

import { useNetwork } from '../../hooks';

import './login.scss';

/**
 * /login form
 * @param mode
 * @param next
 * @param location
 * @param boqId
 * @param formToDisplay
 * @returns {*}
 * @constructor
 */
const Login = ({ mode, next, location, boqId, formToDisplay }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { onLogin } = useContext(UserContext);
  const { error, isLoading, data, doPostCall } = useNetwork();
  const [action, setAction] = useState('login');
  const routerState = {
    ...location,
  };
  if (!routerState.state) {
    routerState.state = {};
  }
  const { from: previousLocation = next } = routerState.state;
  const ACTION_MAPPER = {
    signup: {
      buttonCaption: 'Sign Up',
      socialHeader: 'Or Sign up using',
      footer: (
        <LoginLink
          navigatedFrom={previousLocation}
          onClick={() => {
            if (mode === 'fullpage') {
              history.replace('/login', {
                from: previousLocation,
              });
            } else {
              dispatch({
                type: 'SHOW_LOGIN_MODAL',
                payload: {
                  next,
                  boqId,
                  formToDisplay: 'login',
                },
              });
            }
          }}
        />
      ),
    },
    login: {
      buttonCaption: 'Login',
      socialHeader: '',
      footer: (
        <SignUpLink
          navigatedFrom={previousLocation}
          onClick={() => {
            if (mode === 'fullpage') {
              history.replace('/signup', {
                from: previousLocation,
                action: 'signup',
              });
            } else {
              dispatch({
                type: 'SHOW_LOGIN_MODAL',
                payload: {
                  next,
                  boqId,
                  formToDisplay: 'signup',
                },
              });
            }
          }}
        />
      ),
    },
  };
  const { footer, socialHeader, buttonCaption } = ACTION_MAPPER[action];
  const restrictedRedirectLocation = ['summary'];

  useEffect(() => {
    const navigateTo = restrictedRedirectLocation.some(item => item.includes(previousLocation))
      ? '/'
      : previousLocation;
    if (data) {
      onLogin(data);
      if (boqId) {
        doPost({
          url: `/boqs/${boqId}/user`,
        }).then(() => {
          window.location.href = navigateTo;
        });
      } else {
        window.location.href = navigateTo;
      }
    }
  }, [data]);

  useEffect(() => {
    if (history.location.state && history.location.state.action) {
      setAction(history.location.state.action);
    } else {
      setAction(formToDisplay);
    }
  }, [history.location.state, formToDisplay]);

  // useEffect(() => {
  //   setAction(formToDisplay);
  // }, [formToDisplay]);

  const handleSubmit = ({ email, password }) => {
    doPostCall({
      url: `${API_BASE_URL}/users/${action === 'login' ? 'login' : 'signup'}`,
      body: {
        email,
        password,
      },
    });
  };

  const handleGoogleAuth = ({ email, imageUrl, name, googleId }) => {
    doPostCall({
      url: `${API_BASE_URL}/users/social-login`,
      body: {
        email,
        googleId,
        gravatar_url: imageUrl,
        user_name: name,
      },
    });
  };

  const width = isMobileOnly ? { width: 300 } : { width: 366 };
  return (
    <div className="container-fluid">
      <div className={`login-bg vh-100 w-100 ${action}`} />
      <div className="container mx-auto ">
        <div className="row">
          <div className="col-12 col-sm-10 col-xl-4 mx-auto mt-5">
            <div className="w-100 bg-white position-relative  p-4 rounded mt-5">
              <LoginForm
                action={action}
                error={error}
                onSubmitHandler={handleSubmit}
                onProcessing={isLoading}
                buttonCaption={buttonCaption}
              />
              <div className="">
                <hr />
                <p className="text-center text-2-color hr"> {socialHeader}</p>
                <div className="social">
                  <div className="item text-center mt-2">
                    <GoogleLogin
                      {...width}
                      theme="filled_blue"
                      size="large"
                      shape="rectangular"
                      onSuccess={credentialResponse => {
                        const { email, picture: imageUrl, name, sub: googleId } = jwtDecode(
                          credentialResponse.credential,
                        );
                        handleGoogleAuth({
                          email,
                          imageUrl,
                          name,
                          googleId,
                        });
                      }}
                      onError={() => {}}
                    />
                  </div>
                </div>
                <p className="signup-cta text-center my-2 text-2-color"> {footer}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Login.defaultProps = {
  boqId: undefined,
  mode: 'fullpage',
  next: '/dashboard',
  location: shape({
    state: {
      action: 'login',
      from: '/',
    },
  }),
  formToDisplay: 'login',
};

Login.propTypes = {
  boqId: number,
  mode: string,
  next: string,
  location: shape({}),
  formToDisplay: string,
};

export { Login };
