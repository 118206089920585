import React from 'react';
import { string, number, shape, arrayOf, oneOfType } from 'prop-types';

import { CarpentryForm } from '../BoqDetail/OverAllAnalysis/Carpentry/CarpentryForm';

import './edit-furniture-style.scss';

function EditFurniture(props) {
  return <CarpentryForm {...props} />;
}

EditFurniture.propTypes = {
  furniture: shape({
    description: oneOfType([string, arrayOf(string)]).isRequired,
    dimension: string.isRequired,
    room: string.isRequired,
    count: number.isRequired,
    finish: shape({}).isRequired,
  }).isRequired,
  rooms: arrayOf(shape({})).isRequired,
};

EditFurniture.defaultProps = {};

export { EditFurniture };
