import React from 'react';
import { arrayOf, func, shape, node, string, bool, oneOf, object } from 'prop-types';
import classNames from 'classnames';

import './tabbed-panel.scss';

const TabbedPanel = ({
  tabs,
  children,
  onTabClick,
  selectedTab,
  appendSecondaryTitle,
  bodyStyle,
  align,
}) => {
  return (
    <>
      <ul
        className={classNames('card-header-tabs tw-border-b-2 tw-border-gray-400', {
          alignStart: align === 'start',
          alignCenter: align === 'center',
        })}
      >
        {tabs.map(({ title, value }) => {
          return (
            <li key={value} className="nav-item" onClick={() => onTabClick(value)}>
              <a
                className={classNames('nav-link me-4 fw-bold', {
                  menuBarActive: value === selectedTab,
                  active: value === selectedTab,
                })}
              >
                {title} {appendSecondaryTitle && <span className="md-hide semi-bold">Split</span>}
              </a>
            </li>
          );
        })}
      </ul>
      <div className="card-body" style={{ ...bodyStyle }}>
        {children}
      </div>
    </>
  );
};

TabbedPanel.defaultProps = {
  appendSecondaryTitle: false,
  align: 'center',
  bodyStyle: {},
};

TabbedPanel.propTypes = {
  children: node.isRequired,
  tabs: arrayOf(
    shape({
      title: string,
      value: string,
    }),
  ).isRequired,
  onTabClick: func.isRequired,
  selectedTab: string.isRequired,
  appendSecondaryTitle: bool,
  align: oneOf(['start', 'center']),
  // eslint-disable-next-line react/forbid-prop-types
  bodyStyle: object,
};

export { TabbedPanel };
