import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

const Updates = () => {
  const items = [
    {
      name: 'Bulk Edits',
      date: '20/05/2022',
      updates: [
        'Replace a material or finish in all items in your BOQ in a single step. ',
        'See impacted items and select which items you want to apply the change to. ',
        'View change in material and BOQ cost before applying changes. ',
      ],
    },
    {
      name: 'Revision History',
      date: '12/05/2022',
      updates: [
        'Tracks all revisions made to you BOQ by date and user. ',
        'View all items impacted by each revision. ',
        'View change in total BOQ cost caused by each revision.',
      ],
    },
    {
      name: 'Edit Material Rates',
      date: '02/05/2022',
      updates: [
        'Change the rate of any material in your BOQ',
        'View revised material rate, total material cost and revised BOQ cost before applying.',
      ],
    },
    {
      name: 'Edit Labour Rates',
      date: '01/05/2022',
      updates: [
        'Change the labour rate for any item in your BOQ',
        'View revised labour rate, total labour cost and revised BOQ cost before applying.',
      ],
    },
    {
      name: 'Bug Fixes',
      date: ' 28/04/2022',
      updates: ['Fixed bugs related to login'],
    },
  ];
  return (
    <>
      <div className="tw-container tw-mx-auto">
        <div className="tw-bg-white tw-shadow-xl tw-m-8 tw-p-4 tw-rounded-lg">
          <div className="tw-flex tw-flex-col lg:tw-flex-row">
            <div className="tw-w-full tw-relative">
              <ul className="tw-flex tw-flex-row">
                <li className="tw-text-text2 tw-mr-2">
                  <Link to="/">Home</Link>
                </li>
                <li className="tw-text-text2 tw-mr-2">/</li>
                <li className="tw-text-text2 tw-mr-2 tw-text-opacity-50">Product Updates</li>
              </ul>
              <h2 className="tw-text-text2 tw-font-bold">
                Product <span className="tw-text-cta">Updates</span>
              </h2>
              <div className="tw-py-8">
                {items.map(item => {
                  return (
                    <div className="tw-flex tw-flex-col lg:tw-flex-row tw-m-0">
                      <div className="tw-w-full lg:tw-w-2/12 tw-font-bold tw-text-text2 tw-text-opacity-50 lg:tw-text-opacity-100 tw-pt-4 lg:tw-pt-16 tw-pl-4 lg:tw-pl-0">
                        {item.date}
                      </div>
                      <div className="tw-w-full lg:tw-w-10/12 lg:tw-border-l tw-border-gray-300 lg:tw-pt-16 lg:tw-pb-8 tw-pl-4 tw-relative">
                        <h2
                          className="tw-text-lg tw-font-bold tw-text-text2"
                          style={{ fontSize: '1.2rem' }}
                        >
                          {item.name}
                        </h2>
                        <ul className="tw-list-decimal tw-pl-4">
                          {item.updates.map(update => (
                            <li className="tw-text-text2">{update}</li>
                          ))}
                        </ul>
                        <div
                          className="tw-border tw-px-4 tw-py-2 tw-absolute  tw-bg-white tw-capitalize tw-hidden lg:tw-block"
                          style={{ left: '-50px', top: '-5px' }}
                        >
                          {moment(item.date, 'DD/MM/YYYY').fromNow()}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Updates;
