/**
 * Null, undefined safe string utility
 * @param source
 * @param subString
 * @returns {boolean}
 */
const isSubString = (source, subString) => {
  if (!source || !subString) {
    return false;
  }
  return source.toLowerCase().includes(subString.trim().toLowerCase());
};

const isBlankOrEmptyString = string => {
  return string === null || string === undefined || string.trim() === '';
};

export { isSubString, isBlankOrEmptyString };
