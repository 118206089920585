class StorageUtils {
  static set(key, data) {
    localStorage.setItem(key, data);
  }

  static get(key) {
    let result = localStorage.getItem(key);
    if (result) {
      result = JSON.parse(result);
    }
    return result;
  }
}

export { StorageUtils };
